<template>
	<div v-if="showDialog">
		<!-- 遮罩 -->
		<transition name="fade" appear>
			<div class="modal-overlay"></div>
		</transition>
		<transition name="pop" appear>
			<div class="modal" role="dialog" :style="customStyle">
				<div class="model-body">
					<custom-video-player 
					autoplay 
					width="100%" 
					height="100%" 
					:src="videoSrc"
					:poster="poster" />
				</div>

				<div class="icon-close">
					<img src="@/assets/yjysite/yjysite_video_dialog_close.png" @click="close" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import CustomVideoPlayer from "@/components/video/CustomVideoPlayer.vue"

	export default {
		name: 'CustomDialog',
		components: {
			CustomVideoPlayer
		},
		model: {
			prop: 'showDialog',
			event: 'change'
		},
		props: {
			// 弹框的显示隐藏
			showDialog: {
				type: Boolean,
				default: false
			},
			customStyle: {
				type: Object,
				default: {}
			},
			// 视频地址
			videoSrc: {
				type: String,
				default: ''
			},
			// 视频封面
			poster: {
				type: String,
				default: ''
			},
			// 关闭按钮前的事件处理
			beforeClose: {
				type: Function,
				default: () => {
					return true;
				}
			}
		},
		methods: {
			close() {
				const data = this.beforeClose();
				if (!data) return;
				this.$emit('change', false);
			},
			confim() {
				this.$emit('confim', false);
			}
		}
	};
</script>
<style scoped>
	.modal {
		position: absolute;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		height: fit-content;
		width: 600px;
		overflow: hidden;
		background: transparent;
		z-index: 999;

		transform: none;
	}

	.model-body {
		/* padding: 2rem; */

		position: absolute;
		top: 0;
		right: 37px;
		left: 0;
		bottom: 0;
		min-height: 2rem;
		max-height: 100vh;
		overflow-y: auto;
		background-color: red;
		box-shadow: 0px 5px 10px 0px rgba(240, 240, 240, 0.3);
	}

	.icon-close {
		position: absolute;
		right: 0;
		top: 0;
	}

	.icon-close img {
		width: 23px;
		height: 23px;
		cursor: pointer;
	}

	.modal-overlay {
		content: '';
		position: absolute;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 998;
		background: rgba(0, 0, 0, 0.75);
	}

	/* ---------------------------------- */
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.4s linear;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.pop-enter-active,
	.pop-leave-active {
		transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
	}

	.pop-enter,
	.pop-leave-to {
		opacity: 0;
		transform: scale(0.3) translateY(-50%);
	}
</style>