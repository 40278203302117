var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}],staticClass:"el-carousel__item",class:{
      'is-active': _vm.active,
      'el-carousel__item--card': _vm.$parent.type === 'card',
      'is-in-stage': _vm.inStage,
      'is-hover': _vm.hover,
      'is-animating': _vm.animating
    },style:(_vm.itemStyle),on:{"click":_vm.handleItemClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }