import Vue from 'vue'
import Router from 'vue-router'



// import yjyLinkUs from './yjyLinkUs.vue'

// import yjyNewList from './yjyNewList.vue'

// import yjyNewDetail from './yjyNewDetail.vue'

// import yjyProduct from './yjyProduct.vue'
// 首页
import yjyIndex from './yjyIndex.vue'
// 关于我们
import yjyAboutUs from './yjyAboutUs.vue'

// 客户案例
import yjyCustomerCases from './yjyCustomerCases.vue'
// 前沿技术
import yjyCuttingedgeTechnology from './yjyCuttingedgeTechnology.vue'
// 荣誉资质
import yjyHonorQualification from './yjyHonorQualification.vue'
// 最新动态
import yjyLatestNews from "./yjyLatestNews.vue"
import yjyNewDetail from './news/yjyNewDetail.vue'
// 物流平台
import yjy56Saas from "./yjy56Saas.vue"


// 解决方案
// import solutionCase from './solution/solutionCase.vue'
import yjySolutions from "./yjySolutions.vue"
// 解决方案.智能运输
import znysSolution from './solution/znysSolution.vue'
// 解决方案.网络货运
import wlhySolution from './solution/wlhySolution.vue'
// 解决方案.智能水运
import szsySolution from './solution/szsySolution.vue'
// 解决方案.多式联运
import dslySolution from "./solution/dslySolution.vue"
// 解决方案.智能仓储
import znccSolution from "./solution/znccSolution.vue"
// 解决方案.智能园区
import znwlyqSolution from "./solution/znwlyqSolution.vue"
// 解决方案.灵工
import lhygSolution from "./solution/lhygSolution.vue"
// 解决方案.汇用工
import hygSolution from "./solution/hygSolution.vue"
// 解决方案.56Saas
import saas56Solution from "./solution/saas56Solution.vue"
// 解决方案.人力Saas
import saashrSolution from "./solution/saashrSolution.vue"
// 解决方案.城市物流资源整合平台
import cswlptSolution from "./solution/cswlptSolution.vue"
// 解决方案.物流与供应链大数据交易平台
import dsjptSolution from "./solution/dsjptSolution.vue"
// 解决方案.细分行业供应链平台
import gylptSolution from "./solution/gylptSolution.vue"
// 解决方案.共享IT部解决方案
import gxitbSolution from "./solution/gxitbSolution.vue"
// 解决方案.供应链控制塔
import gylkztSolution from "./solution/gylkztSolution.vue"
// 解决方案.物流招投标解决方案
import ztbSolution from "./solution/ztbSolution.vue"




Vue.use(Router)


export default new Router({
	routes: [
		{
			path: '*',
			redirect: '/yjysite'
		}, { //系统入口页面
			path: '/yjysite/:searchValue?',
			name: 'yjysite',
			props: true,
			meta: {
				title: '研究院'
			},
			component: yjyIndex
		}, {
			path: '/yjyAboutUs',
			name: 'yjyAboutUs',
			props: true,
			meta: {
				title: '关于我们'
			},
			component: yjyAboutUs

		}, {
			path: '/yjySolutions',
			name: 'yjySolutions',
			props: true,
			meta: {
				title: '解决方案'
			},
			component: yjySolutions
		}, {
			path: '/solution/znysSolution',
			name: 'znysSolution',
			props: true,
			meta: {
				title: '智能运输解决方案'
			},
			component: znysSolution
		}, {
			path: '/solution/wlhySolution',
			name: 'wlhySolution',
			props: true,
			meta: {
				title: '网货货运解决方案'
			},
			component: wlhySolution
		}, {
			path: '/solution/szsySolution',
			name: 'szsySolution',
			props: true,
			meta: {
				title: '数字水运解决方案'
			},
			component: szsySolution
		}, {
			path: '/solution/dslySolution',
			name: 'dslySolution',
			props: true,
			meta: {
				title: '多式联运解决方案'
			},
			component: dslySolution
		}, {
			path: '/solution/znccSolution',
			name: 'znccSolution',
			props: true,
			meta: {
				title: '智能仓储解决方案'
			},
			component: znccSolution
		}, {
			path: '/solution/znwlyqSolution',
			name: 'znwlyqSolution',
			props: true,
			meta: {
				title: '智能物流园区解决方案'
			},
			component: znwlyqSolution
		}, {
			path: '/solution/lhygSolution',
			name: 'lhygSolution',
			props: true,
			meta: {
				title: '灵活用工解决方案'
			},
			component: lhygSolution
		}, {
			path: '/solution/hygSolution',
			name: 'hygSolution',
			props: true,
			meta: {
				title: '汇用工解决方案'
			},
			component: hygSolution
		}, {
			path: '/solution/saas56Solution',
			name: 'saas56Solution',
			props: true,
			meta: {
				title: '物流SaaS'
			},
			component: saas56Solution
		}, {
			path: '/solution/saashrSolution',
			name: 'saashrSolution',
			props: true,
			meta: {
				title: '工众人力SaaS'
			},
			component: saashrSolution
		}, {
			path: '/solution/cswlptSolution',
			name: 'cswlptSolution',
			props: true,
			meta: {
				title: '城市物流资源整合平台'
			},
			component: cswlptSolution
		}, {
			path: '/solution/dsjptSolution',
			name: 'dsjptSolution',
			props: true,
			meta: {
				title: '物流与供应链大数据交易平台'
			},
			component: dsjptSolution
		}, {
			path: '/solution/gylptSolution',
			name: 'gylptSolution',
			props: true,
			meta: {
				title: '细分行业供应链平台'
			},
			component: gylptSolution
		}, {
			path: '/solution/gxitbSolution',
			name: 'gxitbSolution',
			props: true,
			meta: {
				title: '共享IT部解决方案'
			},
			component: gxitbSolution
		}, {
			path: '/solution/gylkztSolution',
			name: 'gylkztSolution',
			props: true,
			meta: {
				title: '供应链控制塔'
			},
			component: gylkztSolution
		}, {
			path: '/solution/ztbSolution',
			name: 'ztbSolution',
			props: true,
			meta: {
				title: '物流招投标解决方案'
			},
			component: ztbSolution
		},
		
		
		{
			path: '/yjyCustomerCases',
			name: 'yjyCustomerCases',
			props: true,
			meta: {
				title: '客户案例'
			},
			component: yjyCustomerCases
		}, {
			path: '/yjyCuttingedgeTechnology',
			name: 'yjyCuttingedgeTechnology',
			props: true,
			meta: {
				title: '前沿技术'
			},
			component: yjyCuttingedgeTechnology
		}, {
			path: '/yjyHonorQualification',
			name: 'yjyHonorQualification',
			props: true,
			meta: {
				title: '荣誉资质'
			},
			component: yjyHonorQualification
		}, {
			path: '/yjyLatestNews',
			name: 'yjyLatestNews',
			props: true,
			meta: {
				title: '最新动态'
			},
			component: yjyLatestNews
		}, {
			path: '/yjy56Saas',
			name: 'yjy56Saas',
			props: true,
			meta: {
				title: '物流平台'
			},
			component: yjy56Saas
		},
		{
            path: '/news/yjyNewDetail/:id?',
            name: 'yjyNewDetail',
            props: true,
            meta: {
                title: '新闻详情'
            },
            component: yjyNewDetail

        }
	]
})