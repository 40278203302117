<!-- 智能园区解决方案 -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage show-shadow :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="functionImg" class="solu_function body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>主要模块功能</p>
				</div>
				<div class="function_wrap">
					<img v-lazy="functionImg" alt="" />
				</div>

			</div>
		</div>
		<div v-if="platformImg" :ref="menuList[1]" class="solu_platform body_suit item">
			<img v-lazy="platformImg" alt="" />
			<div class="item_wrap">
				<div class="item_header margin_top">
					<p>园区管理平台</p>
				</div>
			</div>
		</div>
		<div v-if="mechanics.length > 0" :ref="menuList[2]" class="solu_mechanics body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>物联网智能园区管理平台</p>
				</div>
				<div ref="mechWrap" class="mech_wrap" :style="mechStyle">
					<div class="mech_item" v-for="(mech, index) in mechanics" :key="index"
						@mouseenter="onMechMouseenter(mech)" @mouseleave="onMechMouseleave(mech)">
						<div class="mech_item_wrap">
							<img :src="__imagePath + (mech.active ? mech.imageurlA : mech.imageurl)"
								:class="{ img_active: mech.active }" />
							<div class="p_title" :class="{ p_active: mech.active }">
								<p>{{ mech.title }}</p>
							</div>

							<ul v-show="mech.active">
								<li v-for="(sub, sIndex) in mech.subtitles" :key="sIndex">
									<p>{{ sub }}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="businesses.length > 0" :ref="menuList[3]" class="solu_businesses body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>园区招商平台</p>
				</div>
				<div class="businesses_wrap">
					<div class="business_wrap" v-for="(business, index) in businesses" :key="index">
						<div class="item_title">
							<img v-lazy="__imagePath + business.imageurl" alt="" />
							<p>{{ business.title }}</p>
						</div>
						<div class="item_subtitle">
							<p>{{ business.subTitle }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="planValues.length > 0" :ref="menuList[4]" class="solu_plan_value body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案价值</p>
				</div>
				<ul>
					<li v-for="(value, index) in planValues" :key="index">
						<div class="value_wrap">
							<img v-lazy="__imagePath + value.imageurl" alt="" />
							<div class="value_title">
								<p class="p1">{{ value.title }}</p>
								<p>{{ value.subtitle }}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div :ref="menuList[5]" class="solu_download body_suit item dray_background">
			<solutionDataView type="znyq" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>
	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue";
	import solutionDataView from "./commonView/solutionDataView.vue";
	import solutionCasesView from "./commonView/solutionCasesView.vue";
	import localPage from "../json/znwlyqSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView,
		},
		data() {
			return {
				header: {
					title: "智能物流园区解决方案",
				},
				menuList: ["主要模块", "平台架构", "主要功能", "招商平台", "方案价值", "相关资料", ],
				functionImg: null,
				platformImg: null,

				mechanics: [],
				mechStyle: {
					gridTemplateColumns: "repeat(6, 1fr)",
				},
				businesses: [],
				planValues: [],
			};
		},

		created() {},
		mounted() {
			this.loadDatas();
		},
		methods: {
			scrollToEL(e) {
				console.log("scrollToEL");
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${e}`];
					console.log("element:" + element);
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				});
			},

			onMechMouseenter(mech) {
				mech.active = true;
				this.mechWrapStyle(true);
			},
			onMechMouseleave(mech) {
				mech.active = false;
				this.mechWrapStyle(false);
			},
			mechWrapStyle(enter) {
				let count = this.mechanics.length;
				if (enter) {
					const el = this.$refs.mechWrap;
					if (!el) return;
					let rect = el.getBoundingClientRect();
					let rectWidth = rect.width * window.devicePixelRatio
					let average = (rectWidth - (count - 1)) / count;
					let big = average * 1.72;
					let small = (rectWidth - big) / (count - 1);

					let gridC = "";
					this.mechanics.forEach((mech, index) => {
						if (mech.active == true) {
							if (index == 0) gridC += big + "px";
							else gridC += " " + big + "px";
						} else {
							if (index == 0) gridC += small + "px";
							else gridC += " " + small + "px";
						}
					});
					this.mechStyle = {
						gridTemplateColumns: gridC,
					};
				} else {
					this.mechStyle = {
						gridTemplateColumns: 'repeat(' + count + ', 1fr)',
					};
				}

				//grid-template-columns: repeat(5, 1fr);
			},

			headerBtnClick(e) {
				console.log("headerBtnClick");
			},
			headerMenuClick(e) {
				console.log("headerMenuClick:" + e);
				this.scrollToEL(e);
			},
			onDownload() {},
			onVideo() {},

			handlePage(page) {
				let that = this;

				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let functionImg = page.functionImg;
				if (functionImg) {
					that.functionImg = that.__imagePath + functionImg
				}
				let platformImg = page.platformImg;
				if (platformImg) {
					that.platformImg = that.__imagePath + platformImg
				}
				let mechanics = page.mechanics;
				if (mechanics && mechanics.length > 0) {
					that.mechanics = mechanics
				}
				let businesses = page.businesses;
				if (businesses && businesses.length > 0) {
					that.businesses = businesses
				}
				let planValues = page.planValues;
				if (planValues && planValues.length > 0) {
					that.planValues = planValues
				}

			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 84,
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
					}
				});
			}
		}
	};
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 模块功能 */
	.solu_function {
		display: flex;
		flex-direction: column;

		background-color: #ffffff;
		padding: 70px 0;
		padding: 4.375rem 0;

		.function_wrap {
			margin-top: 48px;
			margin-top: 3rem;

			img {
				width: 100%;
			}
		}
	}

	/* 园区管理平台 */
	.solu_platform {
		display: flex;
		flex-direction: column;
		
		aspect-ratio: 1.8824;
		position: relative;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;

		img {
			width: 100%;
		
			position: absolute;
			top: 0;
			left: 0;
		
			z-index: 0;
		}
		
		.margin_top {
			padding-top: 70px;
		}
	}

	/* 园区招商平台 */
	.solu_businesses {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.businesses_wrap {
			margin-top: 60px;

			flex: 1;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 24px;

			.business_wrap {
				background-color: #ffffff;
				border-radius: 4px;

				padding: 20px 40px;

				display: flex;
				flex-direction: column;
				align-items: baseline;
				justify-content: center;

				.item_title {
					display: flex;
					flex-direction: row;
					align-items: center;

					p {
						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 20px;
						color: #333333;
					}

					img {
						width: 20px;
						height: 20px;

						object-fit: contain;

						margin-right: 20px;
					}

					margin-bottom: 10px;
				}

				.item_subtitle {
					padding-left: 40px;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #666666;
					}
				}
			}

			.business_wrap:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 物联网智能园区管理平台 */
	.solu_mechanics {
		display: flex;
		flex-direction: column;

		background-color: #ffffff;
		padding: 70px 0;
		padding: 4.375rem 0;

		.mech_wrap {
			margin-top: 48px;
			margin-top: 3rem;

			aspect-ratio: 3.81;

			display: grid;
			flex-direction: row;
			grid-template-columns: repeat(6, 1fr);
			grid-gap: 0 1px;

			transition: all 0.25s ease;

			.mech_item {
				height: 100%;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				background-color: #ffffff;
				border: 1px solid #e5e7eb;

				transition: all 0.25s ease;

				.mech_item_wrap {
					flex: 1;
					width: 100%;

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.p_title {
						margin-top: 20px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #353c58;
						}
					}

					.p_active {
						margin-top: 30px;

						p {
							font-family: HanSans-Medium;
							font-weight: 500;
						}
					}

					img {
						width: 34px;
						height: 34px;

						object-fit: contain;
					}

					.img_active {
						width: 66px;
						height: 66px;
					}

					ul {
						margin-top: 15px;
					}

					li {
						flex: 1;
						padding: 0 30px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #788296;
							text-align: center;
						}
					}
				}
			}
		}
	}

	/* 方案价值 */
	.solu_plan_value {
		display: flex;
		flex-direction: column;

		background-color: #ffffff;
		padding: 70px 0;
		padding: 4.375rem 0;

		ul {
			margin-top: 60px;
			margin-top: 3.75rem;
		}

		li {
			width: 100%;

			display: flex;
		}

		.value_wrap {
			flex: 1;

			padding: 1.6% 2.2%;
			border-radius: 4px;
			border: 1px solid #dfe1e6;

			display: flex;
			align-items: center;

			img {
				width: 5%;
				aspect-ratio: 1;
			}

			.value_title {
				margin-left: 2.2%;

				p {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 14px;
					color: #333333;
				}

				.p1 {
					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 20px;

					margin-bottom: 1.1%;
				}
			}
		}
	}

	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>