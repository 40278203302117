<template>
  <div class="page_body">
    <div class="page_body_heard"
      :style="{ backgroundImage: 'url(' + (menuIndex==0 ? yjyNews : yjyNews2) + ')' }">  
      <div class="page_body_heard_bot">
         <p class="heard_title_text" v-if="menuIndex==0">新闻资讯</p>
         <p class="heard_title_text" v-if="menuIndex==1">行业动态</p>
         <p class="heard_content_text" v-if="menuIndex==0">了解共生供应链最新资讯</p>
         <p class="heard_content_text" v-if="menuIndex==1">了解最新行业资讯</p>
      </div>
    </div>
        <div class="page_body_list">
      <div class="page_body_list_bot">
              <div class="page_body_list_top">
            <div class="page_body_price" v-for="(item, index) in menuList" :key="index" @click="clickMenu(index)"> 
              <p class="menu_text" :class="{ 'menu_text_active': menuIndex === index }">
                {{item}}
              </p>
            </div>
          </div>
        

    <yjyCompanyNews v-if="menuIndex==0"></yjyCompanyNews>
    <yjyIndustryTrends v-if="menuIndex==1"></yjyIndustryTrends>
      </div>
      </div>
  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import yjyCompanyNews from "@/yjysite/news/yjyCompanyNews.vue";
import yjyIndustryTrends from "@/yjysite/news/yjyIndustryTrends.vue";

export default {
  components: {
      yjyCompanyNews, yjyIndustryTrends
  },
  data() {
    return {
        currentPage: 1, // 当前页码
        pageSize: 10,    // 每页显示的行数
        dataList: [],
        yjyNews: require("@/assets/yjysite/news/yjy_news.png"),
        yjyNews2: require("@/assets/yjysite/news/yjy_news2.png"),
        menuList: ['公司动态', '行业动态'],
        menuIndex: 0,
    };
  },

  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.dataList.slice(start, start + this.pageSize);
    },
  },

  mounted(){
    // this.getCompanyInfo();
  },

  updated() {},
  destroyed() {},

  methods: {

    clickMenu(index){
      this.menuIndex = index;
    },

  },
};
</script>

<style lang="scss" scoped>
.page_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: transparent;
}

.page_body_heard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1920px;
  height: 350px;
  box-sizing: border-box;
}

.heard_image {
  width: 1200px;
  height: 350px;
}

.page_body_list{
  display: flex;
  width: 1920px;
  background: rgba(253, 253, 253, 0.965);
  
}

.page_body_list_bot{
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 40px 240px;
}

.page_body_list_top{
  display: flex;
  width: 100%;
  height: 50px;
  padding: 40px 240px;
  box-sizing: border-box;
}

.page_body_price{
  margin-right: 20px;
}

.menu_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 18px;
  color: #353C58;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.menu_text_active{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 18px;
  color: #0055FF;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.page_body_list_arrange{
  display: flex;
  flex-direction: column;
}

.page_body_list_clount{
  display: flex;
}

.list_clount_left{
  display: flex;
  align-items: center;
  justify-content: center;
}

.month_year{
  display: flex;
  flex-direction: column;
}

.date_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 38px;
  color: #353C58;
  line-height: 38px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.xie_gang{
  width: 8px;
  height: 20px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 20px;
  color: #64698B;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-right: 5px;
}

.month_year_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 15px;
  color: #64698B;
  line-height: 15px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.list_clount_line{
  width: 0px;
  height: 74px;
  border: 1px solid #DFE1E6;
  margin: 0 50px;
}

.list_clount_right{
  display: flex;
  flex-direction: column;
}

.title_text{
 font-family: Source Han Sans, Source Han Sans;
 font-weight: 700;
 font-size: 20px;
 color: #252B3A;
 line-height: 20px;
 text-align: left;
 font-style: normal;
 text-transform: none;
}

.content_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 20px;
}

.page_body_list_line{
  width: 1441px;
  height: 0px;
  border: 1px solid #DFE1E6;
  margin-top: 37px;
}

.page_body_heard_bot{
  display: flex;
  flex-direction: column;
  width: 1440px;
  // align-items: center;
  // margin-left: 480px;
  box-sizing: border-box;
}

.heard_title_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 42px;
  color: #353C58;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.heard_content_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 20px;
  color: #64698B;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 20px;
}

.pagination-container {
  display: flex;
  justify-content: flex-end; /* 右对齐 */
  margin-top: 20px; /* 添加一些间距 */
}


</style>
