<template>
  <div class="page_body">
    <div
      class="page_body_heard"
      :style="{ backgroundImage: 'url(' + customerCasesHeard + ')' }"
      @click="clickEven"
    >
      <!-- <img src="customerCasesHeard" alt="" class="heard_image"> -->
      <div class="page_body_heard_top">
        <p class="title_text_top">客户案例故事</p>
      </div>

      <div class="page_body_heard_mid">
        <div class="page_body_heard_mid_cloum">
          <div class="line_view">
            <p class="title_text_mid">28+</p>
            <p class="title_text_mid2">覆盖全国省市</p>
          </div>
          <div class="mid_line"></div>
        </div>

        <div class="page_body_heard_mid_cloum">
          <div class="line_view">
            <p class="title_text_mid">1000+</p>
            <p class="title_text_mid2">制造业客户</p>
          </div>
          <div class="mid_line"></div>
        </div>

        <div class="page_body_heard_mid_cloum">
          <div class="line_view">
            <p class="title_text_mid">300+</p>
            <p class="title_text_mid2">网络货运客户</p>
          </div>
        </div>
      </div>

      <!-- @mouseover="hover = true"
        @mouseleave="hover = false" -->
      <div class="page_body_heard_lower">
        <div class="lower_view" @click="toCustomerService()">
          <p class="lower_text" :class="{ hovered: hover }">专家咨询</p>
        </div>
      </div>
    </div>

    <div class="page_body_list">
      <div class="page_body_list_bot">
        <div class="list_top">
          <p class="list_text">精选成功案例</p>
        </div>

        <div class="list_cloum">
          <div
            class="list_cloum_piece"
            v-for="(item, index) in paginatedData"
            :class="{ hovered: hover }"
            :key="index"
          >
            <div class="list_cloum_piece_image">
              <img
                :src="item.subImageUrl"
                alt="Image"
                class="page_image zoom-image"
                @mouseover="zoomIn"
                @mouseout="zoomOut"
              />
            </div>
            <div class="list_cloum_piece_bot">
              <div class="list_cloum_piece_title">
                <p class="title_text">{{ item.title }}</p>
              </div>
              <div class="list_cloum_piece_brief_introduction">
                <p>{{ item.subTitle }}</p>
              </div>
              <div class="list_cloum_piece_line"></div>

              <div class="list_cloum_piece_lable">
                <div class="lable_view" v-if="item.lableOne">
                  <p class="lable_view_text">{{ item.lableOne.name }}</p>
                </div>
                <div class="lable_view" v-if="item.lableTwo">
                  <p class="lable_view_text">{{ item.lableTwo.name }}</p>
                </div>
                <div class="lable_view" v-if="item.lableThree">
                  <p class="lable_view_text">{{ item.lableThree.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="list_button">
          <img
            v-if="showFlag"
            :src="buttonDown"
            alt=""
            class="button_down"
            @click="downClick()"
          />
          <img
            :src="buttonUp"
            alt=""
            class="button_up"
            @click="upClick()"
            v-else
          />
        </div>
      </div>
    </div>

    <div class="lower_story">
      <div class="lower_story_top">
        <p class="lower_story_top_text">来自不同行业的客户故事</p>
      </div>
      <div class="lower_story_mid">
        <el-carousel
          :interval="5000"
          arrow="always"
          indicator-position="outside"
        >
          <el-carousel-item
            v-for="(item, index) in dataList"
            :key="index"
            class="element_lable"
          >
            <div class="lower_story_mid_lable">
              <div class="lower_story_top_top">
                <div class="lower_story_top_left">
                  <img :src="item[0].imageUrl" alt="" class="story_logo" />
                </div>
                <div class="lower_story_top_right">
                  <p class="lower_story_text">{{ item[0].contantDsc }}</p>
                </div>
              </div>
              <div class="lower_story_lower">
                <p class="lower_story_text">———{{ item[0].title }}</p>
              </div>
            </div>

            <div class="lower_story_mid_lable">
              <div class="lower_story_top_top">
                <div class="lower_story_top_left">
                  <img
                    :src="item[1] ? item[1].imageUrl : ''"
                    alt=""
                    class="story_logo"
                  />
                </div>
                <div class="lower_story_top_right">
                  <p class="lower_story_text">
                    {{ item[1] ? item[1].contantDsc : "" }}
                  </p>
                </div>
              </div>
              <div class="lower_story_lower">
                <p class="lower_story_text">
                  ———{{ item[1] ? item[1].title : "" }}
                </p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
import aEntityAPI from "@/api/sysAPI.js";
import item from "../components/selector/item";

export default {
  components: {},
  data() {
    return {
      custList: [],
      customerCasesHeard: require("@/assets/yjysite/customercases/yjy_customercase_heard.png"),
      buttonDown: require("@/assets/yjysite/customercases/yjy_button_down.png"),
      buttonUp: require("@/assets/yjysite/customercases/yjy_button_up.png"),
      lableList: ["物流TMS系统", "智能车货匹配系统", "网络货运平台"],
      currentPage: 1, // 当前页
      pageSize: 9, // 每页显示的数据条数
      dataList: [],
      data: [],
      showFlag: true,
    };
  },

  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.custList.slice(start, start + this.pageSize);
      console.log("------------->");
    },
  },

  created() {
    this.clickEven();
  },

  mounted() {
    this.getCompanyInfo();
    console.log("this.dataList-------->" + this.dataList);
  },

  updated() {},
  destroyed() {},

  methods: {
    toCustomerService(e) {
      this.$parent.zxzx();
    },

    transformCustList(custList) {
      const newCustList = [];

      for (let i = 0; i < custList.length; i += 2) {
        if (custList[i + 1] !== undefined) {
          newCustList.push([custList[i], custList[i + 1]]);
        } else {
          newCustList.push([custList[i]]);
        }
      }

      return newCustList;
    },

    zoomIn() {
      this.$refs.zoomImage.style.transform = "scale(1.5)";
    },
    zoomOut() {
      this.$refs.zoomImage.style.transform = "scale(1)";
    },
    getCompanyInfo() {
      console.log("that.custList-------->");
      let that = this;
      aEntityAPI
        .request("HomePageConfigService", "getYjyHpDataInfo")
        .then((res) => {
          if (res.data.success) {
            var dataDict = res.data.data;
            if (dataDict.custList != null && dataDict.custList.length > 0) {
              that.custList = dataDict.custList;
              let listTemp = that.transformCustList(that.custList);
              // that.data = dataList;
              that.dataList = listTemp.slice();
            }
          }
        });
    },

    downClick() {
      // if (this.currentPage < Math.ceil(this.custList.length / this.pageSize)) {
      //   this.currentPage++;
      // }
      this.showFlag = false;
      this.pageSize = 500;
    },
    upClick() {
      // if (this.currentPage > 1) {
      //   this.currentPage--;
      // }
      this.showFlag = true;
      this.pageSize = 9;
    },

    clickEven() {
      console.log("sasasa");
    },
  },
};
</script>

<style lang="scss" scoped>
.page_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: transparent;
}

.page_body_heard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1920px;
  height: 600px;
}

.heard_image {
  width: 1200px;
  height: 600px;
}

.title_text_top {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 62px;
  color: #ffffff;
  line-height: 62px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.title_text {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 62px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  width: 100%;
  height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page_body_heard_mid {
  display: flex;
  flex-direction: row;
}

.page_body_heard_mid_cloum {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.title_text_mid {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  line-height: 62px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.title_text_mid2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 10px;
}

.mid_line {
  width: 0px;
  height: 56px;
  border: 1px solid #ffffff;
  margin-left: 80px;
  margin-right: 80px;
}

.page_body_heard_lower {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  cursor: pointer; /* 鼠标悬停时显示手指 */
}

.lower_view {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-left: 50px;
  width: 154px;
  height: 48px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 32px 32px 32px 32px;
  border: 1px solid #ffffff;
}

.lower_text {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  transition: font-size 0.3s ease;
}

// .page_body_heard_lower:hover .lower_text {
//   font-size: 20px;
// }

.page_body_heard_lower:hover .lower_view {
  border-color: gray; /* 鼠标悬停时边框颜色变为灰色 */
}
// .lower_text {
//   font-family: Source Han Sans, Source Han Sans;
//   font-weight: 400;
//   font-size: 14px;
//   color: #ffffff;
//   line-height: 14px;
//   text-align: left;
//   font-style: normal;
//   text-transform: none;
//   transition: transform 0.3s ease;
// }

// .lower_text.hovered {
//   transform: scale(1.2); /* 放大到原来的1.2倍 */
// }

.line_view {
  display: flex;
  flex-direction: column;
}

.page_body_list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1920px;
  // height: 100%;
  margin-top: 20rpx;
  background: #f4f7fa;
}

.page_body_list_bot {
  display: flex;
  flex-direction: column;
  width: 80%;
  // height: 100%;
  margin-top: 20rpx;
}

.list_text {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 28px;
  color: #191919;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.list_top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
}

.list_cloum {
  display: flex;
  // flex-wrap: wrap-reverse;
  flex-wrap: wrap;
  // flex-direction: row-reverse;
  // flex: 0 1 calc(33.33% - 10px);
}

.list_cloum_piece {
  height: 100px;
  margin: 5px;
  width: 466px;
  height: 482px;
  // background: #ffffff;
  background: #f4f7fa;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px 14px 14px 14px;
}

.list_cloum_piece:hover {
  background-color: white;
  cursor: pointer;
}

.list_cloum_piece_image {
  display: flex;
  overflow: hidden;
  border-radius: 14px 14px 0 0;
}

// .list_cloum_piece_image .page_image {
//   transform: scale(1.2);
// }
.page_image {
  // transition: transform 0.3s ease;
  width: 466px;
  height: 248px;
}

.title_text {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 20px;
  color: #353c58;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.list_cloum_piece_bot {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  widows: 100%;
  height: 200px;
}

.list_cloum_piece_title {
  // display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  overflow: hidden;
}

.list_cloum_piece_brief_introduction {
  display: flex;
  align-items: center;
  height: 40px !important;
  width: 406px;
  height: 40px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 14px;
  color: #788296;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.list_cloum_piece_line {
  width: 406px;
  height: 0px;
  border: 1px solid #ebecf0;
  margin-top: 20px;
}

.list_cloum_piece_lable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-top: 20px;
}

.lable_view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 32px;
  border-radius: 162px 162px 162px 162px;
  border: 1px solid #788296;
  margin-left: 10px;
}

.lable_view_text {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 12px;
  color: #788296;
  line-height: 12px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.lower_story {
  // display: flex;
  width: 1920px;
  height: 461px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  // padding: 50px;
}

.lower_story_top {
  display: flex;
  width: 100%;
  height: 120px;
}

.lower_story_lower {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  // height: 120px;
}

.lower_story_top_text {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 28px;
  color: #191919;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.element_lable {
  display: flex;
  // padding: 20px;
}

.lower_story_mid_lable {
  width: 40%;
  height: 206px;
  background: linear-gradient(180deg, #f4f5f8 0%, #fefefe 100%);
  box-shadow: 0px 8px 16px 0px rgba(192, 199, 218, 0.2);
  border-radius: 6px 6px 6px 6px;
  margin-right: 50px;
}

.lower_story_top_top {
  display: flex;
  width: 75%;
  padding: 20px 50px;
}

.element_lable {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lower_story_top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.story_logo {
  width: 120px;
  height: 90px;
}

.lower_story_top_left {
  display: flex;
}

.lower_story_top_right {
  display: flex;
  flex-direction: column;
  width: 533px;
  height: 84px;
  margin-left: 20px;
}

.lower_story_text {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 16px;
  color: #3d485d;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.el-carousel__arrow {
  display: none;
}

// .zoom-image {
//   transition: transform 0.3s ease;
// }

// .zoom-image:hover {
//   transform: scale(1.5);
// }

/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: 12.5%;
  bottom: 16.8%;
  transform: unset;
  right: unset;
  top: unset;
}

/deep/ .el-carousel__button {
  // 默认按钮的颜色大小等
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 50%;
  background: #e0dada;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  // 选中图片的颜色
  background: #0055ff;
  opacity: 1;
}
</style>
