<!-- 汇用工 -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="sceneImg" class="solu_scene body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>应用场景</p>
				</div>
				<div class="scene_wrap">
					<img v-lazy="sceneImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="architectureImg" :ref="menuList[1]" class="solu_architecture body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="architecture_wrap">
					<img v-lazy="architectureImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="planValues.length > 0" :ref="menuList[2]" class="solu_plan_values body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案价值</p>
				</div>
				<div class="values_wrap">
					<div class="value_wrap" v-for="(plan, index) in planValues" :key="index">
						<div class="item_title">
							<img v-lazy="__imagePath + plan.imageurl" alt="" />
							<p>{{ plan.title }}</p>
						</div>
						<div class="item_subtitle">
							<p>{{ plan.subTitle }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="advantages.length > 0" :ref="menuList[3]" class="solu_advantages body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>特色优势</p>
				</div>
				<ul class="advantages_wrap">
					<li v-for="(item, index) in advantages" :key="index">
						<div class="mode_wrap">
							<img v-lazy="__imagePath + item.imageurl" alt="" />
							<div class="mode">
								<p class="p1">{{ item.title }}</p>
								<div class="mode_subtitle">
									<div class="p_wrap" v-for="(sub, sIndex) in item.subtitles" :key="sIndex">
										<p>{{ '· ' + sub.title }}</p>
										<p v-if="sub.desc && sub.desc != ''" class="p_desc">{{ sub.desc }}</p>
									</div>

								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div :ref="menuList[4]" class="solu_download body_suit item dray_background">
			<solutionDataView type="hyg" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/hygSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {
				header: {
					title: '汇用工解决方案'
				},
				menuList: ['应用场景', '架构图', '方案价值', '特色优势', '相关资料'],
				sceneImg: null,
				architectureImg: null,

				planValues: [],
				advantages: [],
			};
		},

		created() {},
		mounted() {
			this.loadDatas()
		},

		methods: {

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},
			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let sceneBg = page.sceneImg;
				if (sceneBg) {
					that.sceneImg = that.__imagePath + sceneBg
				}
				let architectureImg = page.architectureImg;
				if (architectureImg) {
					that.architectureImg = that.__imagePath + architectureImg
				}
				let planValues = page.planValues;
				if (planValues && planValues.length > 0) {
					that.planValues = planValues
				}
				let advantages = page.advantages;
				if (advantages && advantages.length > 0) {
					that.advantages = advantages
				}
			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 86
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 应用场景 */
	.solu_scene {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.scene_wrap {

			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 架构图 */
	.solu_architecture {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.architecture_wrap {

			margin-top: 50px;
			padding: 0 40px;

			img {
				width: 100%;
			}
		}
	}

	/* 方案价值 */
	.solu_plan_values {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.values_wrap {
			margin-top: 60px;

			flex: 1;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 24px;

			.value_wrap {
				background-color: #FFFFFF;
				border-radius: 4px;

				padding: 20px 40px;

				display: flex;
				flex-direction: column;
				align-items: baseline;
				justify-content: center;

				.item_title {
					display: flex;
					flex-direction: row;
					align-items: center;

					p {
						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 20px;
						color: #333333;
					}

					img {
						width: 20px;
						height: 20px;

						object-fit: contain;

						margin-right: 20px;
					}

					margin-bottom: 10px;
				}

				.item_subtitle {
					padding-left: 40px;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #666666;
					}
				}

			}

			.value_wrap:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}

		}
	}

	/* 特色优势 */
	.solu_advantages {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.advantages_wrap {
			margin-top: 60px;

			li {
				width: 100%;

				display: flex;
			}
		}

		.mode_wrap {

			flex: 1;

			padding: 1.6% 2.2%;
			border-radius: 4px;
			border: 1px solid #DFE1E6;

			display: flex;
			align-items: center;

			img {
				width: 5%;
				aspect-ratio: 1;
			}

			.mode {

				margin-left: 2.2%;

				.p1 {
					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 20px;
					color: #333333;

					margin-bottom: 1.1%;
				}

				.mode_subtitle {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					align-items: center;

					.p_wrap {
						padding-right: 2.2%;
						padding-bottom: 5px;

						display: flex;
						flex-direction: column;
						justify-content: center;

						p {
							font-family: HanSans-Medium;
							font-weight: 500;
							font-size: 14px;
							color: #333333;
						}

						.p_desc {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #333333;
						}
					}
				}
			}
		}
	}


	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>