import Vue from 'vue'
import Box from '@/components/box/box.vue';
import yjysite from './yjyApp.vue'
import router from './yjyRoute.js'
import store from '@/store'
import ElementUI from 'element-ui';
import http from '@/plugins/http/http'

import '@/style/theme/index.css'
import '@/style/index.scss';
import '@/utils/xmlHttpRequestHook.js'

// 图片懒加载
import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload) //无配置项
// 配置项
// const loadimage = require('assets/img/common/loading.gif')
// const errorimage = require('assets/img/common/error.gif')
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载的宽高比
  // loading: loadimage, //图片加载状态下显示的图片
  // error: errorimage, //图片加载失败时显示的图片
  attempt: 1, // 加载错误后最大尝试次数
  silent: false
})

Vue.use(ElementUI, {
    size: 'mini', // medium / small / mini
}); // size用于改变组件的默认尺寸，zIndex 设置弹框的初始 z-index（默认值：2000）

Vue.config.productionTip = false
const HOST = window.location.protocol + '//' + window.location.host
Vue.use(http, {
    baseURL: HOST + process.env.VUE_APP_BACKEND_URL_PROXY
})

Vue.component('Box',Box);//div box
//是否开启 日志记录
Vue.prototype._LOGGGER = false
// Vue.prototype.__ftpPath =  window.location.protocol + '//' + window.location.host + process.env.VUE_APP_FTP_PATH;
Vue.prototype.__ftpPath =  window.location.protocol + '//' + (process.env.VUE_APP_MODE != 'development' ? window.location.host:'192.168.20.234:7788') + process.env.VUE_APP_FTP_PATH;
Vue.prototype.__imagePath = Vue.prototype.__ftpPath + 'yjyconfig/yjysite/';

window.__GetReleaseInfo = function () {
    console.info("release mode: " + process.env.VUE_APP_MODE)
    console.info("release version: " + process.env.VUE_APP_VERSION)
    console.info("relase time: " + process.env.VUE_APP_RELEASE_TIME)
    return true
}

router.afterEach((to) => {
    window.document.title = to.meta.title
})

new Vue({
    router,
    store,
    render: h => h(yjysite)
}).$mount('#app')

export const autoprefixer = function(style) {
  if (typeof style !== 'object') return style;
  const rules = ['transform', 'transition', 'animation'];
  const prefixes = ['ms-', 'webkit-'];
  rules.forEach(rule => {
    const value = style[rule];
    if (rule && value) {
      prefixes.forEach(prefix => {
        style[prefix + rule] = value;
      });
    }
  });
  return style;
};