<template>
	<div class="abu_body">
		<div class="abu_header body_suit item">
			<commonHeaderPage customBtn customBtnClick :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick">
				<img :src="headerPlayImg" alt="" />
				<p>视频介绍</p>
			</commonHeaderPage>
		</div>

		<!-- 公司介绍 -->
		<div v-if="companyIntro" ref="abu_company" class="abu_intro_company body_suit dray_background item">
			<div class="item_wrap">
				<div class="abu_title">
					<p>公司介绍</p>
				</div>
				<div class="company_video_wrap">
					<img v-if="!videoHidden" class="wrap_bg_img" v-lazy="__imagePath + companyIntro.coverImg" alt="" />
					<img v-if="!videoHidden" class="wrap_play_img" :src="companyPlayImg" alt="" @click="videoHidden = true" />
					<custom-video-player v-if="videoHidden" autoplay width="100%" height="100%"
						:poster="__imagePath + companyIntro.coverImg" :src=" __ftpPath + companyIntro.videoUrl " />
				</div>
				<div class="company_intro_wrap">
					<p>{{ companyIntro.title }}</p>
				</div>
			</div>
		</div>
		<!-- 团队介绍 -->
		<div v-if="teams.length > 0" ref="abu_team" class="abu_intro_team body_suit item">
			<div class="item_wrap">
				<div class="abu_title">
					<p>团队介绍</p>
				</div>
				<ul>
					<li v-for="(team, index) in teams" :key="index" :style="{marginTop: index == 0 ? '0':'52px'}">
						<div class="abu_team_item">
							<div class="team_item_img_wrap"
								:style="{order: index ? '2':'1', backgroundImage: 'url(' + __imagePath + team.bgImg + ')'}">
								<div class="img_content_wrap">
									<img class="team_item_img" v-lazy="__imagePath + team.iconImg" alt="" />
									<p>{{ team.title }}</p>
								</div>
							</div>
							<div class="team_item_text_wrap" :style="{order: index ? '1':'2'}">
								<div class="team_item_text">
									<p>{{ team.title }}</p>
									<p class="text_subtitle">{{ team.subTitle }}</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- 发展历程 -->
		<div v-if="historys.length > 0" ref="abu_history" class="abu_history body_suit item"
			:style="{backgroundImage: 'url(' + historyBgImg + ')'}">
			<div class="item_wrap">
				<div class="abu_title">
					<p class="history_title">发展历程</p>
				</div>
				<div class="his_wrap">
					<ul>
						<li v-for="(item, index) in historys" :key="index" :style="{marginTop: index == 0 ? '0':'69px'}"
							@mouseenter="onHistoryHover(item, index)">
							<div :id="'historyYearItem' + index" class="history_li_wrap">
								<p :class="{'his_p_active': historyIndex == index}">{{ item.year + '年' }}</p>
								<div class="his_line_wrap" :class="{'his_line_wrap_active': historyIndex == index}">
									<div class="his_point_wrap">
										<img class="his_point"
											:src="historyIndex == index ? historyPointImgActive:historyPointImg" />
									</div>
									<div class="his_line"></div>
								</div>
							</div>
						</li>
					</ul>
					<div id="historyLine" class="his_line_h_wrap" :style="{left: hisLineLeft + 'px'}">
						<div class="line_hover" :style="{top: hisHoverLineTop + 'px', height: hisHoverLineHeight + 'px'}"></div>
					</div>

					<div v-if="historyItem" class="his_right_wrap">
						<p>{{ historyItem.year }}</p>
						<ul>
							<li v-for="(event, index) in historyItem.events" :key="index"
								:style="{ marginTop: index == 0 ? '0':'2.9375rem'}">
								<div class="his_right_li_wrap">
									<img :src="historyLiImg" alt="" />
									<p>{{ event }}</p>
								</div>
							</li>
						</ul>
					</div>

				</div>
			</div>
		</div>
		<!-- 企业荣誉  -->
		<div v-if="honorList.length > 0" ref="abu_honor" class="abu_honor body_suit item"
			:style="{backgroundImage: 'url(' + honorBgImg + ')'}">
			<div ref="honorUL" class="item_wrap">
				<div class="abu_title">
					<p class="history_title">企业荣誉</p>
				</div>

				<div class="honor_item_wrap" v-for="(honor, hIndex) in honorList" :key="hIndex">
					<div class="honor_title_wrap">
						<div class="honor_title_line"></div>
						<p>{{ honor.title }}</p>
					</div>

					<div class="honor_ul_wrap">
						<div class="honor_box_wrap"> <!-- :style="honorStyles(hIndex)" -->
							<div v-for="(item, index) in honor.honors" :key="index" class="honor_box">
								<img v-lazy="item.imageUrl" alt="" />
								<p>{{ item.title }}</p>
							</div>
						</div>

						<div v-if="honor.moreShow" class="honor_more">
							<img :src="honorMoreImg" alt="" @click="onHonorMore(honor)" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="news.length > 0" class="abu_honor2 body_suit item"
			:style="{ backgroundImage: 'url(' + honorBottomBgImg + ')'}">
			<div class="item_wrap" style="height: 100%;">
				<div class="honor2_wrap">
					<img class="switch_img img_left" :src="isCLHover ? carouselLeftHover:carouselLeft"
						@mouseenter="isCLHover=true" @mouseleave="isCLHover=false" @click="changeHandle('prev')" />

					<custom-carousel ref="honorCarousel" style="width: 100%;" :interval="comBannerTime" :datas="news">
						<template v-slot="props">
							<div class="honor_carousel" @click="onHonorItem(props)">
								<img class="item_img" v-lazy="props.data.item.coverImg" alt="" />
								<p>{{ props.data.item.title }}</p>
							</div>
						</template>
					</custom-carousel>

					<img class="switch_img img_right" :src="isCRHover ? carouselRightHover:carouselRight"
						@mouseenter="isCRHover=true" @mouseleave="isCRHover=false" @click="changeHandle('next')" />
				</div>
			</div>
		</div>

		<!-- 联系我们  -->
		<div ref="abu_link" class="abu_link body_suit item">
			<div class="item_wrap">
				<div class="abu_title">
					<p class="history_title">联系我们</p>
				</div>

				<div class="link_ul_wrap">
					<ul>
						<li v-for="(item, index) in linkList" :key="index"
							:class="{ 'no_topmargin': index/2 < 1, 'no_leftmargin':index%2 == 0 }">
							<div class="link_li_wrap">
								<div class="link_title">
									<img :src="item.imgurl" alt="" />
									<p>{{ item.title }}</p>
								</div>

								<div class="link_content">
									<p>{{ item.subtitle }}</p>

									<div v-if="index == 0" class="link_content_next">
										<p>联系电话：{{ dataDo.tel }} <span
												style="margin-left: 20px;">{{ dataDo.phone }}</span></p>
									</div>
									<div v-if="index == 1" class="link_content_next">
										<p>邮箱：<span style="color: #0055FF;">{{ dataDo.email_1 }}</span></p>
										<p>联系电话：{{ dataDo.tel }} <span
												style="margin-left: 20px;">{{ dataDo.phone }}</span></p>
									</div>
									<div v-if="index == 2" class="link_content_next">
										<div class="jltd" @click="resumeDialogVisible=true">
											<p>简历投递</p>
										</div>

										<el-dialog :visible.sync="resumeDialogVisible" width="559px" :show-close="false"
											append-to-body>
											<div class="resume_send"
												:style="{ backgroundImage: 'url(' + resumeSendBgImg + ')'}">

												<div class="resume_title">
													<p>投递简历</p>
												</div>
												<div class="resume_close">
													<img :src="rsCloseHover ? rsCloseImgHover:rsCloseImg" alt=""
														@mouseenter="rsCloseHover=true" @mouseleave="rsCloseHover=false"
														@click="resumeDialogVisible=false" />
												</div>
												<div class="resume_subtitle">
													<p>简历请发送：<span style="color: #4879FF;">tianyu@gsh56.com</span></p>
													<p>如您的简历与岗位匹配</p>
													<p>我们会在收到邮件的三天之内与您联系</p>
													<p>感谢您的关注</p>
												</div>

											</div>
										</el-dialog>
									</div>
									<div v-if="index == 3" class="link_content_next">
										<div class="link_address">
											<p>总部：{{ dataDo.address }}</p>
											<img :src="linkLocImg" alt="" />
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>



			</div>
		</div>
	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import item from '../components/selector/item';
	import CustomVideoPlayer from "@/components/video/CustomVideoPlayer.vue"
	import CustomCarousel from "@/components/carousel/CustomCarousel.vue"
	import commonHeaderPage from "./solution/commonView/commonHeaderPage.vue"
	import localPage from "./json/yjyAboutUs.json"

	export default {
		components: {
			CustomVideoPlayer,
			CustomCarousel,
			commonHeaderPage
		},
		data() {
			return {
				scrollRef: null,

				header: null,
				headerPlayImg: require("@/assets/yjysite/aboutUs/abu_header_play.png"),
				menuList: ['公司介绍', '团队介绍', '发展历程', '企业荣誉', '联系我们'],

				companyIntro: null,
				companyPlayImg: require("@/assets/yjysite/yjysite_video_play.png"),
				videoHidden: false,

				teams: [],
				historys: [],
				historyItem: null,

				historyBgImg: require("@/assets/yjysite/aboutUs/abu_history_bg.png"),
				historyIndex: 0,
				historyPointImg: require("@/assets/yjysite/aboutUs/abu_history_point.png"),
				historyPointImgActive: require("@/assets/yjysite/aboutUs/abu_history_point_active.png"),
				hisLineLeft: 269,
				hisHoverLineTop: 0,
				historyLiImg: require("@/assets/yjysite/aboutUs/abu_history_right_li_img.png"),

				honorList: [],

				honorBgImg: require("@/assets/yjysite/aboutUs/abu_honor_bg.png"),
				honorBottomBgImg: require("@/assets/yjysite/aboutUs/abu_honor_bottom_bg.png"),
				honorMoreImg: require("@/assets/yjysite/aboutUs/abu_honor_more.png"),
				honorBoxMore2: false,
				honorBoxMore3: false,
				honorLimitHeight: (1440 - 90) / 6,
				linkLocImg: require("@/assets/yjysite/aboutUs/abu_link_icon_location.png"),

				news: [],

				switchIndex: 0,
				switchDir: '',
				comBannerTime: 3000, // 轮播图的自动播放间隔时间
				carouselLeft: require("@/assets/yjysite/aboutUs/abu_honor_carousel_left.png"),
				carouselRight: require("@/assets/yjysite/aboutUs/abu_honor_carousel_right.png"),
				carouselLeftHover: require("@/assets/yjysite/aboutUs/abu_honor_carousel_left-hover.png"),
				carouselRightHover: require("@/assets/yjysite/aboutUs/abu_honor_carousel_right-hover.png"),
				isCLHover: false,
				isCRHover: false,
				hisHoverLineHeight: 0,

				dataDo: {
					phone: '189-0053-5353',
					tel: '400-111-5856',
					email_1: 'serrvice@xiekangfeng.com', // 售后邮箱
					email_2: 'hrd@tianyu.com', //招聘邮箱
					address: '安徽省芜湖市绿地新都会C座15楼'
				},
				linkList: [{
					title: '在线咨询',
					subtitle: '如果您对我们的产品感兴趣，想深入了解、体验产品，请联系我们的产品咨询团队：',
					imgurl: require("@/assets/yjysite/aboutUs/abu_link_icon_online.png")
				}, {
					title: '售后服务咨询',
					subtitle: '如果您在使用产品的过程中有任何疑问、建议或意见，欢迎向我们提出：',
					imgurl: require("@/assets/yjysite/aboutUs/abu_link_icon_aftersale.png")
				}, {
					title: '招贤纳士',
					subtitle: '如果您对我们企业感兴趣。就快快加入我们吧！',
					imgurl: require("@/assets/yjysite/aboutUs/abu_link_icon_Invite.png")
				}, {
					title: '公司地址',
					subtitle: '如果您在使用产品的过程中有任何疑问、建议或意见，欢迎向我们提出：',
					imgurl: require("@/assets/yjysite/aboutUs/abu_link_icon_address.png")
				}],

				resumeDialogVisible: false,
				resumeSendBgImg: require("@/assets/yjysite/aboutUs/abu_link_resumesend_bg.png"),
				rsCloseImg: require("@/assets/yjysite/aboutUs/abu_link_rs_close.png"),
				rsCloseImgHover: require("@/assets/yjysite/aboutUs/abu_link_rs_close_h.png"),
				rsCloseHover: false
			};
		},
		
		created() {
			this.getDataList();
		},
		mounted() {
			let that = this;

			if (that.$route.query != null && that.$route.query.aboutType == 1) {
				that.selectIndex = 1;
			}
			if (window.name === '') {
				document.documentElement.scrollTop = 0
			}

			window.addEventListener("resize", this.windowChange, false);
			window.addEventListener("popstate", this.popstate, false);

		},
		destroyed() {
			window.removeEventListener("resize", this.windowChange, false);
			window.removeEventListener("popstate", this.popstate, false);
		},

		methods: {
			windowChange() {
				const honorUL = this.$refs.honorUL;
				if (honorUL) {
					let rectHo = honorUL.getBoundingClientRect();
					this.honorLimitHeight = (rectHo.width - 90) / 6 * 2 + 20;
				}
			},
			popstate() {
				let that = this;

				let url = window.location.href
				let temp = url.split('?')[1]
				let params = new URLSearchParams('?' + temp)
				that.scrollRef = params.get('scrollRef')

				console.log('scrollRef :' + that.scrollRef)

				if (that.scrollRef) {
					that.scrollToEL()
				} 
			},
			scrollToEL() {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ this.elRef() }`]
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
					that.scrollRef = null
				})
			},
			// aboutusList: ['公司介绍', '团队介绍', '发展历程', '企业荣誉', '联系我们'],
			elRef() {
				if (this.scrollRef === '公司介绍') return 'abu_company'
				if (this.scrollRef === '团队介绍') return 'abu_team'
				if (this.scrollRef === '发展历程') return 'abu_history'
				if (this.scrollRef === '企业荣誉') return 'abu_honor'
				if (this.scrollRef === '联系我们') return 'abu_link'
				return 'abu_company'
			},
			
			headerBtnClick(e) {
				console.log('headerBtnClick')
				this.scrollRef = '公司介绍'
				this.scrollToEL()
				
				if (this.videoHidden == true) return
				this.videoHidden = true
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				if (this.scrollRef == e) return
				this.scrollRef = e

				this.scrollToEL()
			},

			onHistoryHover(item, index) {
				if (this.historyIndex == index) return
				this.historyIndex = index
				this.historyItem = item

				const ele = document.getElementById('historyYearItem' + index)
				const eleP = document.getElementById('historyLine') // 父视图
				if (ele && eleP) {
					let rect = ele.getBoundingClientRect()
					let rectP = eleP.getBoundingClientRect()

					console.log('rectP:' + JSON.stringify(rectP))

					let top = rect.y - rectP.y
					if (index == 0) {
						this.hisHoverLineTop = top - 49
						this.hisHoverLineHeight = 146 // 166-(69-49)
					}else if (index == this.historys.length - 1) {
						this.hisHoverLineTop = top - 69
						this.hisHoverLineHeight = 134 // 166-(69-37)
					}else {
						this.hisHoverLineTop = top - 69
						this.hisHoverLineHeight = 166 
					}
				}
			},
			onHonorMore(honor) {
				console.log('honor:' + JSON.stringify(honor))
				honor.moreShow = false
				if (honor.mores && honor.mores.length > 0) {
					let honors = [...honor.honors, ...honor.mores]
					// honor.honors = honors
					this.$set(honor, 'honors', honors)
				}

				console.log('honor:' + JSON.stringify(this.honorList))
			},
			honorStyles(index) {
				if (index == 0) return {}

				let more = this.honorMore(index)
				return {
					overflow: 'hidden',
					overflowY: 'auto',
					maxHeight: more ? (this.honorLimitHeight + 'px') : ''
				}
			},
			honorMore(index) {
				return index == 0 ? false : (index == 1 ? this.honorBoxMore2 : this.honorBoxMore3)
			},

			onResumeSend() {
				console.log('onResumeSend')

				this.resumeDialogVisible = true
			},

			changeHandle(dir) {
				if (dir === 'prev') {
					this.$refs.honorCarousel.prev();
				}
				if (dir === 'next') {
					this.$refs.honorCarousel.next();
				}
			},
			onHonorItem(props) {
				console.log('onHonorItem:' + JSON.stringify(props.data.item))
			},

			handlePage(page) {
				let that = this
				console.log('page: ' + JSON.stringify(page))

				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let companyIntro = page.companyIntro
				if (companyIntro) {
					that.companyIntro = companyIntro
				}

				let teams = page.teams
				if (teams && teams.length > 0) {

					that.teams = teams
				}


				let historys = page.historys
				if (historys && historys.length > 0) {
					that.historys = historys

					that.historyItem = historys[0]
				}

			},
			getDataList() {
				this.handlePage(localPage);
				
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getAboutUsInfo", {}).then((response) => {

					if (response.data.success) {
						var dataDict = response.data.data;
						let pages = response.data.data.pages;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}

						if (that.honorList.length > 0) that.honorList = []
						let zizhiList = response.data.data.zizhiList
						if (zizhiList && zizhiList.length > 0) {
							// "title": "权威资质",
							// "honors": []
							// "mores": []
							// zizhiList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
							let dict = {
								title: '资质认证',
								moreShow: false
							}
							if (zizhiList.length > 12) {
								let honors = zizhiList.splice(0, 12)
								dict.honors = honors
								dict.mores = zizhiList
								dict.moreShow = true
							} else {
								dict.honors = zizhiList
							}
							that.honorList.push(dict)
						}
						let zhuanliList = response.data.data.zhuanliList
						if (zhuanliList && zhuanliList.length > 0) {
							let dict = {
								title: '专利',
								moreShow: false
							}
							if (zhuanliList.length > 12) {
								let honors = zhuanliList.splice(0, 12)
								dict.honors = honors
								dict.mores = zhuanliList
								dict.moreShow = true
							} else {
								dict.honors = zhuanliList
							}
							that.honorList.push(dict)
						}
						let ruanzhuList = response.data.data.ruanzhuList
						if (ruanzhuList && ruanzhuList.length > 0) {
							let dict = {
								title: '软著',
								moreShow: false
							}
							if (ruanzhuList.length > 12) {
								let honors = ruanzhuList.splice(0, 12)
								dict.honors = honors
								dict.mores = ruanzhuList
								dict.moreShow = true
							} else {
								dict.honors = ruanzhuList
							}
							that.honorList.push(dict)
						}

						let companyNews = response.data.data.companyNews
						if (companyNews && companyNews.length > 0) {
							that.news = companyNews
						}
						that.windowChange()
						that.popstate()
					}
				});
			},

		},
	}
</script>


<style lang="scss" scoped>
	.abu_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		background-color: transparent;

		// background-color: #FFF;

		ul {
			list-style: none;
			list-style-type: disc;
			padding: 0;
			margin: 0 auto;
		}
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;

		// background-color: cornsilk;
	}

	.abu_title {

		width: 100%;
		display: flex;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #353C58;
			line-height: 28px;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}
	}

	/*  header  */
	.abu_header {

		flex-direction: column;

		.abu_header_wrap {

			width: 100%;
			aspect-ratio: 5.49;
			margin: 0 auto;

			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center;

			display: flex;
			justify-content: center;

		}

		.abu_header_menu {

			max-height: 64px;
			max-height: 4rem;
			aspect-ratio: 30;

			padding: 0 12.5%;

			display: flex;
			align-items: center;
			background-color: #FFF;

			ul {
				flex: 1;
				list-style: none;
				width: auto;
				height: 100%;
				overflow: hidden;
				/* 设置x轴可滑动 */
				overflow-x: auto;
				/* 不换行 */
				white-space: nowrap;

				display: flex;
				align-items: center;
			}

			/* 隐藏滚动条 */
			ul::-webkit-scrollbar {
				display: none;
			}

			li {
				/* 保持所有li在同一行 */
				display: inline-block;

				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 14px;
				color: #353C58;
				line-height: 14px;
				text-align: left;
			}

			li:hover {
				color: #0055FF !important;
				cursor: pointer;
			}

			.active {
				color: #0055FF !important;
			}
		}
	}

	/*  公司介绍  */
	.abu_intro_company {
		padding: 3.23% 0;

		.company_video_wrap {

			margin-top: 1.98%;
			width: 100%;
			aspect-ratio: 2.939;
			max-height: 490px;
			max-height: 30.625rem;

			position: relative;

			display: flex;
			justify-content: center;
			align-items: center;

			.wrap_bg_img {
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				height: 100%;

				z-index: 20;
			}

			.wrap_play_img {
				width: 5.35%;
				aspect-ratio: 1;

				z-index: 21;
			}

			.wrap_play_img:hover {
				cursor: pointer;
			}
		}

		.company_intro_wrap {
			margin-top: 2.1875%;

			display: flex;
			justify-content: center;

			p {
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 16px;
				color: #64698B;
				line-height: 16px;
				text-align: left;
			}
		}

	}

	/*  团队介绍  */
	.abu_intro_team {
		background-color: #FFF;

		padding: 3.23% 0;

		ul {
			width: 100%;
			margin-top: 2.78%;

			flex: 1;
			list-style: none;

			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		li {
			display: flex;
			aspect-ratio: 3.6;

			background-color: #F6F9FE;
		}

		.abu_team_item {

			width: 100%;
			height: 100%;

			display: flex;
			// align-items: center;
			justify-content: space-between;

			.team_item_img_wrap {
				flex: 1;

				display: flex;
				justify-content: center;
				align-items: center;

				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: center;

				.img_content_wrap {

					flex: 1;

					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					p {
						margin-top: 2.78%;

						font-family: HanSans-Blod;
						font-weight: 700;
						font-size: 30px;
						color: #FFFFFF;
						line-height: 30px;
						text-align: center;
					}
				}
			}

			.team_item_text_wrap {
				flex: 0.92;

				display: flex;
				justify-content: center;
				align-items: center;

				.team_item_text {
					flex: 1;
					display: flex;
					flex-direction: column;

					padding: 0 10.4%;

					p {
						font-family: HanSans-Blod;
						font-weight: 700;
						font-size: 24px;
						color: #353C58;
						line-height: 24px;
						text-align: left;
					}

					.text_subtitle {
						margin-top: 6%;
						
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #788296;
						line-height: 26px;
					}
				}
			}

			.team_item_img {
				// 95 81
				width: 6.6%;
				aspect-ratio: 95 / 81;
			}
		}
	}

	/*  发展历程  */
	.abu_history {

		padding: 3.23% 0;

		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;

		.history_title {
			color: #FFF;
		}

		.his_wrap {
			flex: 1;
			display: flex;

			margin-top: 2.625rem;
			margin-top: 2.625%;

			position: relative;
		}

		ul {
			width: 13.9%;

			margin-top: 49px;
			margin-bottom: 37px;
			margin-left: 170px;
			list-style: none;

			li {
				display: flex;
			}

			.history_li_wrap {
				flex: 1;
				height: 28px;

				display: flex;
				align-items: center;

				p {
					width: 70px;
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 20px;
					color: rgba(255, 255, 255, 0.5);
					text-align: left;
				}

				.his_p_active {
					color: #FFFFFF !important;
				}

				.his_line_wrap {
					margin-left: 18px;
					height: 24px;

					display: flex;
					align-items: center;

					.his_point_wrap {
						flex: 1;
						height: 24px;
						aspect-ratio: 1;

						display: flex;
						align-items: center;
						justify-content: center;
					}

					.his_point {
						width: 14px;
						height: 14px;
					}

					.his_line {
						background: transparent;

						width: 88px;
						height: 2px;
					}
				}

				.his_line_wrap_active {

					.his_point {
						width: 100%;
						height: 100%;
					}

					.his_line {
						background: linear-gradient(30deg, #45ADFD 0%, rgba(70, 173, 253, 0) 100%) !important;
					}
				}
			}

			.history_li_wrap:hover {
				cursor: pointer;
			}
		}

		.his_line_h_wrap {
			position: absolute;
			width: 2px;
			height: 100%;

			background-color: rgba(128, 236, 254, 0.3);

			.line_hover {
				position: absolute;
				left: 0;
				top: 0;
				width: 2px;
				height: 146px;

				background-color: #80ECFE;
			}
		}


		.his_right_wrap {
			flex: 1;

			display: flex;
			flex-direction: column;

			margin-left: 3.4375rem;
			margin-left: 3.82%;
			padding-top: 0.8125rem;
			padding-top: 0.8125%;

			p {
				font-family: Arial, Arial;
				font-weight: bold;
				font-size: 46px;
				color: #FFFFFF;
				line-height: 46px;
				text-align: left;
			}

			ul {
				flex: 1;
				width: 100%;
				list-style: none;

				margin: 3.1875% 0;
			}

			li {
				display: flex;
			}

			.his_right_li_wrap {
				flex: 1;
				display: flex;
				align-items: center;

				img {
					width: 15px;
					height: 15px;

					margin-right: 0.8125rem;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 18px;
					color: #FFFFFF;
					line-height: 18px;
					text-align: left;
				}
			}
		}

	}

	/* 企业荣誉 */
	.abu_honor {
		padding: 3.23% 0;

		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;

		// 资质认证
		.honor_item_wrap {
			width: 100%;

			display: flex;
			flex-direction: column;

			margin-top: 4.3125rem;
			margin-top: 4.3125%;

			.honor_title_wrap {
				display: flex;
				flex-direction: column;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 20px;
					color: #353C58;
					line-height: 28px;
					text-align: left;

					margin-top: 1.25rem;
					margin-top: 1.25%;
				}

				.honor_title_line {
					width: 50px;
					height: 1px;
					background: #FF9000;
				}
			}

			.honor_ul_wrap {
				flex: 1;
				display: flex;
				flex-direction: column;
				margin-top: 2.75rem;
				margin-top: 2.75%;


				.honor_box_wrap {
					display: grid;
					grid-template-columns: repeat(6, 1fr);
					grid-template-rows: repeat(2, min-content);
					grid-gap: 16px 18px;

					.honor_box {
						aspect-ratio: 1;

						background-color: white;
						border-radius: 4px;

						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: center;

						img {
							width: calc(100% - 60px);
							object-fit: cover;
							aspect-ratio: 1.273;
							margin: 0 30px;
							margin-top: 30px;
							background-color: rgba(0, 0, 0, 0.1);
						}

						p {
							flex: 1;
							margin: 0 30px;

							display: flex;
							align-items: center;

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 16px;
							color: #3D4461;
							text-align: center;
							line-height: 19px;
						}

					}

					.honor_box:hover {
						box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

						p {
							font-family: HanSans-Blod;
							font-weight: 700;
							line-height: 16px;
						}
					}
				}

				// ul {
				// 	flex: 1;
				// 	width: 100%;

				// 	list-style: none;
				// 	display: flex;
				// 	flex-wrap: wrap;
				// }

				// /* 隐藏滚动条 */
				// ul::-webkit-scrollbar {
				// 	display: none;
				// }

				// .honor_ul_scroll {
				// 	overflow: hidden;
				// 	overflow-y: auto;
				// }

				// li {
				// 	flex-basis: calc((100% - 90px) / 6);
				// 	aspect-ratio: 1;

				// 	margin-left: 18px;
				// 	margin-top: 16px;

				// 	img {
				// 		width: 100%;
				// 		height: 100%;
				// 	}
				// }

				// .honor_li_no_leftmargin {
				// 	margin-left: 0;
				// }

				// .honor_li_no_topmargin {
				// 	margin-top: 0;
				// }

				.honor_more {
					display: flex;
					justify-content: center;

					margin: 0 auto;
					margin-top: 1.25rem;
					margin-top: 1.25%;
					height: 2.625%;

					img {
						height: 100%;
						aspect-ratio: 1;
					}

					img:hover {
						cursor: pointer;
					}
				}
			}

		}
	}

	.abu_honor2 {
		// display: flex;
		aspect-ratio: 5.334;

		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;

		.honor2_wrap {
			flex: 1;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.switch_img {
				width: 42px;
				height: 42px;
				width: 2.625rem;
				height: 2.625rem;

				padding: 0 30px;

				transition: all 0.3s ease;

			}

			.img_left {
				padding-left: 0;
			}

			.img_right {
				padding-right: 0;
			}

			.switch_img:hover {
				cursor: pointer;
			}

			.honor_carousel {
				padding: 16px 16px 25px 16px;
				padding: 1rem 1rem 1.5625rem 1rem;
				background-color: #FFFFFF;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				border-radius: 0.625rem;
				box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

				p {
					margin-top: 1.5625rem;

					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 14px;
					color: #3D4461;
					line-height: 14px;
					text-align: center;

					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.item_img {
					width: 100%;
					aspect-ratio: 1.46;

					border-radius: 4px;
				}
			}

		}
	}

	.abu_link {
		padding: 3.23% 0;
		background-color: #FFFFFF;

		.link_ul_wrap {
			flex: 1;
			display: flex;
			flex-direction: column;
			margin-top: 2.5rem;
			margin-top: 2.5%;

			ul {
				flex: 1;
				width: 100%;

				display: flex;
				flex-wrap: wrap;
				list-style: none;
			}

			li {
				flex-basis: calc((100% - 20px) / 2);
				height: 20.5625rem;
				background-color: #F7F8FC;

				margin-top: 21px;
				margin-left: 20px;

				.link_li_wrap {
					flex: 1;

					height: 100%;
					padding: 0 2rem;

					display: flex;
					flex-direction: column;

					.link_title {
						width: 100%;
						display: flex;
						align-items: center;

						margin-top: 2.5rem;

						img {
							width: 13.2%;
							aspect-ratio: 1;
							margin-right: 1.4375rem;
						}

						p {
							font-family: HanSans-Blod;
							font-weight: 700;
							font-size: 28px;
							color: #353C58;
							line-height: 28px;
							text-align: left;
						}
					}

					.link_content {
						flex: 1;
						width: 100%;

						margin-top: 1.9375rem;
						margin-bottom: 2.5rem;

						display: flex;
						flex-direction: column;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #5B627E;
							line-height: 30px;
							text-align: left;
						}

						.link_content_next {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: flex-end;

							position: relative;

							.jltd {
								position: absolute;
								bottom: 0;
								left: 0;

								padding: 16px 60px;
								padding: 1rem 3.75rem;

								display: block;
								flex-direction: row;


								background-color: #0055FF;
								box-shadow: 0px 8px 20px 0px rgba(18, 57, 134, 0.3);
								border-radius: 53px;

								p {

									font-family: HanSans-Blod;
									font-weight: 700;
									font-size: 20px;
									color: #FFFFFF;
									line-height: 20px;
									text-align: center;
								}
							}

							.jltd:hover {
								cursor: pointer;
							}

							.link_address {
								display: flex;
								align-items: center;

								img {
									width: 18px;
									height: 22px;

									margin-left: 10px;
								}
							}

						}
					}
				}
			}

			.no_topmargin {
				margin-top: 0;
			}

			.no_leftmargin {
				margin-left: 0;
			}
		}
	}

	/deep/ .el-dialog {
		background-color: transparent;
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

	}

	.resume_send {

		aspect-ratio: 1.87;

		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center;

		display: flex;
		flex-direction: column;
		align-items: center;

		.resume_title {

			height: 16.5%;

			display: flex;
			flex-direction: row;
			align-items: center;

			p {
				font-family: HanSans-Medium;
				font-weight: 500;
				font-size: 24px;
				color: #FFFFFF;
			}
		}

		.resume_subtitle {

			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 16px;
				color: #333333;
				text-align: center;

				margin-bottom: 14px;
				margin-bottom: 0.875rem;
			}
		}

		.resume_close {

			width: 75%;
			height: 35px;
			height: 2.1875rem;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			img {
				width: 15px;
				height: 15px;
			}

			img:hover {
				width: 28px;
				height: 28px;

				cursor: pointer;
			}
		}
	}
</style>