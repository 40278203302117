<!-- 智能仓储解决方案 -->
<template>
	<div class="solution_body">
		<div class="zncc_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>
		<div v-if="scenes.length > 0" class="zncc_scene body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>多种应用场景及运作</p>
				</div>
				<div class="scene_wrap">
					<div class="scene" v-for="(scene, index) in scenes" :key="index">
						<img v-lazy="__imagePath + scene.imageUrl" alt="" />
						<div class="scene_title">
							<p class="p1">{{ scene.contentTitle }}</p>
							<p>{{ scene.content }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="archBgImg" :ref="menuList[1]" class="zncc_architecture body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="arch_wrap">
					<img v-lazy="archBgImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="planBgImg" :ref="menuList[2]" class="zncc_plans body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案价值</p>
				</div>
				<div class="plan_wrap">
					<img v-lazy="planBgImg" alt="" />
				</div>
			</div>
		</div>

		<div v-if="casesSpecs.length > 0 && casesSpecs2.length > 0" :ref="menuList[3]"
			class="zncc_cases body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>客户案例</p>
				</div>
			</div>
			<div class="case_wrap">
				<img :src="casesBgImg" alt="" />
				<div class="item_wrap">
					<div class="case_content">
						<div v-if="casesSpecs.length > 0" class="content_top">
							<ul>
								<li v-for="(item, index) in casesSpecs" :key="index">
									<p class="p1">{{ item.title }}</p>
									<p>{{ item.subtitle }}</p>
								</li>
							</ul>
						</div>
						<div v-if="casesSpecs2.length > 0" class="content_bottom">
							<div class="bottom_wrap" v-for="(item, index) in casesSpecs2" :key="index">
								<div class="bottom_title" @click="onCaseTap(item, index)">
									<div class="title_wrap">
										<p>{{ item.title }}</p>
										<div class="bottom_line"
											:class="{'bottom_line_selected': currentCaseIndex != index}" />
									</div>
								</div>
								<div class="bottom_subtitle">
									<p :class="{'p_active': currentCaseIndex == index }">{{ item.subtitle }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div :ref="menuList[4]" class="zncc_download body_suit item dray_background">
			<solutionDataView type="zncc" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>
	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue";
	import solutionDataView from "./commonView/solutionDataView.vue";
	import solutionCasesView from "./commonView/solutionCasesView.vue";
	import localPage from "../json/znccSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView,
		},
		data() {
			return {
				header: {
					title: "智能仓储解决方案",
					subTitle: "充分结合现有的设施设备低投入实现数字化，仓储作业与物流优化完美结合， 作业可视化与库存可视化提升供应链柔性",
				},

				menuList: ["应用场景", "架构图", "方案价值", "客户案例", "相关资料"],
				archBgImg: null,
				planBgImg: null,
				casesBgImg: require("@/assets/yjysite/solution/zncc_cases_bg.png"),

				scenes: [],

				casesSpecs: [],
				casesSpecs2: [],

				currentCaseIndex: 0,
			};
		},

		created() {},
		mounted() {
			this.loadDatas();
		},

		methods: {
			scrollToEL(e) {
				console.log("scrollToEL");
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${e}`];
					console.log("element:" + element);
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				});
			},

			headerBtnClick(e) {
				console.log("headerBtnClick");
			},
			headerMenuClick(e) {
				console.log("headerMenuClick:" + e);
				this.scrollToEL(e);
			},
			onCaseTap(item, index) {
				this.currentCaseIndex = index;
			},
			onDownload() {},
			onVideo() {},

			handlePage(page) {
				let that = this;

				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let archBgImg = page.archBgImg;
				if (archBgImg) {
					that.archBgImg = that.__imagePath + archBgImg
				}
				let planBgImg = page.planBgImg;
				if (planBgImg) {
					that.planBgImg = that.__imagePath + planBgImg
				}
				let casesBgImg = page.casesBgImg;
				if (casesBgImg) {
					that.casesBgImg = that.__imagePath + casesBgImg
				}
				let scenes = page.scenes;
				if (scenes && scenes.length > 0) {
					that.scenes = scenes
				}

				let cases = page.cases;
				if (cases) {
					if (cases.specs) {
						that.casesSpecs = cases.specs
					}
					if (cases.specs2) {
						that.casesSpecs2 = cases.specs2
					}
				}
			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 83,
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
						let cases = response.data.data.cases;
						if (cases && cases.length > 0) {
							that.$set(that, "cases", cases);
						}
					}
				});
			}
		},
	};
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.zncc_header {
		display: flex;
		flex-direction: column;
	}

	/* 模块功能 */
	.zncc_scene {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.scene_wrap {
			margin-top: 50px;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 0 21px;

			.scene {
				height: 100%;

				display: flex;
				flex-direction: column;

				border-radius: 14px;
				background-color: #ffffff;

				img {
					width: 100%;
					aspect-ratio: 1.879;
					object-fit: contain;
				}

				.scene_title {
					flex: 1;

					padding: 24px 30px;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #788296;
					}

					.p1 {
						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 18px;
						color: #353c58;

						margin-bottom: 20px;
						margin-bottom: 1.25rem;
					}
				}
			}

			.scene:hover {
				box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 架构图 */
	.zncc_architecture {
		display: flex;
		flex-direction: column;

		background-color: #ffffff;
		padding: 70px 0;
		padding: 4.375rem 0;

		.arch_wrap {
			margin-top: 48px;
			margin-top: 3rem;

			img {
				width: 100%;
			}
		}
	}

	/* 方案规划 */
	.zncc_plans {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.plan_wrap {
			margin-top: 65px;

			img {
				width: 100%;
			}
		}
	}

	.zncc_cases {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
		background-color: #ffffff;

		.case_wrap {
			margin-top: 70px;

			position: relative;

			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center;

			display: flex;
			flex-direction: column;

			img {
				width: 100%;
				aspect-ratio: 4;
				position: absolute;
				top: 0;
				left: 0;

				z-index: 0;
			}

			.case_content {
				z-index: 21;

				margin: 0 8.3%;
				margin-top: 45px;
				max-width: 1200px;

				display: flex;
				flex-direction: column;

				.content_top {
					background: linear-gradient(360deg, #4b6fc8 0%, rgba(75, 111, 200, 0) 100%);

					ul {
						margin: 60px 75px;
					}

					li {
						margin-bottom: 20px;
						display: flex;
						flex-direction: column;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 16px;
							color: #ffffff;
						}

						.p1 {
							margin-bottom: 10px;

							font-family: HanSans-Medium;
							font-weight: 500;
							font-size: 24px;
							color: #ffffff;
						}
					}

					// .top_wrap
				}

				.content_bottom {
					background-color: #ffffff;

					padding: 60px 0;
					box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);

					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 0;

					.bottom_wrap {
						display: flex;
						flex-direction: column;

						.bottom_title {
							display: flex;
							flex-direction: column;
							align-items: center;

							border-bottom: 1px solid #d8d8d8;

							.title_wrap {
								display: flex;
								flex-direction: column;

								p {
									font-family: HanSans-Medium;
									font-weight: 500;
									font-size: 20px;
									color: #333333;
								}

								.bottom_line {
									margin-top: 18px;
									background-color: #4b6fc8;
									height: 2px;
									width: 100%;
								}

								.bottom_line_selected {
									background-color: transparent;
								}
							}
						}

						.bottom_subtitle {
							padding: 22px 45px;

							p {
								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								font-size: 16px;
								color: #666666;
							}

							.p_active {
								font-size: 17px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}

	.zncc_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>