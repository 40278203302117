<template>
	<div class="sc_page">
		<div class="sc_header body_suit">
			<commonHeaderPage :model="header" />
		</div>
		<div class="sc_case body_suit">

			<div class="item_wrap" v-for="(item, index) in solutionList" :key="index">
				<div class="item_header">
					<p>{{ item.title }}</p>
				</div>

				<div class="scc_cell_body">
					<div class="cell_item" v-for="(cell, cellIndex) in item.items" :key="cellIndex">
						<img v-lazy="__imagePath + cell.icon"></img>
						<p class="cell_item_title">{{ cell.title }}</p>
						<p class="cell_item_sub">{{ cell.desc }}</p>
						<div class="cell_item_btn" @click="caseItemAction(cell)">
							<p>{{ cell.btnName ? cell.btnName: '了解详情' }}</p>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./solution/commonView/commonHeaderPage.vue";
	import localPage from "./json/yjySolutions.json"

	export default {
		components: {
			commonHeaderPage
		},
		data() {
			return {
				
				header: null,
				solutionList: null
			}
		},

		created() {
			this.loadDatas()
		},

		methods: {
			caseItemAction(item) {
				let fillName = item.route + 'Solution'
				let path = 'solution/' + fillName

				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/" + path;
				console.log('showProductDetailAction:' + url);
				document.documentElement.scrollTop = 0;	
				window.location.href = url;
			},
			
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}
				
				let solutions = page.solutions;
				if (solutions && solutions.length > 0) {
					that.solutionList = solutions
				}
			
			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 75
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
					}
				});
			}
			
		}
	};
</script>


<style lang="scss" scoped>
	.sc_page {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;

		background-color: #F4F7FA;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		// align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
		}

		.p_sub {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}


	.sc_header {
		display: flex;
		flex-direction: column;
	}

	.sc_case {

		padding: 5% 0 5% 0;

		display: grid;
		grid-template-columns: 100%;
		grid-gap: 65px 0;

		.scc_cell_body {
			margin-top: 1.7%;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 21px;
		}

		.cell_item:hover {
			box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
		}

		.cell_item {

			// 466px

			display: flex;
			flex-direction: column;
			align-items: flex-start;

			padding: 8.5% 10%;

			background: #FFFFFF;
			border-radius: 14px;

			img {
				width: 15%;
				aspect-ratio: 1;
			}

			.cell_item_title {

				font-family: HanSans-Blod;
				font-weight: 700;
				font-size: 20px;
				color: #3D4461;

				margin-top: 6.8%;
			}

			.cell_item_sub {
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 16px;
				color: #64698B;

				margin-top: 2.1%;
				min-height: 100px;
			}

			.cell_item_btn {

				margin-top: 5.5%;
				padding: 0 6%;
				height: 38px;

				border-radius: 19px;
				border: 1px solid #64698B;

				display: flex;
				align-items: center;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #3D4461;
					text-align: center;
				}
			}

			.cell_item_btn:hover {
				border: 1px solid rgba(100, 105, 139, 0.5);
				cursor: pointer;
			}
		}
	}
</style>