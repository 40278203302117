<template>
        <div class="page_body_list">
      <div class="page_body_list_bot">


          <div class="page_body_list_arrange" v-for="(item, index) in paginatedData" :key="index" @click="showDetail(item)">
            <div class="page_body_list_clount">
               <div class="list_clount_left">
                  <p class="date_text">{{dateFormat(item.releaseTime)[2]}}</p>
                  <p class="xie_gang">/</p>
                  <div class="month_year">
                    <p class="month_year_text">{{dateFormat(item.releaseTime)[1]+'月'}}</p>
                    <p class="month_year_text">{{dateFormat(item.releaseTime)[0]}}</p>
                  </div>
               </div>
               
               <div class="list_clount_line"></div>

               <div class="list_clount_right">
                 <div class="list_clount_right_top">
                   <p class="title_text">{{item.title}}</p>
                 </div>
                 <div class="list_clount_right_lower">
                   <p class="content_text">{{item.intro}}</p>
                 </div>
               </div>
            </div>
            <div class="page_body_list_line"></div>
          </div>

          

          <div class="pagination-container">
          <el-pagination
            :background = "false"
            layout="sizes, prev, pager, next, total"
            :total="total"
            :page-size.sync="pageSize"
            @current-change="pageChange"
            :current-page.sync="currentPage"
          ></el-pagination>
          </div>

          <div class="page_list_zhan_wei"></div>

       </div>
    </div>
</template>



<script>
import aEntityAPI from "@/api/sysAPI.js";


export default {
  components: {

  },
  data() {
    return {
        currentPage: 1, // 当前页码
        pageSize: 10,    // 每页显示的行数
        dataList: [],
    };
  },

  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.dataList.slice((this.currentPage-1) * this.pageSize, ((this.currentPage-1) * this.pageSize) + this.pageSize);
    },
  },
  created() {
    this.clickEven()
  },

  mounted(){
    debugger
    this.getCompanyInfo();
  },

  updated() {},
  destroyed() {},

  methods: {
    getCompanyInfo() {
      if(this.total<=this.dataList.length){
        return;
      }
      let that = this;
      let params={
            start: this.pageSize * (this.currentPage - 1),
            limit: 10,
             q:{
                 dictCode: "'NEWS_TYPE_MS_COMPANY'",
                 fields:[
                     "id",
                     "title",
                     "intro",
                     "category",
                     "categoryId",
                     "coverImg",
                     "releaseTime",
                     "status",
                     "content"
                 ],
                 sort:"id__desc"
           }

      }
      aEntityAPI
        .request("NewsService", "getYgxNewsList", {qp: JSON.stringify(params)})
        .then((res) => {
          if (res.data.success) {
            let dataList = this.dataList != [] ? this.dataList : [];
            let dataTemporary = res.data.data;
            dataList = dataList.concat(dataTemporary.slice());
            this.dataList = dataList;
            console.log("this.dataList------>"+this.dataList);
            this.total = res.data.total;
          }
        });
    },

    dateFormat(date){
      return date.split(" ")[0].split("-");
    },

    pageChange(pageSize){
      this.currentPage = pageSize;
      this.getCompanyInfo();
    },

    showDetail(item) {
      debugger
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/yjysite.html#/news/yjyNewDetail?id=" +
        item.id;
        console.log("url----->"+url)
        // window.close();
      window.open(url);
    },

    clickMenu(index){
      this.menuIndex = index;
    },

    clickEven() {
      console.log("sasasa");
    },
  },
};
</script>

<style lang="scss" scoped>
.page_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 68px;
  background-color: transparent;
}

.page_body_heard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1920px;
  height: 300px;
}

.heard_image {
  width: 1200px;
  height: 300px;
}

.page_body_list{
  display: flex;
  width: 1920px;
  background: rgba(253, 253, 253, 0.965);
  
}

.page_body_list_bot{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 240px;
  margin-top: 20px;
}

.page_body_list_top{
  display: flex;
  width: 100%;
  height: 100px;
}

.page_body_price{
  margin-right: 20px;
}

.menu_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 18px;
  color: #353C58;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.menu_text_active{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 18px;
  color: #0055FF;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.page_body_list_arrange{
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.page_body_list_clount{
  display: flex;
}

.list_clount_left{
  display: flex;
  align-items: center;
  justify-content: center;
}

.month_year{
  display: flex;
  flex-direction: column;
}

.date_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 38px;
  color: #353C58;
  line-height: 38px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.xie_gang{
  width: 8px;
  height: 20px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 20px;
  color: #64698B;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-right: 5px;
}

.month_year_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 15px;
  color: #64698B;
  line-height: 15px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.list_clount_line{
  width: 0px;
  height: 74px;
  border: 1px solid #DFE1E6;
  margin: 0 50px;
}

.list_clount_right{
  display: flex;
  flex-direction: column;
}

.title_text{
 font-family: Source Han Sans, Source Han Sans;
 font-weight: 700;
 font-size: 20px;
 color: #252B3A;
 line-height: 20px;
 text-align: left;
 font-style: normal;
 text-transform: none;
}

.content_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 20px;
}

.page_body_list_line{
  width: 1441px;
  height: 0px;
  border: 1px solid #DFE1E6;
  margin-top: 37px;
}

.page_body_heard_bot{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 480px;
}

.heard_title_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  font-size: 42px;
  color: #353C58;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.heard_content_text{
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 20px;
  color: #64698B;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 20px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px; /* 添加一些间距 */
}

.page_body_list_arrange {
    cursor: pointer; /* 鼠标指针样式 */
}

.page_body_list_arrange:hover .title_text {
    color: #0055FF; /* 当鼠标悬停时，标题字体颜色变为蓝色 */
}

.list_clount_right_lower{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page_list_zhan_wei{
  width: 100%;
  height: 80px;
}



</style>