<!-- 精选客户案例 -->
<template>
	<div class="sc_body">
		<el-carousel v-if="model.length > 0" class="sc_carousel" indicator-position="outside" :interval="interval"
			:height="carouselHeight" :style="{marginTop: carouselTop}">
			<el-carousel-item class="sc_case-item" v-for="(items, index) in cases" :key="index"
				:style="itemStyles(index)">
				<div v-for="(item, cIndex) in items" :key="cIndex" @mouseenter="item.active=true"
					@mouseleave="item.active=false"
					:class="{'sc_case-item_border' : showBorder, 'sc_case-item_active': item.active==true && showShadow }">
					<slot :item="item" />
				</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	export default {
		name: 'solutionDataView',
		components: {},
		emits: ['downloadClick', "videoClick"],
		props: {
			model: {
				type: Array,
				default () {
					return []
				}
			},
			title: {
				type: String,
				default: '客户案例'
			},
			interval: {
				type: Number,
				default: 3000
			},
			carouselTop: {
				type: String,
				default: '0px'
			},
			type: {
				type: String,
				default: ''
			},
			column: {
				type: [String, Number],
			},
			columnMargin: {
				type: String,
				default: '0px'
			},
			carouselHeight: {
				type: String,
				default: '0px'
			},
			showBorder: {
				type: Boolean,
				default: true
			},
			showShadow: {
				type: Boolean,
				default: true
			}
		},
		computed: {},
		mounted() {
			if (this.column * 1 > 1 && this.model.length > 0) {
				this.initDatas()
			}
		},

		data() {
			return {
				cases: []
			};
		},

		methods: {
			initDatas() {
				for (let i = 0; i < this.model.length; i += this.column) {
					this.cases.push(this.model.slice(i, i + this.column))
				}

				// console.log('solutionDataView cases:' + JSON.stringify(this.cases))
			},
			itemStyles(index) {
				return {
					'grid-template-columns': 'repeat(' + this.column + ', 1fr)',
					'grid-gap': '0 ' + this.columnMargin
				}
			},
			dataFill(item, index, cIndex) {
				let data = {
					item: item,
					index: index
				}
				console.log('dataFill: ' + data)
				return data
			}
		},
	}
</script>

<style scoped lang="scss">
	.sc_body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.sc_title {

		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}

	}

	.sc_carousel {
		width: 100%;
	}

	.sc_case-item {
		display: grid;

		background-color: #FFFFFF;
	}

	.sc_case-item_border {
		border: 1px solid #EAF0FC;
		border-radius: 8px;
	}

	.sc_case-item_active {
		isolation: isolate;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
		z-index: 31;
		position: relative;
	}
</style>