<!-- 人力众包/灵活用工 -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="lhygImg" class="solu_lhyg body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>灵活用工</p>
				</div>
				<div class="lhyg_wrap">
					<img v-lazy="lhygImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="outsourcingImg" :ref="menuList[1]" class="solu_outsourcing body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>服务外包</p>
				</div>
				<div class="outsourcing_wrap">
					<img v-lazy="outsourcingImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="sceneImg" :ref="menuList[2]" class="solu_scene body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>应用场景</p>
				</div>
				<div class="scene_wrap">
					<img v-lazy="sceneImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="plans.length > 0" :ref="menuList[3]" class="solu_plans body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案优势</p>
				</div>
				<div class="plans_wrap">
					<div class="plan_item" v-for="(plan, index) in plans" :key="index">
						<div class="plan_wrap">
							<img v-lazy="__imagePath + plan.imageurl" alt="" />
							<p class="p_title">{{ plan.title }}</p>

							<ul>
								<li v-for="(sub, sIndex) in plan.subtitles" :key="sIndex">
									<p>{{ sub }}</p>
								</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div v-if="cases.length > 0" :ref="menuList[4]" class="solu_cases body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>精选客户案例</p>
				</div>

				<solutionCasesView column="2" column-margin="24px" carousel-top="50px" :model="cases"
					:carousel-height="caseItemHeight + 'px'">
					<template v-slot="{ item }">
						<div class="case_wrap">
							<img v-lazy="item.subImageUrl" alt="" />
							<div class="case_title">
								<p class="p1">{{ item.title  }}</p>
								<p>{{ item.subTitle }}</p>
							</div>
						</div>
					</template>
				</solutionCasesView>

			</div>
		</div>


		<div :ref="menuList[5]" class="solu_download body_suit item dray_background">
			<solutionDataView type="lhyg" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/lhygSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '众包产品介绍-灵工解决方案'
				},
				menuList: ['灵活用工', '服务外包', '应用场景', '方案优势', '客户案例', '相关资料'],
				lhygImg: null,
				outsourcingImg: null,
				sceneImg: null,

				plans: [],

				caseItemHeight: 374,
				cases: []
			};
		},

		created() {},
		mounted() {
			window.addEventListener("resize", this.windowChange, false);

			this.loadDatas()
		},
		destroyed() {
			window.removeEventListener("resize", this.windowChange, false);
		},

		methods: {
			windowChange() {
				const wrap = this.$refs[`${ '客户案例' }`]
				if (wrap) {
					this.$nextTick(() => {
						let rect = wrap.getBoundingClientRect();
						this.caseItemHeight = rect.width * 374 / 1920;
					})
				}
			},

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},
			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this

				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let lhygImg = page.lhygImg;
				if (lhygImg) {
					that.lhygImg = that.__imagePath + lhygImg
				}
				let outsourcingImg = page.outsourcingImg;
				if (outsourcingImg) {
					that.outsourcingImg = that.__imagePath + outsourcingImg
				}
				let sceneImg = page.sceneImg;
				if (sceneImg) {
					that.sceneImg = that.__imagePath + sceneImg
				}
				let plans = page.plans;
				if (plans && plans.length > 0) {
					that.plans = plans
				}
			},
			loadDatas() {
				this.handlePage(localPage);

				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 85
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}

						let cases = response.data.data.cases;
						if (cases && cases.length > 0) {
							that.$set(that, 'cases', cases)
						}
					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 灵活用工 */
	.solu_lhyg {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.lhyg_wrap {

			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 服务外包 */
	.solu_outsourcing {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.outsourcing_wrap {

			margin-top: 75px;
			padding: 0 65px;

			img {
				width: 100%;
			}
		}
	}

	/* 应用场景 */
	.solu_scene {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.scene_wrap {
			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 应用场景 */
	.solu_plans {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.plans_wrap {
			margin-top: 60px;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 20px;

			.plan_item {

				padding: 40px 0 20px 0;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				background-color: #FFFFFF;
				border: 1px solid #DFE1E6;
				border-radius: 10px;


				.plan_wrap {
					flex: 1;
					width: 100%;

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.p_title {
						margin-top: 10px;

						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 18px;
						color: #353C58;
					}


					img {
						width: 30px;
						height: 30px;

						object-fit: contain;
					}

					ul {
						margin-top: 20px;
					}

					li {
						flex: 1;
						padding: 0 40px;

						p {

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #788296;
							text-align: center;
						}
					}
				}
			}

			.plan:hover {
				box-shadow: 0px 6px 26px 0px rgba(0, 0, 0, 0.1);
			}
		}
	}

	.solu_cases {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
		background-color: #FFFFFF;

		.case_wrap {

			height: 100%;
			display: flex;
			flex-direction: column;

			img {
				width: 100%;
				aspect-ratio: 3.77;
				object-fit: contain;
				background-color: rgba(0, 0, 0, 0.1);

			}

			.case_title {

				padding: 4.2%;
				padding-bottom: 0;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #788296;

					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 18px;
					color: #353C58;

					-webkit-line-clamp: 1;

					margin-bottom: 2.8%;
				}
			}

		}
	}


	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>