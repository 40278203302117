<!-- 物流与供应链大数据交易平台 -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage show-shadow :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="architectureImg" class="solu_architecture body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>平台框架</p>
				</div>
				<div class="architecture_wrap">
					<img v-lazy="architectureImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="dataValues.length > 0" :ref="menuList[1]" class="solu_data_value body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>数据资产化推动企业数据价值显性化</p>
				</div>
				<div class="data_value_wrap">
					<div class="data_value" v-for="(item, index) in dataValues" :key="index">
						<div class="item_title">
							<img v-lazy="__imagePath + item.imageurl" alt="" />
							<p>{{ item.title }}</p>
						</div>
						<div class="item_subtitle">
							<p>{{ item.subTitle }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="services.length > 0" :ref="menuList[2]" class="solu_services body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>平台服务</p>
				</div>
				<ul>
					<li v-for="(item, index) in services" :key="index">
						<div class="service_wrap">
							<img v-lazy="__imagePath + item.imageurl" alt="" />
							<div class="service_title">
								<p class="p_title">{{ item.title }}</p>
								<ul>
									<li v-for="(detail, dIndex) in item.details" :key="dIndex">
										<p class="p1">{{ '• ' + detail.title + '：'}}</p>
										<p>{{ detail.subtitle }}</p>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div v-if="opportunity" :ref="menuList[3]" class="solu_opportunity body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>市场机遇</p>
				</div>
				<div class="opportunity_wrap">
					<div class="oppor_title">
						<p class="p1">{{ opportunity.title }}</p>
						<ul>
							<li v-for="(sub, sIndex) in opportunity.subTitles" :key="sIndex">
								<p>{{ '• ' + sub }}</p>
							</li>
						</ul>
					</div>
					<div class="oppor_img">
						<img v-lazy="__imagePath + opportunity.imageurl" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="customors" :ref="menuList[4]" class="solu_customors body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>客户群体</p>
				</div>
				<div class="customors_wrap">
					<div class="customor_img">
						<img v-lazy="__imagePath + customors.imageurl" alt="" />
					</div>
					<ul>
						<li v-for="(item, index) in customors.titles" :key="index" :class="{'li_zero': index == 0}">
							<p class="p1">{{ item.title }}</p>
							<p>{{ item.subtitle }}</p>
						</li>
					</ul>
				</div>
			</div>
		</div>


		<div :ref="menuList[5]" class="solu_download body_suit item dray_background">
			<solutionDataView type="dsjpt" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/dsjptSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '物流与供应链大数据交易平台'
				},
				menuList: ['平台框架', '数据价值', '平台服务', '市场机遇', '客户群体', '相关资料'],
				architectureImg: null,

				opportunity: null,
				customors: null,

				dataValues: [],
				services: []

			};
		},

		created() {},
		mounted() {
			this.loadDatas()
		},

		methods: {

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},

			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let architectureImg = page.architectureImg;
				if (architectureImg) {
					that.architectureImg = that.__imagePath + architectureImg
				}
				let values = page.dataValues;
				if (values && values.length > 0) {
					that.dataValues = values
				}
				let services = page.services;
				if (services && services.length > 0) {
					that.services = services
				}
				let opportunity = page.opportunity;
				if (opportunity) {
					that.opportunity = opportunity
				}
				let customors = page.customors;
				if (customors) {
					that.customors = customors
				}

			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 90
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}

		.p_sub {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 平台架构图 */
	.solu_architecture {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.architecture_wrap {

			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 数据价值 */
	.solu_data_value {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.data_value_wrap {
			margin-top: 60px;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 24px;

			.data_value {
				background-color: #FFFFFF;
				border-radius: 4px;

				padding: 20px 40px;

				display: flex;
				flex-direction: column;
				align-items: baseline;
				justify-content: center;

				.item_title {
					display: flex;
					flex-direction: row;
					align-items: center;

					p {
						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 20px;
						color: #333333;
					}

					img {
						width: 20px;
						height: 20px;

						object-fit: contain;

						margin-right: 20px;
					}

					margin-bottom: 10px;
				}

				.item_subtitle {
					padding-left: 40px;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #666666;
					}
				}

			}

			.data_value:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 平台服务 */
	.solu_services {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		ul {
			margin-top: 60px;
			margin-top: 3.75rem;
		}

		li {
			width: 100%;

			display: flex;
		}

		.service_wrap {

			flex: 1;

			padding: 1.6% 2.2%;
			border-radius: 4px;
			border: 1px solid #DFE1E6;

			display: flex;
			align-items: flex-start;

			img {
				width: 5%;
				aspect-ratio: 1;
			}

			.service_title {
				margin-left: 2.1%;

				.p_title {
					font-weight: 700;
					font-size: 20px;
					color: #333333;
					font-family: HanSans-Blod;
				}

				ul {
					margin-top: 1.1%;

					li {
						.p1 {
							font-family: HanSans-Medium;
							font-weight: 500;
							color: #333333;
						}

						p {

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #666666;
						}
					}
				}
			}
		}
	}

	/* 市场机遇 */
	.solu_opportunity {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.opportunity_wrap {

			margin-top: 65px;

			display: flex;
			flex-direction: row;
			align-items: center;

			.oppor_title {
				flex: 1;
				margin-right: 18%;

				display: flex;
				flex-direction: column;

				.p1 {
					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 20px;
					color: #333333;

					margin-bottom: 1.4%;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
				}

			}

			.oppor_img {
				width: 42.6%;

				img {
					width: 100%;
				}
			}
		}
	}

	/* 客户群体 */
	.solu_customors {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.customors_wrap {

			margin-top: 65px;

			display: flex;
			flex-direction: row;
			align-items: center;

			ul {
				flex: 1;
				margin-left: 10%;

				li {
					display: flex;
					flex-direction: column;
					margin-top: 3.3%;

					.p1 {
						font-family: HanSans-Blod;
						font-weight: 700;
						font-size: 20px;
						color: #333333;

						margin-bottom: 1.6%;
					}

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #666666;
					}
				}

				.li_zero {
					margin-top: 0;
				}
			}

			.customor_img {
				width: 42.6%;

				img {
					width: 100%;
				}
			}
		}
	}


	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>