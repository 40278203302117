<template>
	<div>
		<div class="wlhy_header_wrap" :style="{backgroundImage:  'url(' + (model && model.bgUrl ? model.bgUrl:defaultBgUrl) + ')'}">  <!--  -->
			<!-- <img class="header_bg" v-if="model && model.bgUrl" :src="model.bgUrl" /> -->
			<div class="header_wrap">
				<p>{{ model && model.title ? model.title:'' }}</p>
				<p class="p_subtitle">{{ model && model.subTitle ? model.subTitle : '' }}</p>
				
				<div v-if="customBtn" class="header_button_wrap" @click="onBtn">
					<slot></slot>
				</div>
				<div v-else class="header_button_wrap" @click="onBtn">
					<p>{{ model && model.btnName ? model.btnName: '方案咨询' }}</p>
				</div>
			</div>
		</div>
		<div v-if="menus && menus.length > 0" ref="stickyElement" class="wlhy_header_menu" :class="{'wlhy_header_menu-top' : isSticky, 'wlhy_header_menu-shadow': showShadow}">
			<ul>
				<li v-for="(menu, index) in menus" :key="index" :style="{marginLeft: index == 0 ? '0':'4.0625%'}"
					:class="{'active': currentMenu === menu}" @click="onMenu(menu)">
					{{ menu }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		emits: ['menuClick', "btnClick"],
		props: {
			model: {
				type: Object,
				default () {
					return {}
				}
			},
			menus: {
				type: Array,
				default () {
					return []
				}
			},
			showShadow: {
				type: Boolean,
				default: false
			},
			customBtn: {
				type: Boolean,
				default: false
			},
			customBtnClick: {
				type: Boolean,
				default: false
			},
		},

		data() {
			return {
				currentMenu: null,
				
				defaultBgUrl: require("@/assets/yjysite/case/sc_title_bg.png"),
				
				isSticky: false,
				stickyOffset: 0
			};
		},
		mounted() {
			this.currentMenu = this.menus[0]
			
			window.addEventListener("scroll", this.handleScroll, false);
			// this.stickyOffset = this.$refs.stickyElement.offsetTop;
		},
		
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll, false);
		},
		methods: {
			handleScroll() {
				// console.log('window.scrollY:' + window.scrollY +' stickyOffset' + this.stickyOffset)
				// this.isSticky = window.scrollY >= this.stickyOffset;
			},
			
			onBtn() {
				if (this.customBtnClick) {
					this.$emit("btnClick", null);
				}else {
					this.$parent.$parent.zxzx()
				}
			},
			onMenu(menu) {
				this.currentMenu = menu
				this.$emit("menuClick", menu);
			},

		},
	}
</script>

<style lang="scss" scoped>
	
	.wlhy_header_wrap {

		width: 100%;
		aspect-ratio: 5.49;
		margin: 0 auto;

		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;

		display: flex;
		justify-content: center;
		
		.header_bg {
			
			position: absolute;
			z-index: 0;
			left: 0;
			top: 0;
			
			width: 100%;
			height: 100%;
		}

		.header_wrap {
			flex: 1;
			z-index: 21;

			margin: 0 12.5%;

			display: flex;
			flex-direction: column;
			justify-content: center;

			p {
				font-family: HanSans-Blod;
				font-weight: 700;
				font-size: 42px;
				color: #353C58;
				line-height: 56px;
				text-align: left;

			}

			.p_subtitle {

				margin-top: 15px;
				margin-top: 0.9375rem;
				
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 20px;
				color: #64698B;
				line-height: 26px;
				
				white-space: pre-wrap;
			}

			.header_button_wrap {
				margin-top: 37px;
				margin-top: 2.3125rem;

				height: 46px;
				height: 2.875rem;
				aspect-ratio: 3.48;
				max-width: 10rem;
				background-color: #0055FF;
				border-radius: 32px;

				display: flex;
				align-items: center;
				justify-content: center;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 18px;
					color: #FFFFFF;
					line-height: 18px;
					text-align: left;
				}
				
				img {
					width: 17px;
					width: 1.0625rem;
					aspect-ratio: 1;
					margin-right: 7px;
					margin-right: 0.4375rem;
				}
			}

			.header_button_wrap:hover {
				background-color: #3377FF !important;
				cursor: pointer;
			}

		}
	}
	
	.wlhy_header_menu-top {
	  position: fixed;
	  top: 78px;
	  // left: 0;
	  z-index: 1000;
	  
	  height: 64px;
	  height: 4rem;
	  
	}
	
	.wlhy_header_menu-shadow {
		position: relative;
		z-index: 21;
		box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
	}
	
	.wlhy_header_menu {

		max-height: 64px;
		max-height: 4rem;
		aspect-ratio: 30;

		padding: 0 12.5%;

		display: flex;
		align-items: center;
		background-color: #FFF;

		ul {
			flex: 1;
			list-style: none;
			width: auto;
			height: 100%;
			overflow: hidden;
			/* 设置x轴可滑动 */
			overflow-x: auto;
			/* 不换行 */
			white-space: nowrap;

			display: flex;
			align-items: center;
		}

		/* 隐藏滚动条 */
		ul::-webkit-scrollbar {
			display: none;
		}

		li {
			/* 保持所有li在同一行 */
			display: inline-block;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 14px;
			color: #353C58;
			line-height: 14px;
			text-align: left;
			
		}

		li:hover {
			color: #0055FF !important;
			cursor: pointer;
		}

		.active {
			color: #0055FF !important;
		}
	}

</style>