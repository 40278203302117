<!-- 解决方案下载 -->
<template>
	<div class="dd_body">
		<div class="dd_title">
			<p>相关资料</p>
		</div>

		<div class="dd_conetnt">
			<div class="c_wrap margin-right">
				<div class="dd_wrap common_style">
					<div class="title_wrap">
						<img :src="dataImg" alt="" />
						<p>{{ titleByType() }}</p>
					</div>
					<div class="sub_wrap" @click="downloadTemplate">
						<img :src="downloadImg" alt="" />
						<p>下载文档</p>
						<!-- <a style="display: none" :href="templateUrl()" :download="titleByType()" ref="downloadTemplate">下载文档</a> -->
					</div>
				</div>
			</div>
			<div class="c_wrap">
				<div class="video_wrap common_style">
					<div class="sub_wrap" @click="onVideo">
						<img :src="videoImg" alt="" />
						<p>观看产品视频</p>
					</div>
				</div>
			</div>
		</div>

		<video-dialog v-model="dialogVideoVisible" :videoSrc="__ftpPath + 'yjyconfig/shipin/网络货运.mp4'"
			:customStyle="videoDialogStyle" />
	</div>
</template>

<script>
	import videoDialog from "@/components/video/videoDialog.vue"

	export default {
		name: 'solutionDataView',
		components: {
			videoDialog
		},
		emits: ['downloadClick', "videoClick"],
		props: {
			type: { // 1 智能运输 2 网络货运 3 数字水运
				type: String,
				default: ''
			},
		},

		data() {
			return {
				dataImg: require("@/assets/yjysite/solution/wlhy_solution_data.png"),
				downloadImg: require("@/assets/yjysite/solution/wlhy_solution_download.png"),
				videoImg: require("@/assets/yjysite/solution/wlhy_solution_video.png"),

				dialogVideoVisible: false,
				videoDialogStyle: {
					width: '80%',
					height: '686px',
					minWidth: '1200px',
					maxWidth: '1440px'
				}
			};
		},
		methods: {
			titleByType() {
				if (this.type === 'znys') return '智能运输解决方案.pdf'
				if (this.type === 'wlhy') return '网络货运解决方案.pdf'
				if (this.type === 'szsy') return '数字水运解决方案.pdf'
				if (this.type === 'dsly') return '多式联运解决方案.pdf'
				if (this.type === 'zncc') return '智能仓储解决方案.pdf'
				if (this.type === 'znyq') return '智能物流园区解决方案.pdf'
				if (this.type === 'lhyg') return '众包产品介绍-灵工解决方案.pdf'
				if (this.type === 'hyg') return '汇用工解决方案.pdf'
				if (this.type === '56saas') return '物流SaaS产品解决方案.pdf'
				if (this.type === 'hrsaas') return '工众人力SaaS解决方案.pdf'
				if (this.type === 'cswlpt') return '城市物流资源整合平台.pdf'
				if (this.type === 'dsjpt') return '物流与供应链大数据交易平台.pdf'
				if (this.type === 'gylkzt') return '供应链控制塔.pdf'
				if (this.type === 'ztb') return '物流招投标解决方案.pdf'

				return ''
			},
			templateUrl() {
				let url = this.__ftpPath + 'yjyconfig/' + this.titleByType()
				console.log('templateUrl:' + url)
				return url
			},

			onVideo() {
				// this.$emit("videoClick", this.type);

				this.dialogVideoVisible = true;
			},
			downloadTemplate() {
				// this.$emit("downloadClick", this.type);

				// let url = this.__ftpPath + 'yjyconfig/' + this.titleByType()
				let url = this.__ftpPath + 'yjyconfig/pdf/' + this.titleByType()
				console.log('url:' + url)
				// this.fileAjax(
				// 	url,
				// 	function(xhr) {
				// 		this.downloadFile(xhr.response, this.titleByType());
				// 	}, {
				// 		responseType: 'blob',
				// 	}
				// );
				fetch(url, {
					method: 'get',
					responseType: 'arraybuffer',
				}).then(res => {
					if (res.status !== 200) {
						return res.json()
					}
					return res.arrayBuffer()
				}).then(blobRes => {
					const e = new Blob([blobRes], {
						type: 'application/octet-stream',
						'Content-Disposition': 'attachment'
					})
					// 将 Blob 对象转为 url
					const link = window.URL.createObjectURL(e)
					let a = document.createElement('a');
					a.href = link;
					a.download = this.titleByType();
					a.click();
					window.URL.revokeObjectURL(link);
					
				}).catch(error => {
					this.$message.error('文件下载失败:' + error);
				})
			},

			fileAjax(url, callback, options) {
				let xhr = new XMLHttpRequest();
				xhr.open('get', url, true);
				if (options.responseType) {
					xhr.responseType = options.responseType;
				}
				xhr.onreadystatechange = function() {
					if (xhr.readyState === 4 && xhr.status === 200) {
						callback(xhr);
					}
				};
				xhr.send();
			},

			downloadFile(content, filename) {
				window.URL = window.URL || window.webkitURL;
				let a = document.createElement('a');
				let blob = new Blob([content]);
				// 通过二进制文件创建url
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = filename;
				a.click();
				// 销毁创建的url
				window.URL.revokeObjectURL(url);
			},
		},
	}
</script>

<style scoped lang="scss">
	.dd_body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}

	.dd_title {

		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}

	}

	.dd_conetnt {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		margin-top: 60px;
		margin-top: 3.75rem;

		.c_wrap {

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

		}

		.c_wrap:hover {
			cursor: pointer;
		}

		.margin-right {
			margin-right: 100px;
			margin-right: 6.25rem;
		}


		.dd_wrap {
			width: 380px;
			height: 140px;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.title_wrap {
				display: flex;
				flex-direction: row;
				align-items: center;

				img {
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 18px;
					color: #353C58;
				}

			}

			.sub_wrap {
				display: flex;
				flex-direction: row;
				align-items: center;

				margin-top: 28px;

				img {
					width: 15px;
					height: 15px;
					margin-right: 9px;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 16px;
					color: #0055FF;
				}


			}

			.sub_wrap:hover {
				p {
					text-decoration: underline;
				}

				img {
					width: 17px;
					height: 17px;
				}
			}
		}

		.video_wrap {
			width: 380px;
			height: 140px;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.sub_wrap {
				display: flex;
				flex-direction: row;
				align-items: center;

				img {
					width: 18px;
					height: 18px;
					margin-right: 9px;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 16px;
					color: #0055FF;
				}


			}

			.sub_wrap:hover {
				p {
					text-decoration: underline;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.common_style {
			margin-bottom: 16px;
			margin-bottom: 1rem;

			background: #FFFFFF;
			box-shadow: 0px 4px 10px 0px #E6E9F1;
		}
	}
</style>