<template>
	<div>
		<el-carousel ref="carousel" v-if="datas.length != 0" type="card" arrow="never"
			:interval="interval">
			<custom-carousel-item v-for="(item, index) in datas" :key="index">
				<slot :data="dataFill(item, index)" />
			</custom-carousel-item>
		</el-carousel>
	</div>
</template>
<script>
	import CustomCarouselItem from './CustomCarouselItem.vue';

	export default {
		name: 'CustomCarouse',
		components: {
			CustomCarouselItem
		},
		props: {
			datas: {
				type: Array,
				default () {
					return [];
				}
			},
			interval: {
				type: Number,
				default: 3000
			}
		},
		emits: ["change"],
		data() {
			return {
				arrow: 'nerver',
				height: ''
			}
		},
		mounted() {
			if (this.interval < 3000) this.interval = 3000
		},
		methods: {

			prev() {
				this.$refs.carousel.prev();
			},
			next() {
				this.$refs.carousel.next();
			},

			change(e) {
				this.$emit('change', e)
			},
			dataFill(item, index) {
				let data = {
					item: item,
					index: index
				}
				console.log('dataFill: ' + data)
				return data
			}
		},
	}
</script>

<style lang="scss" scoped>
	.el-carousel__item {
		// border-radius: 10px;
		// box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

		display: flex;
		justify-content: center;
		align-items: center;
	}

	::v-deep.el-carousel__item--card {
		width: 20%;
	}
</style>