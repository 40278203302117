<!-- 共享IT部解决方案 -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="sceneImg" class="solu_scene body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>市场分析</p>
				</div>
				<div class="scene_wrap">
					<img v-lazy="sceneImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="values.length > 0" :ref="menuList[1]" class="solu_values body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>使用共享IT部的好处</p>
				</div>
				<div class="values_wrap">
					<div class="value" v-for="(value, index) in values" :key="index">
						<p class="p1">{{ value.title }}</p>
						<p>{{ value.subTitle }}</p>
					</div>
				</div>
			</div>
		</div>
		<div v-if="contents.length > 0" :ref="menuList[2]" class="solu_contents body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>主要内容</p>
				</div>
				<ul>
					<li v-for="(item, index) in contents" :key="index">
						<div class="content_wrap">
							<img v-lazy="__imagePath + item.imageurl" alt="" />
							<div class="content_title">
								<p class="p1">{{ item.title }}</p>
								<p>{{ item.subtitle }}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div v-if="qas.length > 0" :ref="menuList[3]" class="solu_qas body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>问题答疑</p>
				</div>
				<div class="qas_wrap">
					<div class="qa" v-for="(item, index) in qas" :key="index">
						<p class="p1">{{ item.q }}</p>
						<p>{{ item.a }}</p>
					</div>
				</div>
			</div>
		</div>

		<div :ref="menuList[2]" class="solu_download body_suit item dray_background">
			<solutionDataView type="dsjpt" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/gxitbSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '共享IT部解决方案',
					subTitle: '提供全方位技术服务，成为您企业的IT专家'
				},
				menuList: ['应用场景', '平台价值', '相关资料'],
				sceneImg: null,

				values: [],
				contents: [],
				qas: []

			};
		},

		created() {
			this.loadDatas()
		},
		mounted() {},

		methods: {

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},

			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header;
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = this.__imagePath + header.bgUrl;
					}
					that.header = header;
				}
				
				let sceneBg = page.sceneBg;
				if (sceneBg) {
					that.sceneImg = that.__imagePath + sceneBg
				}
				let values = page.values;
				if (values && values.length > 0) {
					that.values = values
				}
				let contents = page.contents;
				if (contents && contents.length > 0) {
					that.contents = contents
				}
				let qas = page.qas;
				if (qas && qas.length > 0) {
					that.qas = qas
				}

			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 92
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}

		.p_sub {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 应用场景 */
	.solu_scene {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.scene_wrap {

			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 使用共享IT部的好处 */
	.solu_values {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.values_wrap {
			margin-top: 65px;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 38px 20px;

			.value {

				border-radius: 10px;
				border: 1px solid #DFE1E6;

				padding: 24px 50px;

				display: flex;
				flex-direction: column;

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 20px;
					color: #333333;

					margin-bottom: 10px;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
				}

			}

			.value:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 主要内容 */
	.solu_contents {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		ul {
			margin-top: 65px;
		}

		li {
			width: 100%;

			display: flex;
		}

		.content_wrap {

			flex: 1;
			background-color: #FFFFFF;

			padding: 1.6% 2.2%;
			border-radius: 4px;
			border: 1px solid #DFE1E6;

			display: flex;
			align-items: center;

			img {
				width: 5%;
				aspect-ratio: 1;
			}

			.content_title {

				margin-left: 2.2%;

				p {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 14px;
					color: #333333;
				}

				.p1 {
					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 20px;

					margin-bottom: 1.1%;
				}
			}
		}
	}

	.solu_qas {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.qas_wrap {
			margin-top: 65px;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 0 21px;

			.qa {
				border-radius: 10px;
				border: 1px solid #DFE1E6;

				padding: 5.5% 9.66%;

				display: flex;
				flex-direction: column;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 20px;
					color: #333333;

					margin-bottom: 5%;
				}

			}

			.qa:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>