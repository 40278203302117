<!-- 网络货运解决方案 -->
<template>
	<div class="wlhy_body">
		<div class="wlhy_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="modesSegs.length > 0" :ref="menuList[0]" class="wlhy_modes body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>多场景覆盖，满足不同运营模式</p>
				</div>
				<div class="modes_segmengt">
					<div v-for="(mode, index) in modesSegs" :key="index" class="mode_seg_item_wrap"
						@click="onModeSeg(mode)">
						<div class="mode_seg_item"
							:class="{'mode_seg_item_active': currentModeSeg.title === mode.title }">
							<p>{{ mode.title }}</p>
							<div class="seg_underline"
								:class="{'seg_underline_active': currentModeSeg.title === mode.title }"></div>
						</div>
					</div>
				</div>

				<div v-if="currentModeSeg" class="settle_modes_wrap">
					<div class="modes_img">
						<img v-lazy="__imagePath + currentModeSeg.imageurl" alt="" />
					</div>
					<div class="modes_detail_wrap">
						<p class="detail_title">{{ currentModeSeg.title }}</p>
						<div class="detail_ul">
							<ul>
								<li v-for="(detail, index) in currentModeSeg.details" :key="index">
									<div class="detail_li_wrap">
										<p>{{ detail.title }}</p>
										<p class="p_subtitle">{{ detail.subtitle }}</p>
										<!-- <ul>
											<li v-for="(subitem, cIndex) in detail.subtitles" :key="cIndex">
												<div class="detail_li_wrap2">
													<div class="detail_li_point"></div>
													<p>{{ subitem }}</p>
												</div>
											</li>
										</ul> -->
									</div>
								</li>
							</ul>
						</div>
						<div class="detail_button">
							<p>专家咨询</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="archImg" :ref="menuList[1]" class="wlhy_architecture body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="arch_wrap">
					<img v-lazy="archImg" alt="" />
				</div>
			</div>
		</div>

		<div v-if="coreValues.length > 0" :ref="menuList[2]" class="wlhy_values body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>核心价值</p>
				</div>

				<div class="values_wrap">

					<div class="value" v-for="(item, index) in coreValues" :key="index" @mouseenter="item.active = true"
						@mouseleave="item.active = false">
						<img class="bg_img" v-lazy="__imagePath + item.imageurl" alt="" />
						<div class="li_wrap">
							<div class="li_title">
								<p>{{ item.title }}</p>
								<div class="li_title_line" />
							</div>
							<div class="li_subtitle" :class="{ 'li_subtitle_active': item.active }">
								<p>{{ item.subtitle }}</p>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div v-if="advantages.length > 0" :ref="menuList[3]" class="wlhy_advantages body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>特色优势</p>
				</div>
			</div>

			<div class="advantages_wrap">
				<img class="bottom_bg_img" :src="advantagesBgImg" alt="" />
				<div class="advan_card_wrap">
					<div class="advan_header">
						<div v-for="(item, index) in advantages" :key="index" class="card_header"
							:class="{ 'no_border_left': index == 0, 'card_header_active': index == currentAdvantageIndex }"
							@click="onAdvanChange(item, index)">
							<p>{{ item.header }}</p>
						</div>
					</div>
					<div class="advan_content">
						<div class="content_wrap" v-for="(item, index) in currentAdvantage.subs">
							<div class="content">
								<img v-lazy="__imagePath + item.imgurl" alt="" />
								<p class="p_title">{{ item.title }}</p>
								<p class="p_tips">{{ item.tips }}</p>
							</div>
							<div v-if="index != 0" class="card_line"></div>
						</div>
					</div>
					
				</div>

			</div>
		</div>

		<div :ref="menuList[4]" class="wlhy_service_modes body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>服务模式</p>
				</div>

				<div class="modes_wrap" :style="{backgroundImage: 'url(' + modeBgImg + ')'}">
					<p>四大类服务模式</p>
					<div class="modes_imgs_wrap">
						<div class="modes_img" v-for="(mode, index) in modesImgs" :key="index"
							@mouseenter="mode.active=true" @mouseleave="mode.active=false">
							<img :src="mode.imgurl" alt="" />
						</div>
					</div>

				</div>
			</div>
		</div>

		<div v-if="cases.length > 0" :ref="menuList[5]" class="wlhy_cases body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>精选客户案例</p>
				</div>

				<solutionCasesView column="3" column-margin="30px" carousel-top="50px" :model="cases"
					:carousel-height="caseItemHeight + 'px'">
					<template v-slot="{ item }">
						<div class="case_wrap">
							<img :src="item.subImageUrl" alt="" />
							<div class="case_title">
								<p class="p1">{{ item.title  }}</p>
								<p>{{ item.subTitle }}</p>
							</div>
						</div>
					</template>
				</solutionCasesView>

			</div>
		</div>

		<div :ref="menuList[6]" class="wlhy_download body_suit item dray_background">
			<solutionDataView type="wlhy" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/wlhySolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {
				header: {
					title: '网络货运解决方案',
					subTitle: '首批无车承运试点公司精心打造，高效运营和满足监管完美融合',
				},
				menuList: ['应用场景', '架构图', '核心价值', '特色优势', '服务模式', '精选案例', '相关资料'],

				archImg: null,

				currentModeSeg: null,
				modesSegs: [],
				coreValues: [],

				advantagesBgImg: require("@/assets/yjysite/solution/wlhy_advan_bg.png"),
				advantages: [],
				currentAdvantage: null,
				currentAdvantageIndex: 0,

				modeBgImg: require("@/assets/yjysite/solution/wlhy_mode_bg.png"),
				modesImgs: [{
						imgurl: require("@/assets/yjysite/solution/wlhy_mode_card-1.png"),
						active: false,
					},
					{
						imgurl: require("@/assets/yjysite/solution/wlhy_mode_card-2.png"),
						active: false
					},
					{
						imgurl: require("@/assets/yjysite/solution/wlhy_mode_card-3.png"),
						active: false
					},
					{
						imgurl: require("@/assets/yjysite/solution/wlhy_mode_card-4.png"),
						active: false
					}
				],

				caseItemHeight: 374,
				cases: []

			};
		},

		created() {},

		mounted() {
			window.addEventListener("resize", this.windowChange, false);

			this.loadDatas()
		},

		destroyed() {
			window.removeEventListener("resize", this.windowChange, false);
		},

		methods: {
			windowChange() {
				const case_wrap = this.$refs[`${ '精选案例' }`]
				if (case_wrap) {
					this.$nextTick(() => {
						let rect = case_wrap.getBoundingClientRect();
						this.caseItemHeight = rect.width * 374 / 1920
					})
				}
			},

			handleScroll() {
				console.log('window.scrollY:' + window.scrollY)
			},
			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},

			onModeSeg(seg) {
				if (this.currentModeSeg.title === seg.title) return
				this.currentModeSeg = seg;
			},
			onAdvanChange(adv, index) {
				if (this.currentAdvantageIndex == index) return
				this.currentAdvantageIndex = index
				this.currentAdvantage = adv
			},

			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				console.log('page:' + JSON.stringify(page))
				let that = this

				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let archBgImg = page.architectureBg;
				if (archBgImg) {
					that.archImg = that.__imagePath + archBgImg
				}
				let modes = page.modes;
				if (modes && modes.length > 0) {
					that.modesSegs = modes
					that.currentModeSeg = modes[0]
				}
				let coreValues = page.coreValues;
				if (coreValues && coreValues.length > 0) {
					coreValues.forEach(item => {
						item.active = false
					})
					that.coreValues = coreValues
				}
				let advantages = page.advantages;
				if (advantages && advantages.length > 0) {
					that.advantages = advantages
					that.currentAdvantage = advantages[0]
				}
			},
			loadDatas() {
				this.handlePage(localPage);

				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 80
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}

						let cases = response.data.data.cases;
						if (cases && cases.length > 0) {
							that.$set(that, 'cases', cases)
						}
					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0 auto;
	}

	.el-carousel__item {

		display: flex;
		flex-direction: row;
	}

	.wlhy_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.wlhy_header {
		display: flex;
		flex-direction: column;
	}

	/* 模式展示 */
	.wlhy_modes {
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.modes_segmengt {
			padding-top: 50px;
			margin-bottom: 24px;
			height: 40px;

			display: flex;
			justify-content: space-between;

			.mode_seg_item_wrap {

				flex: 1;
				height: 100%;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.mode_seg_item {

					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 18px;
						color: #3D4461;
						text-align: center;
					}

				}

				.mode_seg_item_active {
					p {
						color: #0055FF;
					}
				}

				.seg_underline {
					width: 100%;
					height: 2px;
					margin-top: 14px;

					transition: all 0.3s;

					background-color: transparent;
				}

				.seg_underline_active {
					background-color: #0055FF !important;
				}
			}

			.mode_seg_item_wrap:hover {
				cursor: pointer;
			}


		}

		.settle_modes_wrap {
			background-color: #FFFFFF;
			padding: 55px 52px 90px 52px;
			padding: 3.4375rem 3.25rem 5.625rem 3.25rem;

			border-radius: 14px;

			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.modes_img {
				padding-top: 13px;
				padding-top: 0.8125rem;
				margin-right: 54px;
				margin-right: 3.375rem;

				width: 55.56%;
				aspect-ratio: 1.717;

				img {
					width: 100%;
					// height: 100%;
				}
			}

			.modes_detail_wrap {
				flex: 1;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;

				.detail_title {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 24px;
					color: #191919;
					line-height: 24px;
					text-align: left;
				}

				.detail_ul {
					flex: 1;
					width: 100%;


					margin-top: 50px;
					margin-top: 3.125rem;


					ul {
						flex: 1;
						height: 100%;

					}

					li {
						margin-bottom: 45px;
						margin-bottom: 2.8125rem;

						.detail_li_wrap {

							flex: 1;

							p {
								font-family: HanSans-Medium;
								font-weight: 500;
								font-size: 20px;
								color: #191919;
								text-align: left;
							}

							.p_subtitle {

								margin-top: 1.25%;

								font-weight: 400;
								font-size: 14px;
								color: #666666;
							}
						}
					}

				}


				.detail_button {

					background-color: rgba(255, 255, 255, 0.05);
					border-radius: 162px;
					border: 1px solid #64698B;
					padding: 12px 28px;
					padding: 0.75rem 1.75rem;

					display: flex;
					align-items: center;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #3D4461;
						line-height: 14px;
						text-align: center;
					}
				}

				.detail_button:hover {
					cursor: pointer;
					border: 1px solid rgba(100, 105, 139, 0.5);
				}
			}

		}
	}

	/* 架构图 */
	.wlhy_architecture {
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.arch_wrap {

			margin-top: 48px;
			margin-top: 3rem;

			img {
				width: 100%;
			}
		}
	}

	.wlhy_values {
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.values_wrap {

			margin-top: 65px;
			margin-top: 4.0625rem;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 26px 21px;

			.no_margintop {
				margin-top: 0;
			}

			.no_marginleft {
				margin-left: 0;
			}

			.value {
				width: 100%;
				aspect-ratio: 1.779;
				margin: 0 auto;
				scale: 1;

				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: center;

				display: flex;
				position: relative;

				.bg_img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;

					z-index: 0;

					transition: all 0.3s ease;
				}

				.li_wrap {
					padding: 10%;
					z-index: 21;

					display: flex;
					flex-direction: column;

					.li_title {
						margin-bottom: 6.4%;

						display: flex;
						flex-direction: column;

						.li_title_line {
							margin-top: 3%;

							width: 7%;
							height: 3px;

							background-color: #FFFFFF;
						}

						p {
							font-family: HanSans-Blod;
							font-weight: 700;
							font-size: 20px;
							color: #FFFFFF;
						}
					}

					.li_subtitle {

						opacity: 0;

						transition: all 0.3s ease;

						li {
							margin-top: 3.2%;
						}

						.li_zero {
							margin-top: 0;
						}

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #FFFFFF;
						}
					}

					.li_subtitle_active {
						opacity: 1;
					}
				}
			}

			.value:hover {
				scale: 1.03;
			}
		}

	}

	.wlhy_advantages {
		display: flex;
		flex-direction: column;

		aspect-ratio: 2.83;

		background-color: #FFFFFF;
		padding-top: 70px;
		padding-top: 4.375rem;

		.advantages_wrap {
			margin-top: 2.5%;

			flex: 1;
			display: flex;
			flex-direction: column;

			position: relative;
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center;

			.bottom_bg_img {

				position: absolute;

				bottom: 0;
				left: 0;
				width: 100%;
				height: auto;

				z-index: 0;
			}

			.advan_card_wrap {
				z-index: 21;
				display: flex;
				flex-direction: column;

				padding: 0 12.5%;
				
				.advan_header {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					grid-gap: 0;
					
					.card_header {
						height: 56px;
						background-color: #EDF9FD;
					
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
					
						border-left: 1px solid #CDE0EE;
					
						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 20px;
							color: #5B627E;
						}
					}
					
					.no_border_left {
						border: none;
					}
					
					.card_header_active {
						background-color: #0055FF !important;
					
						p {
							color: #FFFFFF;
						}
					}
				}

				.advan_card {
					
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					grid-gap: 0;

					.card_header {
						height: 56px;
						background-color: #EDF9FD;

						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						border-left: 1px solid #CDE0EE;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 20px;
							color: #5B627E;
						}
					}

					.no_border_left {
						border: none;
					}

					.card_header_active {
						background-color: #0055FF !important;

						p {
							color: #FFFFFF;
						}
					}

				}
				
				.advan_content {
					
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					min-height: 17.1875rem;
					
					background-color: #FFFFFF;
					
					.content_wrap {
						flex-basis: 25%;
						
						position: relative;
						
						.content {
							display: flex;
							flex-direction: column;
							align-items: center;
												
							img {
								width: 30px;
								height: 30px;
								object-fit: contain;
							}
												
							// .content_title {
							.p_tips {
								margin-top: 0.5rem;
								
								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								font-size: 16px;
								color: #5B627E;
								text-align: center;
								line-height: 1.5rem;
								
								white-space: pre-wrap;
							}
							
							.p_title {
								margin-top: 20px;
								
								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								font-size: 18px;
								color: #353C58;
								text-align: center;
							}
						}
						.card_line {
						
							position: absolute;
							left: 0;
							top: 20%;
							width: 1px;
						
							height: 60%;
							// width: 2px;
							background-color: #CDE0EE;
						}
						
					
					// 	ul {
					// 		// margin-top: 8px;
					// 		display: flex;
					// 		flex-direction: column;
					
					// 		li {
					// 			height: 30px;
					
					// 			p {
					// 				font-family: Source Han Sans, Source Han Sans;
					// 				font-weight: 400;
					// 				font-size: 16px;
					// 				color: #5B627E;
					// 			}
					// 		}
					// 	}
					}
				}
			}


		}


	}

	.wlhy_service_modes {
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.modes_wrap {
			margin-top: 50px;
			margin-top: 3.125rem;
			aspect-ratio: 3.186;
			padding: 0 50px;
			padding: 0 3.125rem;

			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center;


			p {
				margin-top: 67px;
				margin-top: 4.1875rem;

				font-family: HanSans-Blod;
				font-weight: 700;
				font-size: 30px;
				color: #3D446E;
			}

			.modes_imgs_wrap {
				flex: 1;
				width: 65%;
				margin-top: 50px;
				margin-top: 3.125rem;

				display: flex;
				flex-direction: row;

				.modes_img {
					flex: 1;
					aspect-ratio: 0.85;

					margin-right: 28px;
					margin-right: 1.75rem;

					img {
						width: 100%;
					}

					img:hover {
						box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
					}

				}
			}
		}
	}

	.wlhy_cases {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
		background-color: #FFFFFF;

		.case_wrap {

			height: 100%;

			display: flex;
			flex-direction: column;

			img {
				width: 100%;
				aspect-ratio: 2.45;
				object-fit: contain;
				background-color: rgba(0, 0, 0, 0.1);
			}

			.case_title {

				padding: 4.2%;
				padding-bottom: 0;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #788296;

					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 18px;
					color: #353C58;

					-webkit-line-clamp: 1;

					margin-bottom: 4%;
				}
			}
		}
	}

	.wlhy_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>