<!-- 数字水运解决方案 -->
<template>
	<div class="solution_body">
		<div class="szsy_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="sceneInfo" class="szsy_scene body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>应用场景</p>
				</div>
				<div class="scene_wrap">
					<div class="modes_img">
						<img v-lazy="__imagePath + sceneInfo.imgUrl" alt="" />
					</div>
					<div class="modes_detail_wrap">
						<p class="detail_title">{{ sceneInfo.title }}</p>
						<ul>
							<li v-for="(detail, index) in sceneInfo.details" :key="index">
								<div class="detail_li_wrap">
									<p class="p1">{{ detail.title }}</p>
									<p>{{ detail.subtitle }}</p>
								</div>
							</li>
						</ul>
						<div class="detail_button">
							<p>专家咨询</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="archBgImg" :ref="menuList[1]" class="szsy_architecture body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="arch_wrap">
					<img v-lazy="archBgImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="plans.length > 0" :ref="menuList[2]" class="szsy_plans body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案价值</p>
				</div>
				<div class="plans_wrap">
					<div class="plan_wrap" v-for="(plan, index) in plans" :key="index">
						<p class="p1">{{ plan.title }}</p>
						<p>{{ plan.subTitle }}</p>
					</div>
				</div>
			</div>
		</div>
		<div v-if="serviceModes.length > 0" :ref="menuList[3]" class="szsy_service_mode body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>服务模式</p>
				</div>
				<ul>
					<li v-for="(mode, index) in serviceModes" :key="index">
						<div class="mode_wrap">
							<img v-lazy="__imagePath + mode.imageurl" alt="" />
							<div class="mode_title">
								<p class="p1">{{ mode.title }}</p>
								<p>{{ mode.subtitle }}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div v-if="cases.length > 0" :ref="menuList[4]" class="szsy_cases body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>精选客户案例</p>
				</div>
				<solutionCasesView column="3" column-margin="30px" carousel-top="50px" :model="cases"
					:carousel-height="caseItemHeight + 'px'">
					<template v-slot="{ item }">
						<div class="case_wrap">
							<img v-lazy="item.subImageUrl" alt="" />
							<div class="case_title">
								<p class="p1">{{ item.title  }}</p>
								<p>{{ item.subTitle }}</p>
							</div>
						</div>

					</template>
				</solutionCasesView>
			</div>
		</div>

		<div :ref="menuList[5]" class="szsy_download body_suit item dray_background">
			<solutionDataView type="szsy" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
		import localPage from "../json/szsySolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '数字水运解决方案',
					subTitle: '助力数字化升级，链接更多资源'
				},
				menuList: ['应用场景', '架构图', '方案价值', '服务模式', '客户案例', '相关资料'],

				sceneInfo: null,
				archBgImg: null,
				plans: [],
				serviceModes: [],

				caseItemHeight: 374,
				cases: []

			};
		},

		created() {
		},
		mounted() {
			window.addEventListener("resize", this.windowChange, false);
			
			this.loadDatas()
		},

		destroyed() {
			window.removeEventListener("resize", this.windowChange, false);
		},

		methods: {
			windowChange() {
				const case_wrap = this.$refs[`${ '客户案例' }`]
				if (case_wrap) {
					this.$nextTick(() => {
						let rect = case_wrap.getBoundingClientRect();
						this.caseItemHeight = rect.width * 374 / 1920
					})
				}
			},

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},
			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
			
				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}
				
				let sceneInfo = page.sceneInfo
				if (sceneInfo) {
					that.sceneInfo = sceneInfo
				}
			
				let archBgImg = page.architectureBg;
				if (archBgImg) {
					that.archBgImg = this.__imagePath + archBgImg
				}
				
				let plans = page.plans;
				if (plans && plans.length > 0) {
					that.plans = plans
				}
				let serviceModes = page.serviceModes;
				if (serviceModes && serviceModes.length > 0) {
					that.serviceModes = serviceModes
				}
			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 81
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
			
						let cases = response.data.data.cases;
						if (cases && cases.length > 0) {
							that.$set(that, 'cases', cases)
						}
					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.szsy_header {
		display: flex;
		flex-direction: column;
	}

	/* 模块功能 */
	.szsy_scene {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.scene_wrap {
			background-color: #FFFFFF;
			margin-top: 50px;
			padding: 55px 50px 90px 50px;
			padding: 3.4375rem 3.125rem 5.625rem 3.125rem;

			border-radius: 14px;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.modes_img {
				margin-right: 55px;
				margin-right: 3.4375rem;

				width: 55.2%;
				aspect-ratio: 2.044;

				img {
					width: 100%;
				}
			}

			.modes_detail_wrap {
				flex: 1;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;

				.detail_title {

					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 24px;
					color: #191919;
				}

				ul {
					flex: 1;
					width: 100%;

					margin-top: 50px;
					margin-top: 3.125rem;
				}

				li {
					margin-bottom: 50px;
					margin-bottom: 3.125rem;

					.detail_li_wrap {

						flex: 1;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #666666;
						}

						.p1 {
							margin-bottom: 13px;

							font-family: HanSans-Medium;
							font-weight: 500;
							font-size: 20px;
							color: #191919;
						}
					}
				}

				.detail_button {

					background-color: rgba(255, 255, 255, 0.05);
					border-radius: 162px;
					border: 1px solid #64698B;
					padding: 12px 28px;
					padding: 0.75rem 1.75rem;

					display: flex;
					align-items: center;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #3D4461;
						line-height: 14px;
						text-align: center;
					}
				}

				.detail_button:hover {
					cursor: pointer;
					border: 1px solid rgba(100, 105, 139, 0.5);
				}
			}

		}
	}

	/* 架构图 */
	.szsy_architecture {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.arch_wrap {

			margin-top: 50px;
			margin-top: 3.125rem;

			img {
				width: 100%;
			}
		}
	}

	/* 方案优势 */
	.szsy_plans {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.plans_wrap {
			margin-top: 48px;
			margin-top: 3rem;

			flex: 1;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 35px 25px;

			.plan_wrap {
				background-color: #FFFFFF;
				border-radius: 10px;

				padding: 20px 50px;

				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 20px;
					color: #333333;

					margin-bottom: 10px;
				}
			}

			.plan_wrap:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 服务模式 */
	.szsy_service_mode {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		ul {
			margin-top: 60px;
			margin-top: 3.75rem;
		}

		li {
			width: 100%;

			display: flex;
		}

		.mode_wrap {

			flex: 1;
			
			padding: 1.6% 2.2%;
			border-radius: 4px;
			border: 1px solid #DFE1E6;

			display: flex;
			align-items: center;

			img {
				width: 5%;
				aspect-ratio: 1;
			}
			
			.mode_title {
				
				margin-left: 2.2%;
				
				p {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 14px;
					color: #333333;
				}
				.p1 {
					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 20px;
					
					margin-bottom: 1.1%;
				}
			}
		}
	}

	.szsy_cases {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
		background-color: #FFFFFF;

		.case_wrap {
			
			height: 100%;
			
			display: flex;
			flex-direction: column;

			img {
				width: 100%;
				aspect-ratio: 2.45;
				object-fit: contain;
				background-color: rgba(0, 0, 0, 0.1);
			}

			.case_title {
				padding: 6.5%;
				padding-bottom: 0;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #788296;
					
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 18px;
					color: #353C58;
					
					-webkit-line-clamp: 1;

					margin-bottom: 4%;
				}
			}

		}

	}

	.szsy_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>