<!-- 人力Saas -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="scene" class="solu_scene body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>应用场景</p>
				</div>
				<div class="scene_wrap">
					<div class="scene_title">
						<p class="p1">{{ scene.title }}</p>
						<p>{{ scene.subTitle }}</p>
					</div>
					<div class="scene_img">
						<img v-lazy="__imagePath + scene.imgurl" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="architectureImg" :ref="menuList[1]" class="solu_architecture body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="architecture_wrap">
					<img v-lazy="architectureImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="outsourcing" :ref="menuList[2]" class="solu_outsourcing body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>外包交易</p>
					<p class="p_sub">低成本合规、提供用工弹性、增加进项</p>
				</div>
				<div class="outsourcing_wrap">
					<ul class="outsourcing">
						<li v-for="(item, index) in outsourcing.titles" :key="index" :class="{'li_zero': index == 0}">
							<div class="li_title">
								<img v-lazy="__imagePath + item.imgurl" alt="" />
								<p>{{ item.title }}</p>
							</div>
							<div class="li_subTitle">
								<p>{{ item.subTitle }}</p>
							</div>
						</li>
					</ul>
					<div class="wrap_img">
						<img v-lazy="__imagePath + outsourcing.imgurl" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="lhygImg" :ref="menuList[3]" class="solu_lhyg body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>灵活用工</p>
					<p class="p_sub">在线派单、在线结算、自由职业者认定</p>
				</div>
				<div class="lhyg_wrap">
					<img v-lazy="lhygImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="settlein" :ref="menuList[4]" class="solu_settlein body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>服务商入驻</p>
					<p class="p_sub">承接平台各类人力需求</p>
				</div>
				<div class="settlein_wrap">
					<div class="wrap_img">
						<img v-lazy="__imagePath + settlein.imgurl" alt="" />
					</div>
					<div class="settlein">
						<div class="settlein_li" v-for="(item, index) in settlein.titles" :key="index">
							<div class="li_title">
								<img v-lazy="__imagePath + item.imgurl" alt="" />
								<p>{{ item.title }}</p>
								<div class="line" />
							</div>
							<div class="li_subTitle">
								<p>{{ item.subTitle }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="plans.length > 0" :ref="menuList[5]" class="solu_plans body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案价值</p>
				</div>
				<div class="plans_wrap">
					<div class="plan_wrap" v-for="(plan, index) in plans" :key="index">
						<p class="p1">{{ plan.title }}</p>
						<p>{{ plan.subTitle }}</p>
					</div>
				</div>
			</div>
		</div>

		<div :ref="menuList[6]" class="solu_download body_suit item dray_background">
			<solutionDataView type="hrsaas" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/saashrSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '工众人力SaaS',
					subTitle: '连接广泛资源，一站式灵活用工解决方案'
				},
				menuList: ['应用场景', '架构图', '外包交易', '灵活用工', '服务商入驻', '方案价值', '相关资料'],
				architectureImg: null,
				lhygImg: null,

				scene: null,
				outsourcing: null,
				settlein: null,
				plans: []
			};
		},

		created() {},
		mounted() {
			this.loadDatas()
		},

		methods: {

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},

			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let lhygImg = page.lhygImg;
				if (lhygImg) {
					that.lhygImg = that.__imagePath + lhygImg
				}
				let archBgImg = page.architectureImg;
				if (archBgImg) {
					that.architectureImg = that.__imagePath + archBgImg
				}
				let scene = page.scene;
				if (scene) {
					that.scene = scene
				}
				let outsourcing = page.outsourcing;
				if (outsourcing) {
					that.outsourcing = outsourcing
				}
				let settlein = page.settlein;
				if (settlein) {
					that.settlein = settlein
				}
				let plans = page.plans;
				if (plans && plans.length > 0) {
					that.plans = plans
				}

			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 88
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}

					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}

		.p_sub {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 应用场景 */
	.solu_scene {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.scene_wrap {

			margin-top: 80px;
			padding: 0 25px;

			display: flex;
			flex-direction: row;
			align-items: center;

			.scene_title {
				flex: 1;
				margin-right: 8.89%;

				display: flex;
				flex-direction: column;

				p {

					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 18px;
					color: #666666;

				}

				.p1 {
					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 20px;
					color: #333333;

					margin-bottom: 1.4%;
				}
			}

			.scene_img {
				width: 51.2%;

				img {
					width: 100%;
				}
			}
		}
	}

	/* 架构图 */
	.solu_architecture {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.architecture_wrap {

			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 外包交易 */
	.solu_outsourcing {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.outsourcing_wrap {
			margin-top: 40px;

			display: flex;
			flex-direction: row;
			// align-items: center;

			.outsourcing {
				flex-basis: 40%;
				margin-right: 10%;

				li {
					margin-top: 55px;
					padding-left: 16px;

					display: flex;
					flex-direction: column;

					.li_title {
						display: flex;
						flex-direction: row;
						align-items: center;

						img {
							width: 38px;
							height: 38px;

							margin-right: 20px;
						}

						p {
							font-family: HanSans-Medium;
							font-weight: 500;
							font-size: 24px;
							color: #333333;
						}
					}

					.li_subTitle {

						padding-left: 58px;
						margin-top: 4px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 16px;
							color: #666666;
						}
					}
				}

				.li_zero {
					margin-top: 0;
				}
			}

			.wrap_img {
				flex-basis: 50%;

				display: flex;
				flex-direction: row;
				justify-content: center;

				img {
					width: 76%;
				}
			}

		}

	}

	/* 灵活用工 */
	.solu_lhyg {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.lhyg_wrap {

			margin-top: 40px;

			img {
				width: 100%;
			}
		}
	}

	/* 服务商入驻 */
	.solu_settlein {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.settlein_wrap {
			margin-top: 40px;

			display: flex;
			flex-direction: row;
			// align-items: center;

			.wrap_img {
				flex-basis: 53%;

				display: flex;
				flex-direction: row;
				justify-content: center;

				img {
					width: 100%;
				}
			}

			.settlein {
				flex: 1;
				margin-left: 2%;

				padding: 2.78% 5.5%;

				background-color: #FFFFFF;
				border-radius: 8px;

				display: grid;
				flex-direction: column;
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 2.78% 0;

				.settlein_li {

					display: flex;
					flex-direction: column;

					.li_title {

						// padding: 20px 0;
						padding: 4% 0;
						display: flex;
						flex-direction: row;
						align-items: flex-end;
						position: relative;

						img {
							position: relative;
							top: -5px;
							width: 23px;
							height: 23px;

							margin-right: 10px;
						}

						p {
							font-family: HanSans-Medium;
							font-weight: 500;
							font-size: 18px;
							color: #333333;
						}

						.line {
							position: absolute;
							left: 0;
							bottom: 0;

							background-color: #D8D8D8;
							height: 1px;
							width: 60%;
						}
					}

					.li_subTitle {

						margin-top: 10px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 16px;
							color: #666666;
						}
					}
				}
			}
		}
	}

	/* 方案价值 */
	.solu_plans {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.plans_wrap {
			margin-top: 65px;

			flex: 1;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 38px 28px;

			.plan_wrap {
				border-radius: 10px;
				border: 1px solid #DFE1E6;

				padding: 25px 50px;

				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 20px;
					color: #333333;

					margin-bottom: 10px;
				}
			}

			.plan_wrap:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>