<template>
	<div v-show="ready" class="el-carousel__item" :class="{
      'is-active': active,
      'el-carousel__item--card': $parent.type === 'card',
      'is-in-stage': inStage,
      'is-hover': hover,
      'is-animating': animating
    }" @click="handleItemClick" :style="itemStyle">
	
		<slot></slot>
	</div>
</template>

<script>

	const CARD_SCALE = 0.9;
	const CARD_SCALE2 = 0.81;

	export default {
		name: 'ElCarouselItem',
		data() {
			return {
				hover: false,
				translate: 0,
				scale: 1,
				active: false,
				ready: false,
				inStage: false,
				animating: false,
				opacity: 1.0
			};
		},

		methods: {
			processIndex(index, activeIndex, length) {
				if ((activeIndex === 0 || activeIndex === 1) && index === length - 1) {
					return -1;
				} else if ((activeIndex === 0 || activeIndex === 1) && index === length - 2) {
					return -2;
				} else if ((activeIndex === length - 1 || activeIndex === length - 2) && index === 0) {
					return length;
				} else if ((activeIndex === length - 1 || activeIndex === length - 2) && index === 1) {
					return length + 1;
				}
				return index;
			},

			calcCardTranslate(index, activeIndex) {
				const parentWidth = this.$parent.$el.offsetWidth;

				let translate = 0.0,
					stanTran = parentWidth / 5
				if (this.inStage) {
					translate = parentWidth * (index - activeIndex + 2) / 5;
					let abs = Math.abs(activeIndex - index)
					if (abs == 2) {
						if (activeIndex > index) {
							// 左边
							translate += (stanTran * (1 - CARD_SCALE))
						} else {
							// 右边
							translate -= (stanTran * (1 - CARD_SCALE))
						}
					}
				} else {
					if (index < activeIndex) {
						translate = 0 * parentWidth / 5;
					} else {
						translate = 4 * parentWidth / 5;
					}
				}
				return translate
			},

			calcTranslate(index, activeIndex, isVertical) {
				const distance = this.$parent.$el[isVertical ? 'offsetHeight' : 'offsetWidth'];
				return distance * (index - activeIndex);
			},

			translateItem(index, activeIndex, oldIndex) {
				const parentType = this.$parent.type;
				const parentDirection = this.parentDirection;
				const length = this.$parent.items.length;
				if (parentType !== 'card' && oldIndex !== undefined) {
					this.animating = index === activeIndex || index === oldIndex;
				}
				index = this.processIndex(index, activeIndex, length);
				if (parentType === 'card') {
					if (parentDirection === 'vertical') {
						console.warn('[Element Warn][Carousel]vertical direction is not supported in card mode');
					}
					// activeIndex-2 activeIndex-1 activeIndex activeIndex+1 activeIndex+2

					let abs = Math.abs(activeIndex - index)

					this.inStage = Math.round(abs) <= 2;
					this.active = index === activeIndex;
					this.translate = this.calcCardTranslate(index, activeIndex);
					this.opacity = abs > 2 ? 0.0 : 1.0;
					this.scale = this.active ? 1 : ((abs == 1) ? CARD_SCALE : CARD_SCALE2);

				} else {
					this.active = index === activeIndex;
					const isVertical = parentDirection === 'vertical';
					this.translate = this.calcTranslate(index, activeIndex, isVertical);
					this.scale = 1;
				}
				this.ready = true;
			},

			handleItemClick() {
				const parent = this.$parent;
				if (parent && parent.type === 'card') {
					const index = parent.items.indexOf(this);
					parent.setActiveItem(index);
				}
			},

			autoprefixer: function(style) {
				if (typeof style !== 'object') return style;
				const rules = ['transform', 'transition', 'animation'];
				const prefixes = ['ms-', 'webkit-'];
				rules.forEach(rule => {
					const value = style[rule];
					if (rule && value) {
						prefixes.forEach(prefix => {
							style[prefix + rule] = value;
						});
					}
				});
				return style;
			}
		},

		computed: {
			parentDirection() {
				return this.$parent.direction;
			},

			itemStyle() {
				const translateType = this.parentDirection === 'vertical' ? 'translateY' : 'translateX';
				const value = `${translateType}(${ this.translate }px) scale(${ this.scale })`;
				const style = {
					transform: value,
					opacity: this.opacity
				};
				return this.autoprefixer(style);
			}
		},

		created() {
			this.$parent && this.$parent.updateItems();
		},

		destroyed() {
			this.$parent && this.$parent.updateItems();
		}
	};
</script>