<template>
	<div class="yjy_body_index">
		<div class="banner body_suit item">
			<el-carousel ref="bannerCarousel" :autoplay="false" :interval="comBannerTime" :height="homeBnnerH + 'px'"
				@change="bannerChange">
				<el-carousel-item v-for="(item, index) in bannerList" :key="index">

					<div v-if="bannerIndex == index" class="banner_carousel_item">
						<custom-video-player v-if="item.showVideo==true" class="banner_player"
							:forbidClick="item.showText" :autoplay="item.showText" :controls="!item.showText"
							:showPlay="!item.showText" :loop="true" width="100%" height="100%"
							:src="__ftpPath + item.videoUrl" :poster="__imagePath + item.imageUrl" />
						<img v-else v-lazy=" __imagePath + item.imageUrl" />

						<div v-if="item.showText==true" class="banner_carousel_item_body">
							<div class="banner_carousel_item_title" v-if="item.span">全链的物流和供应链<span
									style="color:#F3A213">{{ item.span }}</span>服务商</div>
							<div class="banner_carousel_item_title" v-else>{{ item.title }}</div>
							<div class="banner_carousel_item_subTitle">{{ item.subTitle }}</div>
							<div class="banner_carousel_item_know" @click="onClickMore(1)" v-if="item.todetail==true">了解详情
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>

		<div v-if="aboutUs" class="aboutUs body_suit item dray_background">

			<div class="com_item_title_wrap item_wrap">
				<div class="com_item_title">关于我们</div>
				<div class="com_item_subtitle" @click="onClickMore(1)">
					<p :class="{ 'moreTagHover': (headerHoverIndex == 1) }" @mouseenter="headerHoverIndex = 1"
						@mouseleave="headerHoverIndex = 0">了解更多</p>
					<img :src="moreImg" alt="" />
				</div>
			</div>

			<div v-if="aboutUs.president" class="aboutUs_body">
				<div class="body_content body_width_suit">
					<div class="aboutUs_body_title">
						<div class="aboutUs_body_title_line"></div>
						<p>董事长介绍</p>
					</div>

					<div class="aboutUs_body_wrap">
						<div class="aboutUs_body_wrap_left">
							<img v-lazy="__imagePath + aboutUs.president.imageUrl" alt="董事长" />
							<div class="wrap_left_name"><span
									class="president_name">{{ aboutUs.president.name }}</span>{{ aboutUs.president.position }}
							</div>
							<ul>
								<li v-for="(sub, index) in aboutUs.president.subs" :key="index">
									<p>{{ sub }}</p>
								</li>
							</ul>
						</div>

						<div class="aboutUs_body_wrap_right">
							<div class="wrap_right_honor_line"></div>
							<ul>
								<li v-for="(item, index) in aboutUs.president.titles" :key="index">
									<div class="wrap_right_honor_item" @mouseenter="honorMouseenter(index)"
										@mouseleave="isHonorHover=false">
										<div class="wrap_right_honor_item_img">
											<img v-if="isHonorHover && honorHoverIndex == index"
												:src="honorIconHoverImg" alt="" />
											<img v-else :src="honorIconImg" alt="" />
										</div>
										<p>{{ item }}</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div v-if="aboutUs.company" class="aboutUs_body aboutUs_company">
				<div class="body_content padding_top body_width_suit">
					<div class="aboutUs_body_title">
						<div class="aboutUs_body_title_line"></div>
						<p>公司简介</p>
					</div>

					<div class="aboutUs_company_wrap">
						<div class="aboutUs_company_intro_text" v-for="(title, index) in aboutUs.company.titles"
							:key="index">
							<p :class="{'dark_text': index != 0}">{{ title }}</p>
						</div>
						<div class="aboutUs_company_box_wrap">
							<div class="aboutUs_company_box" v-for="(item, index) in aboutUs.company.specs"
								:key="index">
								<div class="box_wrap"
									:style="'background-image: url(' + __imagePath + item.imageUrl + ')'">
									<p>{{ item.title }}</p>
									<p class="big_text">{{ item.subTitle }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div v-if="aboutUs.reasons" class="aboutUs_advantage item_wrap">

				<div class="aboutUs_body_title">
					<div class="aboutUs_body_title_line"></div>
					<p>为什么选择我们</p>
				</div>

				<div class="aboutUs_advantage_wrap">
					<div class="advantage_option_wrap">
						<div v-for="(item, index) in aboutUs.reasons" :key="index" @click="onTouchOption(item, index)"
							class="advantage_option"
							:class="advantageOptionIndex == index ? 'advantage_option_active': 'advantage_option'"
							:style="{ marginTop: (index == 0 ? '0':'30px')}">

							<p :class="{'advantage_option_p_active': advantageOptionIndex == index}">
								{{ '0' + (index + 1) + ' | ' + item.key }}
							</p>
						</div>
					</div>

					<div class="advantage_content_wrap">
						<div class="advantage_content">
							<div class="advantage_content_title">{{ advantage.title }}</div>
							<div class="advantage_content_ul">
								<ul>
									<li v-for="(item, index) in advantage.subs" :key="index">
										<div class="advantage_li_item">
											<img :src="advantageItemImg" alt="" />
											<p>{{ item }}</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div v-if="solutions.length > 0" class="solution body_suit item">
			<div class="com_item_title_wrap item_wrap">
				<div class="com_item_title">丰富、专业、成熟的数字化解决方案</div>
				<div class="solution_header_subtitle">
					<div class="header_subtitle">专业的方案沉淀，助力企业实现数字化转型升级</div>
					<div class="com_item_subtitle" @click="onClickMore(2)">
						<p :class="{ 'moreTagHover': (headerHoverIndex == 2) }" @mouseenter="headerHoverIndex = 2"
							@mouseleave="headerHoverIndex = 0">全部解决方案</p>
						<img :src="moreImg" alt="" />
					</div>
				</div>
			</div>
			<!-- 解决方案 -->
			<div class="solution_body">
				<div class="solution_segmengt body_width_suit">
					<div class="solution_seg_item_wrap" v-for="(item, index) in solutions" :key="index"
						@click="onSolutionTab(item)">
						<div class="solution_seg_item"
							:class="{'solution_seg_item_active': item.key === solutionItems.key }">
							{{ item.key }}
						</div>
						<div class="seg_underline" :class="{'seg_underline_active': item.key === solutionItems.key }">
						</div>
					</div>
				</div>

				<div class="solution_body_wrap">
					<div class="solution_body_content_wrap body_width_suit">
						<!-- 左边菜单 -->
						<div class="solution_body_menu_wrap">
							<div class="menu_title">{{ solutionItems.key + '解决方案' }}</div>
							<ul>
								<li v-for="(item, index) in solutionItems.solutions" :key="index"
									:class="{'li_zero': index == 0}">
									<div class="menu_li_wrap" :class="{'menu_li_wrap_active': solutionIndex == index}"
										:key="index" @click="onSolutionMenu(item, index)">
										<div class="menu_li_line"
											:class="{'menu_li_line_active': solutionIndex == index}"></div>
										<p>{{ item.title }}</p>
									</div>
								</li>
							</ul>
						</div>

						<!-- 右边内容 -->
						<div class="solution_body_content">
							<div class="solution_content">
								<div class="solution_content_title">{{ solution.title }}</div>
								<div class="solution_content_subtitle">{{ solution.subTitle }}</div>
								<div v-if="solution.specs && solution.specs.length > 0" class="solution_boxes">
									<div class="solution_box" v-for="(spec, index) in solution.specs" :key="index">
										<img :src="solutionItemImg" alt="" />
										<p>{{ spec }}</p>
									</div>
								</div>

								<div v-if="solution.specs && solution.specs.length > 0"
									class="solution_content_buttons">
									<div class="solution_button" @click="onSolutionConsultation(solution)">在线咨询</div>
									<div class="solution_button solution_button2" @click="onSolutionDetail(solution)">
										方案详情
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>

		</div>

		<div class="cases body_suit item">
			<!-- 客户案例  -->
			<div v-if="classCases.length > 0" class="cases_body"
				:style="'background-image: url(' + classCasesBgImg + ')'">
				<div class="cases_body_wrap item_wrap">
					<div class="cases_header_title">客户的认可和选择</div>

					<div class="cases_tabs_wrap">
						<div class="cases_tab" v-for="(item, index) in classCases" :key="index"
							@click="onCaseTab(item, index)">
							<div class="cases_tab_text"
								:class="{'cases_tab_text_active': currentCase && currentCase.id === item.id }">
								{{ item.caseType.name }}
							</div>
							<div class="cases_tab_line"
								:class="{'cases_tab_line_active': currentCase && currentCase.id === item.id }"></div>
						</div>
					</div>

					<div v-if="currentCase" class="cases_content_wrap">
						<div class="cases_content">
							<!-- 公司logo  -->
							<div class="img_wrap">
								<img v-lazy="currentCase.imageUrl" @load="getImgSize" alt="" :style="currCaseImgStyle" />
							</div>
							<!-- 公司名称 -->
							<div class="cases_content_title">{{ currentCase.title }}</div>
							<!-- 公司简介 -->
							<div class="cases_content_subtitle">{{ currentCase.subTitle }}</div>
						</div>
					</div>
					<div v-if="currentCase" class="com_item_subtitle cases_content_bottom_wrap" @click="onClickMore(3)">
						<p :class="{ 'moreTagHover': (headerHoverIndex == 3) }" @mouseenter="headerHoverIndex = 3"
							@mouseleave="headerHoverIndex = 0">查看更多案例</p>
						<img :src="moreWhiteImg" alt="" />
					</div>
				</div>
			</div>

			<!-- 客户案例滚动 -->
			<div v-if="scrollCases.length > 0" class="solution_cases_scroll dray_background">
				<div class="solution_cases_scroll_body" :style="{height: (scrollCases.length * 88 + 16) + 'px'}">

					<div class="solution_cases_scroll_item" v-for="(cases, index) in scrollCases" :key="index"
						:style="{marginTop: index == 0 ? '0':'16px'}">
						<vue-seamless-scroll :data="cases" :class-option="defaultOption" class="cases_scroll_wrap">
							<ul>
								<li v-for="(item, cindex) in cases" :key="cindex">
									<div class="cases_scroll_li_wrap">
										<img v-lazy="item.imageUrl" />
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>

				</div>

			</div>
		</div>

		<div v-if="technologyShows.length > 0" class="technology body_suit item" ref="qyjs">
			<div class="com_item_title_wrap item_wrap">
				<div class="com_item_title">专业、前沿的技术支撑</div>
			</div>

			<div class="item_wrap">
				<div ref="technologyBody" class="technology_body" :style="technologyStyle">
					<div class="technology_item_wrap" v-for="(tech, index) in technologyShows" :key="index" @mouseenter="onTechnologyMouseenter(tech)" @mouseleave="onTechnologyMouseleave(tech)">

						<div class="technology_item" :style="technologyItemStyle(tech)">
							<div class="technology_item_line"
								:class="{ 'technology_item_line_hidden': tech.state == 1 }" />
							<div class="technology_item_content">
								<img :src="__imagePath + tech.iconUrl" alt="" />
								<div class="technology_item_content_title" :class="technologyItemTitleClass(tech)">
									{{ tech.title }}
								</div>

								<div v-show="tech.state == 1" class="technology_item_content_subtitle">
									{{ tech.subTitle }}
								</div>
							</div>
							<img :src=" tech.state == 1 ? technologyShowIconSelectedImg:technologyShowIconImg " />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="honorList.length > 0" class="honor body_suit item dray_background" ref="ryzz">
			<div class="com_item_title_wrap body_width_suit">
				<div class="com_item_title">荣誉资质</div>
			</div>

			<div class="honor_body body_width_suit">
				<!-- tabs -->
				<div class="honor_tabs_wrap">
					<div class="honor_tab" v-for="(honor, index) in honorList" :key="index" @click="onHonorTab(honor)">
						<p :class="{ 'honor_tab_title_active': honor.active == true }">{{ honor.title }}</p>
						<div class="honor_tab_line" :class="{ 'honor_tab_line_active': honor.active == true }" />
					</div>
				</div>
				<!-- 6 x n 宫格 -->
				<div class="honor_box_wrap">
					<div class="honor_box" v-for="(honor, index) in currentHonors" :key="index">
						<img v-lazy="honor.imageUrl" alt="" />
						<div class="box_title">{{ honor.title }}</div>
					</div>
				</div>
			</div>

		</div>

		<div v-if="newsMessages.length > 0" class="news body_suit item">
			<div class="com_item_title_wrap body_width_suit">
				<div class="com_item_title">最新动态</div>
			</div>

			<div class="news_body body_width_suit">
				<!-- 左 新闻资讯 -->
				<div class="news_left_wrap" @click="onNewsTap(newsMessages[newsMessageIndex])">
					<img class="news_left_coverImg" :src="newsMessages[newsMessageIndex].coverImg" alt="" />

					<div class="news_left_header">
						<img :src=" newsMessages[newsMessageIndex].type == 1 ? newsTagMessageImg:newsTagNewsImg"
							alt="" />
						<p>{{ newsMessages[newsMessageIndex].title }}</p>
					</div>
					<div class="news_left_content">{{ newsMessages[newsMessageIndex].intro }}</div>
					<div class="news_left_bottom">
						<p>{{ newsMessages[newsMessageIndex].lastUpdateTime }}</p>
						<div v-if="newsMessages.length > 1" class="news_left_bottom_toggle">
							<img class="toggle_img_left" @click.stop="onMessageChange(1)" />
							<img class="toggle_img_right" @click.stop="onMessageChange(2)" />
						</div>
					</div>
				</div>

				<!-- 右 动态+视频 -->
				<div class="news_right_wrap">
					<div class="news_right_top_wrap news_right_item">
						<div class="news_right_top_header">
							<div class="top_header_title">公司动态</div>
							<div class="top_header_more" @click="onNewsMore">查看更多</div>
						</div>
						<ul>
							<li v-for="(news, index) in newsCompanys" :key="index"
								:style="{ marginTop: index == 0 ? '0':'40px'}">

								<div class="news_right_top_item" @click="onNewsTap(news)">
									<img v-lazy="news.coverImg" alt="" />
									<div class="top_item_wrap">
										<p>{{ news.title }}</p>
										<div class="top_item_time">{{ news.lastUpdateTime }}</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="news_right_video_wrap news_right_item">
						<div class="news_right_top_header">
							<div class="top_header_title">新说辣评</div>
						</div>

						<ul id="video_ul">
							<li :id="'video_li' + index" v-for="(video, index) in newsVideos" :key="index"
								:class="{ 'video_list_li_margin' : index == 0}">

								<div class="video_item" @click="onVideoPlay(video)" @mouseleave="videoHover = false"
									@mouseenter="videoMouseenter(index)">

									<img src="../assets/yjysite/yjysite_video_play_hover.png"
										:style="{ visibility : (videoHover == true && videoHoverIndex == index)?'visible':'hidden' }" />
								</div>
							</li>
						</ul>

						<div class="video_list_page">

							<img v-if="videoLeftDisable" src="../assets/yjysite/yjysite_video_line_left_disable.png" />
							<img v-else class="list_page_img_left" @click="onVideoScroll(1)" />
							<img v-if="videoRightDisable" class="list_page_margin"
								src="../assets/yjysite/yjysite_video_line_right_disable.png" />
							<img v-else class="list_page_img_right list_page_margin" @click="onVideoScroll(2)" />
						</div>

					</div>
				</div>
			</div>
		</div>

		<video-dialog v-model="dialogVideoVisible" :videoSrc="videoPlayReady.videoUrl"
			:customStyle="videoDialogStyle" />

	</div>
</template>


<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import {
		videoPlayer
	} from 'vue-video-player'
	import vueSeamlessScroll from "vue-seamless-scroll";
	import 'video.js/dist/video-js.css'
	import videoDialog from "@/components/video/videoDialog.vue"
	import CustomVideoPlayer from "@/components/video/CustomVideoPlayer.vue"
	import localPage from "./json/yjyIndex.json"
	import {
		bodyZoom
	} from "@/utils/utils.js"


	export default {
		components: {
			vueSeamlessScroll,
			videoPlayer,
			videoDialog,
			CustomVideoPlayer
		},

		data() {
			return {

				toolsIndex: 0,
				toolImgL: require("@/assets/yjy/arrow_left_gray.png"),
				toolImgR: require("@/assets/yjy/arrow_right_gray.png"),
				scrollRef: null,

				videoDialogStyle: {
					width: '80%',
					height: '686px',
					minWidth: '1200px',
					maxWidth: '1440px'
				},
				defaultOption: {
					step: 0.5, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 2, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				},
				playerOptions: {
					playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
					autoplay: false, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4", // 类型
						src: require("@/assets/ygx/ygx_logo.mp4"), // url地址
					}],
					poster: require("@/assets/ygx/banner.png"), // 封面地址
					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: false, // 是否显示剩余时间功能
						fullscreenToggle: true // 是否显示全屏按钮
					}
				},
				homeBnnerH: 700,
				bannerIndex: 0,
				comBannerTime: 3000, // 轮播图的自动播放间隔时间

				bannerList: [],
				aboutUs: null,

				headerHoverIndex: 0,
				moreImg: require("@/assets/yjysite/arrow_right_blue.png"),
				moreWhiteImg: require("@/assets/yjysite/arrow_right_white.png"),

				isHonorHover: false,
				honorHoverIndex: 0,
				honorIconImg: require("@/assets/yjysite/yjysite_timeline_normal.png"),
				honorIconHoverImg: require("@/assets/yjysite/yjysite_timeline_hover.png"),

				advantageItemImg: require("@/assets/yjysite/yjysite_advantage_item_mark.png"),
				advantageOptionIndex: 0,
				advantage: {},

				solutions: [],
				solutionItems: null,
				solutionIndex: 0,
				solution: null,
				solutionItemImg: require("@/assets/yjysite/yjysite_solution_li_mark.png"),

				classCasesBgImg: require("@/assets/yjysite/class_cases_bg.png"),
				classCases: [],
				currentCase: null,
				currCaseImgStyle: null,
				scrollCases: [],

				technologyStyle: null,
				technologyShows: [],

				technologyShowIconImg: require("@/assets/yjysite/yjysite_technology_show_default.png"),
				technologyShowIconSelectedImg: require("@/assets/yjysite/yjysite_technology_show_selected.png"),

				honorList: [], //荣誉资质
				currentHonors: null,

				newsMessages: [],
				newsCompanys: [],
				newsVideos: [],

				newsTagMessageImg: require("@/assets/yjysite/yjysite_news_tag_message.png"),
				newsTagNewsImg: require("@/assets/yjysite/yjysite_news_tag_news.png"),
				newsMessageIndex: 0,
				offsetCount: 0,
				videoHover: false,
				videoHoverIndex: 0,
				videoLeftDisable: true,
				videoRightDisable: false,
				dialogVideoVisible: false,
				videoPlayReady: {},

			};
		},

		created() {

			// console.log('clientWidth: ' + this.__imagePath + 'home/president.png')
			
			this.getHomeDataInfo()
		},

		mounted() {
			if (window.name === '') {
				document.documentElement.scrollTop = 0
			}
			
			window.addEventListener("resize", this.widowChange, false);
			window.addEventListener("popstate", this.popstate, false);
			window.addEventListener("scroll", this.handleScroll, false);
			
		},

		destroyed() {
			window.removeEventListener("resize", this.widowChange, false);
			window.removeEventListener("popstate", this.popstate, false);
			window.removeEventListener("scroll", this.handleScroll, false);
		},

		methods: {
			widowChange() {
				let zoom = bodyZoom()
				let actWidth = document.documentElement.clientWidth
				if (actWidth > 1920*zoom) {
					actWidth = 1920*zoom;
				} else if (actWidth < 1366*zoom) {
					actWidth = 1366*zoom;
				}
				this.homeBnnerH = actWidth / 1920 * 700;
			},
			handleScroll() {
				if (this.scrollRef) {
					console.log(document.documentElement.scrollTop)
				}
			},
			popstate() {
				let that = this;

				let url = window.location.href
				let temp = url.split('?')[1]
				let params = new URLSearchParams('?' + temp)
				that.scrollRef = params.get('scrollRef')

				if (that.scrollRef) {
					that.scrollToEL()
				}
			},
			scrollToEL() {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ this.scrollRef }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
					that.scrollRef = null
				})
			},

			bannerChange(e) {
				console.log('bannerChange :' + JSON.stringify(e))
				this.bannerIndex = e
			},
			honorMouseenter(index) {
				this.isHonorHover = true;
				this.honorHoverIndex = index;
			},
			videoMouseenter(index) {
				this.videoHover = true;
				this.videoHoverIndex = index;
			},
			onTouchOption(item, index) {
				console.log('onTouchOption:' + index)
				if (this.advantageOptionIndex == index) return
				this.advantageOptionIndex = index;
				this.advantage = item;
			},
			onClickMore(index) {
				console.log('onClickMore: ' + index)
				this.$parent.onSwitchTap(index)
			},
			onSolutionTab(items) {
				console.log('solution items:' + JSON.stringify(items))
				if (this.solutionItems.key === items.key) return
				this.solutionItems = items;
				this.solutionIndex = 0
				this.solution = items.solutions[0]

				console.log('solution +' + JSON.stringify(this.solution))
			},

			onSolutionMenu(item, index) {
				console.log('item:' + item)
				if (this.solutionIndex === index) return
				this.solutionIndex = index;
				this.solution = item;
			},
			onSolutionConsultation(solution) {
				this.$parent.onLeviteated(0)
			},
			onSolutionDetail(solution) {
				console.log('onSolutionDetail:' + JSON.stringify(solution))
				this.$parent.showDetailAction(solution, 2)
			},
			getImgSize(e) {
				const img = e.target
				this.currCaseImgStyle = {
					height: img.naturalHeight + 'px'
				}
			},
			onCaseTab(item, index) {
				if (this.currentCase.id === item.id) return
				this.currentCase = item;
			},
			onTechnologyMouseenter(tech) {
				this.technologyShows.forEach(item => {
					if (tech.id == item.id) {
						item.state = 1
					} else {
						item.state = -1
					}
				})
				this.techWrapStyle(true)
			},
			onTechnologyMouseleave(tech) {
				tech.state = 0
				this.techWrapStyle(false)
			},
			techWrapStyle(enter) {
				let count = this.technologyShows.length;
				if (enter) {
					const el = this.$refs.technologyBody
					if (!el) return
					let rect = el.getBoundingClientRect();
					console.log('rect:' + JSON.stringify(rect))
					let rectWidth = rect.width * window.devicePixelRatio
					
					let margin = 7 * (count - 1);
					let average = (rectWidth - margin) / count
					let big = average * 1.75
					let small = (rectWidth - big - margin) / (count - 1)

					let gridC = ''
					this.technologyShows.forEach((mech, index) => {
						if (mech.state == 1) {
							if (index == 0) gridC += (big + 'px')
							else gridC += (' ' + big + 'px')
						} else {
							if (index == 0) gridC += (small + 'px')
							else gridC += (' ' + small + 'px')
						}
					})
					console.log('gridC:' + gridC)
					this.$set(this, 'technologyStyle', {
						gridTemplateColumns: gridC
					})
				} else {
					this.technologyStyle = {
						gridTemplateColumns: 'repeat(' + count + ', 1fr)',
					}
				}
			},
			technologyItemStyle(tech) {
				let paddingTop = (tech.state == 1 ? '12.4%' : '23px');
				return {
					paddingTop: paddingTop,
					backgroundImage: 'url(' + this.technologyItemBgUrl(tech) + ')'
				}
			},
			technologyItemBgUrl(tech) {

				let url = this.__imagePath;
				if (tech.state == 1) {
					url += tech.bgFatUrl
				} else if (tech.state == -1) {
					url += tech.bgThinUrl
				} else {
					url += tech.bgUrl
				}
				return url
			},
			technologyItemTitleClass(tech) {
				if (tech.state == 1) {
					return {
						'technology_item_content_title_hover': true
					}
				} else if (tech.state == -1) {
					return {
						'technology_item_content_title_light': true
					}
				}
				return ''
			},

			onHonorTab(honor) {
				if (honor.active == true) return
				this.honorList.forEach(item => {
					item.active = (item.title === honor.title)
				})

				this.currentHonors = honor.honors;
			},
			onMessageChange(direction) {
				console.log('onMessageChange :' + direction == 1 ? '上一条' : '下一条');
				if (direction === 1) {
					if (this.newsMessageIndex == 0) {
						this.newsMessageIndex = this.newsMessages.length - 1
					} else {
						this.newsMessageIndex--
					}
				} else {
					if (this.newsMessageIndex >= this.newsMessages.length - 1) {
						this.newsMessageIndex = 0
					} else {
						this.newsMessageIndex++
					}
				}
			},
			onNewsTap(news) {
				// this.$parent.onSwitchTap(index)
				console.log('onNewsTap:' + JSON.stringify(news))

				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/news/yjyNewDetail?id=" +
					news.id;
				console.log("url----->" + url)
				window.open(url);
			},
			onNewsMore() {
				console.log('onNewsMore')
				this.$parent.onSwitchTap(6)
				// var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/yjyNewList";
				// window.open(url);
			},
			onVideoPlay(video) {
				console.log('onVideoPlay' + JSON.stringify(video))

				var _this = this

				if (video.videoUrl) {
					_this.videoPlayReady = video
					_this.dialogVideoVisible = true;
				} else {
					this.$message.error('无效视频地址');
				}
			},
			onVideoScroll(direction) {
				if (this.newsVideos.length === 0) return
				console.log('onVideoScroll :' + (direction === 1 ? '向左' : '向右'));

				var list = document.querySelector('#video_ul')
				let ulWidth = list.clientWidth;

				const getWidth = (idName) => {
					let li = document.querySelector(idName)
					return li.clientWidth
				}
				let liWidth = 0
				this.newsVideos.forEach((ele, index) => {
					liWidth = liWidth + getWidth(`#video_li${index}`)
				});

				// 根据方向和宽度计算偏移量
				if (liWidth < ulWidth) {
					// 视频数据不满屏 左右滑动禁止
					this.videoLeftDisable = true;
					this.videoRightDisable = true;
					return
				}

				let offsetX = 150
				let maxCount = (liWidth - ulWidth) / offsetX + 1;
				maxCount = Math.floor(maxCount)

				let zero = false,
					max = false;
				if (direction == 1) {
					if (this.offsetCount > 0) {
						this.offsetCount = this.offsetCount - 1
					} else {
						zero = true;
					}
				} else {
					if (this.offsetCount <= maxCount) {
						this.offsetCount = this.offsetCount + 1
					} else {
						max = true;
					}
				}

				if (zero) {
					this.videoLeftDisable = true;
					this.videoRightDisable = false;
				} else if (max) {
					this.videoLeftDisable = false;
					this.videoRightDisable = true;
				} else {
					this.videoLeftDisable = false;
					this.videoRightDisable = false;
				}
				console.log('videoLeftDisable:videoRightDisable --  ' + this.videoLeftDisable + ':' + this
					.videoRightDisable)

				let distance = this.offsetCount * offsetX
				list.scrollTo({
					'left': distance,
					'behavior': 'smooth'
				})
				console.log(distance)
			},



			changeArrowImgAction(type) {
				if (type == 11) {
					this.toolImgL = require("@/assets/yjy/arrow_left_gray.png");
				}
				if (type == 12) {
					if (this.toolsIndex == 0) {
						this.toolImgL = require("@/assets/yjy/arrow_left_gray.png");
					} else {
						this.toolImgL = require("@/assets/yjy/arrow_left_blue.png");
					}

				}
				if (type == 21) {
					this.toolImgR = require("@/assets/yjy/arrow_right_gray.png");
				}
				if (type == 22) {
					if (this.toolsIndex == this.toolsList.length - 1) {
						this.toolImgR = require("@/assets/yjy/arrow_right_gray.png");
					} else {
						this.toolImgR = require("@/assets/yjy/arrow_right_blue.png");
					}
				}

			},
			lookMoreAction() {
				var url = window.location.protocol + "//" + window.location.host + "/yjy.html#/yjyAboutUs?aboutType=1";
				window.open(url);
			},


			//服务的点击
			serAction(type) {
				var oldIndex = this.toolsIndex;

				if (type == 1) {
					if (oldIndex == 0) {
						return;
					}
					this.$refs.toolsCarousel.prev();
					if (oldIndex > 0) {
						oldIndex = oldIndex - 1;
					}
				} else {
					if (oldIndex == this.toolsList.length - 1) {
						return;
					}
					this.$refs.toolsCarousel.next();
					if (oldIndex < this.toolsList.length - 1) {
						oldIndex = oldIndex + 1;
					}
				}
				this.toolsIndex = oldIndex;
				console.log(this.toolsIndex);

			},

			showProductDetail(item) {

				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/" + item.subTitle;
				console.log(url);
				window.open(url);
			},

			//查看更多新闻
			loolMoreNews(item) {
				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/yjyNewList";
				window.open(url);
			},

			//查看新闻详情
			lookNewsDetail(item) {
				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/yjyNewDetail?id=" + item
					.id;
				window.open(url);
			},
			knowAction() {
				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/yjyAboutUs";
				window.open(url);
			},
			linkUsAction() {
				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/yjyLinkUs";
				window.open(url);

			},

			// getLocalData() {
			handlePage(page) {
				let that = this
				console.log('page: ' + JSON.stringify(page))

				let banners = page.banners
				if (banners != null && banners.length > 0) {
					that.bannerList = banners;

					console.log('banners: ' + JSON.stringify(banners))
				}
				if (page.aboutUs) {
					that.aboutUs = page.aboutUs

					if (page.aboutUs.reasons && page.aboutUs.reasons.length > 0) {
						that.advantage = page.aboutUs.reasons[0];
					}
				}

				if (page.solutions && page.solutions.length > 0) {
					// solutionTabs: ['运输', '仓储', '园区', '人力', 'SAAS', '平台', '其他'],
					// solutionTab: '运输',
					that.solutions = page.solutions
					that.solutionItems = page.solutions[0]
					if (that.solutionItems.solutions && that.solutionItems.solutions.length > 0) {
						that.solution = that.solutionItems.solutions[0]
					}
				}

				if (page.technologys && page.technologys.length > 0) {
					page.technologys.forEach((item, index) => {
						item.id = 100 + index
						item.state = 0 // 0 正常 -1 缩小 1 放大
					})
					that.technologyShows = page.technologys

					that.technologyStyle = {
						gridTemplateColumns: 'repeat(' + that.technologyShows.length + ', 1fr)'
					}
				}

				if (page.dynamic) {
					that.latestDynamics = page.dynamic
					console.log('that.latestDynamics: ' + JSON.stringify(that.latestDynamics))
				}
			},

			getClassCases() {
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjyHomeCase").then((res) => {

					if (res.data.success) {
						let data = res.data.data;

						let classList = data.classList
						if (classList && classList.length > 0) {
							let classCases = [],
								classTypes = []
							classList.forEach(item => {
								if (item.homeShow && item.caseType) {
									if (!classTypes.includes(item.caseType.value)) {
										classTypes.push(item.caseType.value)
										classCases.push(item)
									}
								}
							})
							classCases.sort(function(a, b) {
								let keya = a['caseType']['value']
								let keyb = b['caseType']['value']
								return ((keya < keyb) ? -1 : (keya > keya) ? 1 : 0)
							})
							that.$set(that, 'classCases', classCases)
							if (classCases.length > 0) {
								that.currentCase = classCases[0]
							}
						}

						let scrollList = data.scrollList
						if (scrollList && scrollList.length > 0) {
							if (that.scrollCases.length) that.scrollCases = []

							// 三分custList
							let size = 8
							if (scrollList.length > 24) {
								size = Math.ceil(scrollList.length / 3)
							}
							for (let i = 0; i < scrollList.length; i += size) {
								that.scrollCases.push(scrollList.slice(i, i + size))
							}
						}
					}
				});
			},

			//获取研究院首页的相关信息
			getHomeDataInfo() {
				this.handlePage(localPage);

				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjyHpDataInfo").then((res) => {
					// console.log('res:' + JSON.stringify(res))
					if (res.data.success) {
						let pages = res.data.data.pages;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
						that.getClassCases()

						if (that.honorList.length > 0) that.honorList = []
						let zizhiList = res.data.data.zizhiList
						if (zizhiList && zizhiList.length > 0) {
							if (zizhiList.length > 18) zizhiList = zizhiList.splice(0, 18)
							let dict = {
								active: false,
								title: '权威资质',
								honors: zizhiList
							}
							that.honorList.push(dict)
						}
						let zhuanliList = res.data.data.zhuanliList
						if (zhuanliList && zhuanliList.length > 0) {
							if (zhuanliList.length > 18) zhuanliList = zhuanliList.splice(0, 18)
							let dict = {
								active: false,
								title: '专利',
								honors: zhuanliList
							}
							that.honorList.push(dict)
						}
						let ruanzhuList = res.data.data.ruanzhuList
						if (ruanzhuList && ruanzhuList.length > 0) {
							if (ruanzhuList.length > 18) ruanzhuList = ruanzhuList.splice(0, 18)
							let dict = {
								active: false,
								title: '软著',
								honors: ruanzhuList
							}
							that.honorList.push(dict)
						}

						if (that.honorList.length > 0) {
							let first = that.honorList[0]
							first.active = true
							that.currentHonors = first.honors
						}

						let news = []
						//companyNews 公司新闻
						let companyNews = res.data.data.companyNews
						if (companyNews) {
							companyNews.forEach(item => {
								item.type = 1 // 新闻
							})
							news = [...news, ...companyNews]
						}
						let companyNews2 = res.data.data.companyNews2
						if (companyNews2) {
							companyNews2.forEach(item => {
								item.type = 2 // 资讯
							})
							news = [...news, ...companyNews2]
						}
						that.newsMessages = news

						// gsNews 公司动态
						let gsNews = res.data.data.gsNews
						if (gsNews) {
							that.newsCompanys = gsNews
						}

						// videos 新说辣评
						let videos = res.data.data.videos
						if (videos) {
							that.newsVideos = videos
						}

						that.popstate()
					}
				});
			},
		},
	};
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0 auto;
	}

	/deep/ .el-carousel__indicators {
		// 指示灯位置
		left: 12.5%;
		bottom: 16.8%;
		transform: unset;
		right: unset;
		top: unset
	}

	/deep/ .el-carousel__button {
		// 默认按钮的颜色大小等
		width: 44px;
		height: 6px;
		border: none;
		border-radius: 25px;
		background: #FFFFFF;
		opacity: 1;
	}

	/deep/ .is-active .el-carousel__button {
		// 选中图片的颜色
		background: #0055FF;
		opacity: 1;
	}

	.ul-item {
		display: flex;
	}

	.li-item {
		width: 300px;
		height: 320px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;

	}

	/* 公共的样式 */
	.com_item_title_wrap {
		display: flex;
		flex-direction: column;

		padding-bottom: 10px;
	}

	.com_item_title {
		font-size: 40px;
		font-family: HanSans-Blod;
		font-weight: 700;
		color: #191919;
		line-height: 40px;
		text-align: center;

		margin-bottom: 20px;
	}

	.com_item_subtitle {
		display: flex;
		justify-content: center;
		align-items: center;

		.moreTagHover {
			text-decoration: underline;
			margin-right: 10px !important;
			cursor: pointer;
		}
	}

	.com_item_subtitle p {
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		font-size: 18px;
		color: #0055FF;
		line-height: 18px;
		text-align: center;

		margin-right: 5px;
	}

	.com_item_subtitle img {
		width: 16px;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_content {
		flex: 1;

		display: flex;
		flex-direction: column;
	}

	.aboutUs_body_title {
		display: flex;
		flex-direction: column;

		.aboutUs_body_title_line {
			padding-bottom: 32px;

			width: 50px;
			height: 0;
			border-top: 1px solid #FF9000;
		}

		p {
			font-family: HanSans-Medium;
			font-weight: 500;
			font-size: 28px;
			color: #353C58;
			line-height: 28px;
			text-align: left;
		}
	}

	.yjy_body_index {
		display: flex;
		flex-direction: column;
		align-items: center;

		position: relative;
		background-color: transparent;

		width: 100%;

		flex: 1;
	}

	.item {
		flex: 1;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.body_width_suit {
		width: 100%;
		max-width: 1440px;
		min-width: 1200px;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;
	}


	/* banner */
	.banner {

		display: flex;
		flex-direction: column;

		.banner_carousel_item {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;

			background-size: auto 100%;
			background-repeat: no-repeat;
			background-position: center;

			background-color: #111629;

			img {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
			}

			.banner_player {
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 0;

				background-color: #111629;
			}
		}

		.banner_carousel_item_body {
			position: absolute;
			left: 12.5%;
			top: 25.6%;

			width: 40%;
			max-width: 800px;
			min-width: 667px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			z-index: 21;
		}

		.banner_carousel_item_title {

			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 42px;
			color: #FFFFFF;
			line-height: 42px;
			text-align: left;
		}

		.banner_carousel_item_subTitle {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 20px;
			color: #D2D9E7;
			line-height: 30px;
			text-align: left;
		}

		.banner_carousel_item_know {

			display: flex;
			align-items: center;
			justify-content: center;

			margin-top: 9%;

			font-size: 18px;
			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			color: #FFFFFF;
			width: 160px;
			height: 46px;
			line-height: 64px;
			text-align: center;
			border-radius: 42px;

			background: #0055FF;
		}

		.banner_carousel_item_know:hover {
			background-color: #3377FF !important;
			cursor: pointer;
		}
	}

	/* 关于我们样式 */
	.aboutUs {
		padding-top: 4%;

		display: flex;
		flex-direction: column;

		.aboutUs_body {
			/* width: 1440px; */
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			padding-bottom: 121px;
			padding-bottom: 7.5625rem;

			.padding_top {
				padding-top: 59px;
				padding-top: 3.6875rem;
			}

			.aboutUs_body_wrap {
				display: flex;

				margin-top: 44px;
				margin-top: 2.75rem;

				.aboutUs_body_wrap_left {
					/* 925 / 1440 */
					width: 64.2%;
					background-color: white;
					/* x / 925 */
					padding: 33px 37px 15px 37px;
					padding: 2.0625rem 2.3125rem 0.9375rem 2.3125rem;

					.wrap_left_name {

						margin-top: 35px;

						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 20px;
						color: #353C58;
						line-height: 20px;
						text-align: left;
					}

					.president_name {

						margin-right: 20px;

						font-family: HanSans-Blod;
						font-weight: 700;
						font-size: 30px;
						color: #0055FF;
						line-height: 30px;
						text-align: left;
					}

					img {
						/* width: 851px; */
						/* height: 436px; */
						width: 100%;
						height: auto;
					}

					ul {
						list-style-type: disc;
						margin-top: 30px;
						margin-left: 20px;
					}

					li {

						margin-bottom: 17px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #353C58;
							text-align: left;
						}
					}
				}

				.aboutUs_body_wrap_right {
					/* width: ; */

					/* flex: 1; */

					padding-left: 50px;
					position: relative;

					.wrap_right_honor_line {
						position: absolute;
						display: block;

						width: 1px;
						height: calc(90% - 40px);
						top: 30px;
						left: 63px;

						z-index: 20;

						background-color: rgba(191, 202, 220, 0.7);

					}

					li {
						margin: 18px 0 50px 0;
					}

					.wrap_right_honor_item {

						display: flex;
						align-items: center;

						cursor: pointer;

						.wrap_right_honor_item_img {
							width: 27px;
							height: 27px;

							margin-right: 20px;

							display: flex;
							justify-content: center;
							align-items: center;

							z-index: 21;
						}

						img {
							width: 9px;
						}


						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #5B627E;
							line-height: 18px;
							text-align: left;
						}
					}

					.wrap_right_honor_item:hover {
						img {
							width: 27px !important;
						}

						p {
							color: #0055FF !important;
						}
					}

				}
			}
		}

		.aboutUs_company {

			background-color: white;

			padding-bottom: 85px;

			.aboutUs_company_wrap {
				display: flex;
				flex-direction: column;

				.aboutUs_company_intro_text {

					margin-top: 32px;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 18px;
						color: #5B627E;
						line-height: 30px;
						text-align: left;
					}

					.dark_text {
						color: #353C58 !important;
					}
				}



				.aboutUs_company_box_wrap {

					display: flex;
					flex-direction: row;
					justify-content: space-between;

					margin-top: 49px;
					margin-top: 3.0625rem;

					.aboutUs_company_box {
						height: 156px;
						/* width: 460px; */
						width: 32%;
						aspect-ratio: 2.948;


						.box_wrap {
							width: calc(100% - 100px);
							height: calc(100% - 78px);
							padding: 39px 50px;

							display: flex;
							flex-direction: column;
							align-items: flex-start;

							background-size: auto 100%;
							background-repeat: no-repeat;
							background-position: center;

							p {
								font-family: HanSans-Blod;
								font-weight: 700;
								font-size: 20px;
								font-size: 1.25rem;
								color: #FFFFFF;
								line-height: 28px;
								text-align: left;
							}

							.big_text {

								margin-top: 18px;

								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								font-size: 28px;
								// font-size: 1.75rem;
								font-size: 28px;
							}

						}
					}
				}
			}
		}

		.aboutUs_advantage {
			padding-top: 59px;
			padding-top: 3.6875rem;
			padding-bottom: 96px;
			padding-bottom: 6rem;

			.aboutUs_advantage_wrap {
				margin-top: 45px;
				margin-top: 2.8125rem;
				width: 100%;

				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.advantage_option_wrap {
					width: 25%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.advantage_option {

						width: 100%;
						aspect-ratio: 2.8125;
						/* height: 128px; */
						/* padding: 0 40px; */
						/* margin-bottom: 30px; */

						display: flex;
						align-items: center;

						background-color: white;
						background-image: '';
						border-radius: 8px;

						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-position: center;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #353C58;
							line-height: 18px;
							text-align: left;

							margin-left: 40px;

						}

						.advantage_option_p_active {
							color: #FFFFFF;
						}
					}

					.advantage_option_active {
						background-color: transparent;
						border-radius: 0px;
						background-image: url("../assets/yjysite/yjysite_advantage_option_bg.png");
					}
				}

				.advantage_option_wrap:hover {
					cursor: pointer;
				}

				.advantage_content_wrap {
					flex: 1;
					margin-left: 20px;
					margin-left: 1.25rem;

					/* width: calc(75% - 20px); */

					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: center;

					background-image: url("../assets/yjysite/yjysite_advantage_content_bg.png");

					display: flex;

					.advantage_content {

						margin: 0 49px;
						margin: 0 3.0625rem;
						margin-top: 10%;

						display: flex;
						flex-direction: column;
						justify-content: center;

						.advantage_content_title {

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 24px;
							color: #353C58;
							line-height: 24px;
							text-align: left;

						}

						.advantage_content_ul {

							flex: 1;

							ul {
								display: flex;
								flex-wrap: wrap;
							}

							li {
								flex-basis: 50%;
							}

							.advantage_li_item {
								display: flex;

								margin-top: 40px;
								margin-right: 10px;

								img {
									width: 16px;
									height: 16px;
								}

								p {
									margin-left: 13px;
									font-family: Source Han Sans, Source Han Sans;
									font-weight: 400;
									font-size: 16px;
									color: #5B627E;
									line-height: 16px;
									text-align: left;
								}
							}
						}

					}
				}
			}
		}
	}

	/* 解决方案 */
	.solution {
		padding-top: 4%;

		display: flex;
		flex-direction: column;

		background-color: white;

		.solution_header_subtitle {
			display: flex;
			justify-content: center;
			align-items: center;

			.header_subtitle {

				margin-right: 13px;

				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 18px;
				color: #64698B;
				line-height: 18px;
				text-align: right;
			}
		}

		.solution_body {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.solution_segmengt {
				padding-top: 40px;
				margin-bottom: 28px;
				height: 40px;

				display: flex;
				justify-content: space-between;

				.solution_seg_item_wrap {

					flex: 1;
					height: 100%;

					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;


					.solution_seg_item {

						display: flex;
						justify-content: center;
						align-items: center;

						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 18px;
						color: #3D4461;
						line-height: 18px;
						text-align: center;

					}

					.solution_seg_item_active {
						color: #0055FF !important;
					}

					.seg_underline {
						width: 100%;
						height: 2px;
						margin-top: 14px;

						background-color: transparent;

					}

					.seg_underline_active {
						background-color: #0055FF !important;
					}
				}

				.solution_seg_item_wrap:hover {
					cursor: pointer;
				}


			}

			.solution_body_wrap {
				width: 100%;
				aspect-ratio: 3.34;

				background-image: url("../assets/yjysite/yjysite_solution_body_wrap_bg.png");
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: center;

				display: flex;
				justify-content: center;

				.solution_body_content_wrap {
					/* flex: 1; */
					margin: 60px 0;

					display: flex;
					justify-content: center;

					.solution_body_menu_wrap {
						/* width: 250px; */
						width: 25%;

						margin: 18px 0;

						display: flex;
						flex-direction: column;

						.menu_title {

							font-family: HanSans-Blod;
							font-weight: 700;
							font-size: 32px;
							color: #FFFFFF;
							text-align: left;
							
						}

						ul {
							width: 100%;
							margin-top: 48px;
							max-height: 314px;

							border-left: 1px solid rgba(255, 255, 255, 0.5);

							overflow-y: scroll;
							overflow-x: hidden;
						}

						/* 隐藏滚动条 */
						ul::-webkit-scrollbar {
							display: none;
						}

						.li_zero {
							margin-top: 0;
						}

						li {
							height: 56px;
							margin-top: 30px;

							.menu_li_wrap {
								width: 100%;
								height: 100%;

								background-image: '';

								display: flex;
								align-items: center;

								.menu_li_line {
									width: 6px;
									height: 100%;

									background-color: transparent;
								}

								.menu_li_line_active {
									background-color: #FFFFFF;
								}

								p {
									margin-left: 19px;

									font-family: HanSans-Blod;
									font-weight: 700;
									font-size: 20px;
									color: #FFFFFF;
									line-height: 20px;
									text-align: left;
								}
							}

							.menu_li_wrap:hover {
								cursor: pointer;
							}

							.menu_li_wrap_active {
								background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
							}
						}

					}

					.solution_body_content {
						/* height: 100%; */
						/* margin-left: 135px; */
						width: 75%;

						background-color: rgba(255, 255, 255, 0.85);
						box-shadow: 0px 10px 20px 0px rgba(169, 179, 202, 0.3);
						border-radius: 10px;

						display: flex;
						flex-direction: column;

						.solution_content {
							flex: 1;
							padding: 0 80px;
							padding-top: 40px;

							display: flex;
							flex-direction: column;

							position: relative;

							.solution_content_title {

								font-family: HanSans-Blod;
								font-weight: 700;
								font-size: 30px;
								color: #3D446E;
								text-align: left;
							}

							.solution_content_subtitle {

								margin-top: 25px;

								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								font-size: 16px;
								color: #64698B;
								text-align: left;
							}

							.solution_boxes {
								margin-top: 25px;

								display: grid;
								grid-template-columns: repeat(3, 1fr);
								grid-gap: 15px 38px;

								.solution_box {
									aspect-ratio: 5;
									background-color: #FFFFFF;
									border-radius: 4px;
									box-shadow: 0px 4px 10px 0px #EAEFFA;

									padding: 0 15px;

									display: flex;
									align-items: center;

									img {
										width: 14px;
										height: 14px;
									}

									p {
										margin-left: 18px;

										font-family: Source Han Sans, Source Han Sans;
										font-weight: 400;
										font-size: 14px;
										color: #64698B;
										line-height: 14px;
										text-align: left;
									}
								}
							}

							.solution_content_buttons {
								height: 40px;
								position: absolute;
								bottom: 30px;
								left: 80px;

								display: flex;
								align-items: center;

								.solution_button {

									height: 40px;
									aspect-ratio: 4;

									font-family: Source Han Sans, Source Han Sans;
									font-weight: 400;
									font-size: 16px;
									color: #FFFFFF;
									line-height: 16px;
									text-align: center;

									background-color: #0055FF;
									border-radius: 4px;

									display: flex;
									justify-content: center;
									align-items: center;
								}

								.solution_button:hover {
									cursor: pointer;
								}

								.solution_button2 {
									height: 38px;
									margin-left: 20px;
									color: #0055FF;
									background-color: #FFFFFF;
									border: 1px solid #0055FF;
								}
							}
						}
					}
				}
			}
		}


	}

	.cases {
		padding-top: 4%;

		display: flex;
		flex-direction: column;

		background-color: white;

		.cases_body {

			aspect-ratio: 2.25;

			margin-top: 70px;

			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center;

			display: flex;
			flex-direction: column;

			.cases_body_wrap {
				flex: 1;

				display: flex;
				flex-direction: column;
				align-items: center;

				.cases_header_title {

					margin-top: 110px;

					font-family: HanSans-Blod;
					font-weight: 700;
					font-size: 40px;
					color: #FFFFFF;
					line-height: 40px;
					text-align: center;
				}

				.cases_tabs_wrap {

					width: 50%;
					height: 40px;
					margin-top: 48px;

					display: flex;
					justify-content: space-between;
					align-items: center;

					.cases_tab {

						display: flex;
						flex-direction: column;
						justify-content: center;

						.cases_tab_line {
							width: 100%;
							height: 2px;
							margin-top: 8px;

							background-color: transparent;
						}

						.cases_tab_text {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #CBD2D2;
							line-height: 18px;
							text-align: center;
						}

						.cases_tab_text_active {
							color: #FFFFFF;
							font-family: HanSans-Blod;
							font-weight: 700;
						}

						.cases_tab_line_active {
							background-color: #FFFFFF;
						}
					}

					.cases_tab:hover {
						cursor: pointer;
					}
				}

				.cases_content_wrap {
					flex: 1;
					width: 100%;

					.cases_content {
						flex: 1;
						max-width: 60%;

						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;

						.img_wrap {
							height: 200px;

							display: flex;
							align-items: center;

							img {
								width: auto;
								height: 100%;
							}
						}



						.cases_content_title {

							font-family: HanSans-Blod;
							font-weight: 700;
							font-size: 30px;
							color: #FFFFFF;
							text-align: left;
						}

						.cases_content_subtitle {
							margin-top: 30px;

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							text-align: left;
						}

						.cases_content_button_wrap {

							margin-top: 48px;
							width: 138px;
							height: 48px;
							background-color: rgba(255, 255, 255, 0.05);
							border-radius: 24px;
							border: 1px solid #FFFFFF;

							display: flex;
							justify-content: center;
							align-items: center;

							p {
								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								font-size: 14px;
								color: #FFFFFF;
								line-height: 14px;
								text-align: center;
							}
						}

						.cases_content_button_wrap:hover {
							border-color: rgba(255, 255, 255, 0.5) !important;
							cursor: pointer;
						}

					}
				}

				.cases_content_bottom_wrap {
					padding-top: 20px;
					padding-bottom: 46px;

					p {
						color: #FFFFFF !important;
						cursor: pointer;
					}
				}
			}
		}

		.solution_cases_scroll {

			width: 100%;
			min-width: 1366px;
			max-width: 1920px;
			// height: 406px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.solution_cases_scroll_body {
				flex: 1;
				width: 100%;
				height: 296px;
				margin: 55px 0;

				display: flex;
				flex-direction: column;

				.solution_cases_scroll_item {
					width: 100%;
					display: flex;
					flex-direction: column;
					/* justify-content: space-between; */
				}

				.cases_scroll_wrap {

					width: 100%;
					height: 88px;

					overflow: hidden;

					ul {
						margin: 0 auto;

						display: flex;
						/* 不换行 */
						white-space: nowrap;
					}

					li {
						display: inline-block;
						width: 216px;
						height: 88px;

						margin-left: 16px;

						display: flex;

						.cases_scroll_li_wrap {
							flex: 1;

							display: flex;
							justify-content: center;
							align-items: center;

							background-color: white;
							box-shadow: 0px 8px 16px 0px rgba(192, 199, 218, 0.25);
							border-radius: 4px;

							img {
								// width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}

					}
				}
			}
		}

	}

	.technology {
		padding: 4% 0;

		display: flex;
		flex-direction: column;

		background-color: white;

		.technology_body {
			flex: 1;
			aspect-ratio: 2.8;
			margin-top: 30px;
			
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			grid-gap: 0 7px;

			.technology_item_wrap {
				
				display: flex;

				transition: all 0.25s ease;

				.technology_item {

					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;

					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: center;

					padding-bottom: 35px;

					transition: all 0.25s ease;

					.technology_item_line {
						display: block;

						width: 38px;
						height: 5px;
						background-color: #0055FF;
					}

					.technology_item_line_hidden {
						display: none;
					}

					.technology_item_content {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						.technology_item_content_title {

							margin-top: 40px;

							display: flex;
							justify-content: center;
							align-items: center;
							white-space: pre-line;
							/* 将空格和换行符保留下来 */

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #333333;
							line-height: 32px;
							text-align: center;
						}

						.technology_item_content_title_hover {
							margin-top: 20px;

							font-family: HanSans-Blod;
							font-weight: 700;
							font-size: 24px;
							color: #0055FF;
						}

						.technology_item_content_title_light {
							margin-top: 20px;

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #3D446E;
						}

						.technology_item_content_subtitle {

							/* margin-top: 64px; */
							margin-top: 12.4%;

							display: flex;
							justify-content: center;
							align-items: center;
							white-space: pre-line;
							/* 将空格和换行符保留下来 */

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 16px;
							color: #788296;
							line-height: 28px;
							text-align: center;
						}

						.technology_item_content_subtitle_hover {

							display: flex;
						}

						img {
							height: 70px;
							width: auto;
						}
					}
				}

				.technology_item_width_thin {
					width: calc((100% - 35px - 416px) / 5);
				}

				.technology_item_width_fat {
					width: 416px;
				}
			}
		}

	}

	.honor {
		padding-top: 4%;

		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 70px;

		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url("../assets/yjysite/yjysite_honor_body_bg.png");

		.honor_body {

			display: flex;
			flex-direction: column;
			margin-top: 65px;

			.honor_tabs_wrap {

				width: 100%;

				display: flex;
				justify-content: space-between;
				align-items: center;

				border-bottom: 1px solid #EBECF0;

				.honor_tab {
					flex: 1;
					height: 100%;

					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;

					p {
						margin-bottom: 17px;

						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 18px;
						color: #3D4461;
						line-height: 18px;
						text-align: center;
					}

					.honor_tab_title_active {
						color: #0055FF !important;
						font-family: HanSans-Medium;
						font-weight: 500;
					}

					.honor_tab_line {
						width: 100%;
						height: 2px;
						background-color: transparent;
					}

					.honor_tab_line_active {
						background-color: #0055FF !important;
					}
				}

				.honor_tab:hover {
					cursor: pointer;
				}
			}

			.honor_box_wrap {

				margin-top: 40px;

				display: grid;
				grid-template-columns: repeat(6, 1fr);
				grid-gap: 16px 18px;

				.honor_box {
					aspect-ratio: 1;

					background-color: white;
					border-radius: 4px;

					padding: 30px 30px 20px 30px;

					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;

					img {
						flex: 1;

						width: 100%;
						aspect-ratio: 4 / 3;

						object-fit: cover;

						background-color: rgba(0, 0, 0, 0.1);
					}

					.box_title {
						height: 40px;

						display: flex;
						align-items: center;

						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 16px;
						color: #3D4461;
						text-align: center;
					}

				}

				.honor_box:hover {
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

					.box_title {
						font-family: HanSans-Blod;
						font-weight: 700;
					}
				}
			}

		}
	}

	.news {
		padding: 4% 0;

		display: flex;
		flex-direction: column;
		align-items: center;

		background-color: white;

		.news_body {
			flex: 1;
			margin-top: 20px;

			display: flex;
			justify-content: space-between;

			.news_left_wrap {
				width: 51.67%;

				background-color: #F7F8FC;
				border-radius: 10px;
				overflow: hidden;

				display: flex;
				flex-direction: column;

				.news_left_coverImg {
					width: 100%;
					aspect-ratio: 1.78;
					object-fit: cover;
				}

				.news_left_header {
					flex: 1;

					margin: 28px 32px 0 32px;

					display: flex;
					align-items: flex-start;

					img {
						// border: 1px dashed #000;
						margin-right: 11px;
						height: 40px;
					}

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 20px;
						color: #3D4461;
						line-height: 40px;

						text-align: left;

						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
						/* 这个值根据你的需求调整，这里是每行的高度 */
						/* line-height: 20px; */
						/* 这是行高的两倍，因为最多显示两行 */
						/* max-height: 40px; */
					}

					p:hover {
						color: #0055FF !important;
						text-decoration: underline !important;
						cursor: pointer;
					}
				}

				.news_left_content {
					height: 40px;
					margin: 10px 32px;

					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 16px;
					color: #6E767F;
					line-height: 20px;
					text-align: left;

					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.news_left_bottom {
					margin: 0 32px;
					padding-bottom: 32px;

					height: 40px;
					display: flex;
					justify-content: space-between;
					align-items: flex-end;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #AEB2B9;
						line-height: 14px;
						text-align: left;

					}

					.news_left_bottom_toggle {
						width: 94px;

						display: flex;
						justify-content: space-between;
						align-items: center;

						img {
							width: 40px;
							height: 40px;
						}

						.toggle_img_left {
							content: url("../assets/yjysite/yjyste_news_toggle_left.png");
						}

						.toggle_img_right {
							content: url("../assets/yjysite/yjyste_news_toggle_right.png");
						}

						.toggle_img_left:hover {
							content: url("../assets/yjysite/yjyste_news_toggle_left_h.png");
						}

						.toggle_img_right:hover {
							content: url("../assets/yjysite/yjyste_news_toggle_right_h.png");
						}

					}
				}
			}

			.news_left_wrap:hover {
				cursor: pointer;
			}

			.news_right_wrap {

				display: flex;
				flex-direction: column;
				justify-content: space-between;

				/* flex: 1; */
				width: calc(100% - 51.67% - 24px);
				margin-left: 24px;

				.news_right_item {
					flex: 1;

					background-color: #F7F8FC;
					border-radius: 10px;

					padding: 28px 22px 0 22px;
				}

				.news_right_top_header {

					display: flex;
					justify-content: space-between;
					align-items: center;

					.top_header_title {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 20px;
						color: #191919;
						line-height: 20px;
						text-align: left;
					}

					.top_header_more {

						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 16px;
						color: #0055FF;
						line-height: 16px;
						text-align: right;
					}

					.top_header_more:hover {
						text-decoration: underline !important;
						cursor: pointer;
					}
				}

				.news_right_top_wrap {

					display: flex;
					flex-direction: column;

					ul {
						margin-top: 26px;
						margin-bottom: 20px;
						width: 100%;
					}

					.news_right_top_item {

						flex: 1;
						margin: 8px 0;

						display: flex;
						flex-direction: row;

						img {
							height: 100%;
							max-height: 80px;
							aspect-ratio: 2.07;
							object-fit: cover;
						}

						.top_item_wrap {

							/* flex: 1; */
							margin: 8px 0 8px 16px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: flex-start;

							p {
								font-family: HanSans-Medium;
								font-weight: 500;
								font-size: 16px;
								color: #3D4461;
								line-height: 16px;
								text-align: left;
							}

							p:hover {
								color: #0055FF !important;
								text-decoration: underline;
								cursor: pointer;
							}

							.top_item_time {

								margin-top: 35px;

								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								color: #AEB2B9;
								font-size: 14px;
								line-height: 14px;
								text-align: left;
							}
						}


					}

					.news_right_top_item:hover {
						cursor: pointer;
					}
				}

				.news_right_video_wrap {
					margin-top: 26px;

					display: flex;
					flex-direction: column;

					ul {
						margin-top: 26px;
						flex: 1;

						// list-style: none;
						width: 100%;
						height: 100%;
						overflow: hidden;
						/* 设置x轴可滑动 */
						overflow-x: auto;
						/* 不换行 */
						white-space: nowrap;

					}

					/* 隐藏滚动条 */
					ul::-webkit-scrollbar {
						display: none;
					}

					li {
						/* 保持所有li在同一行 */
						display: inline-block;

						/* width: 154px; */
						height: 100%;
						aspect-ratio: 0.82;

						margin-left: 21px;

					}

					.video_list_li_margin {
						margin-left: 0;
					}

					.video_item {
						width: 100%;
						height: 100%;

						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url("../assets/yjysite/yjysite_video_detault_coverImg.png");

						display: flex;
						justify-content: center;
						align-items: center;

						img {
							width: 24%;
							aspect-ratio: 1;
						}

						img:hover {
							cursor: pointer;
						}
					}

					.video_list_page {
						height: 13.8%;

						display: flex;
						justify-content: flex-end;
						align-items: center;

						/* margin-top: 10px; */
						padding-right: 10px;

						img {
							width: 16px;
							height: auto;
						}

						.list_page_img_left {
							content: url("../assets/yjysite/yjysite_video_line_left.png");
						}

						.list_page_img_left:hover {
							content: url("../assets/yjysite/yjysite_video_line_left_h.png") !important;
						}

						.list_page_img_right {
							content: url("../assets/yjysite/yjysite_video_line_right.png");
						}

						.list_page_img_right:hover {
							content: url("../assets/yjysite/yjysite_video_line_right_h.png") !important;
						}

						.list_page_margin {
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
</style>